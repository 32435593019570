import React, { useState, useEffect, useCallback } from 'react';
import axios, { AxiosResponse } from 'axios';
import { 
    Row, 
    Col,
    Button,
    Alert,
    Table,
    Breadcrumb,
    Pagination,
} from 'react-bootstrap';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import { AiOutlineSend, AiOutlineUserAdd, AiOutlineBars } from "react-icons/ai";
import { FaSort, FaSortUp, FaSortDown } from "react-icons/fa";
import { BiSearchAlt } from "react-icons/bi";
import SubMenu from '../submenu';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../redux/reducers';
import { createRequest } from './redux/adminUserActions';
import { LoadingPage } from '../../../components/loader';
import { CreateUserSuccess, CreateUserFailed, AuthAccessFailed } from './loader';
import { getLocalStorageVariable } from '../../../components/localStorage';
import { setSessionVariable } from '../../../components/sessionStorage';
import apiUrl from '../../../components/apiurl';
import { useNavigate } from 'react-router-dom';
import CryptoJS from 'crypto-js';

const SearchSchema = Yup.object().shape({
    search: Yup.string()
        .trim(),
  });

const CreateSchema = Yup.object().shape({
    account: Yup.string()
        .max(10, 'Must be 10 characters or less')
        .required("Account # is required"),
    fname: Yup.string()
        .trim()
        .max(30, 'Must be 30 characters or less')
        .matches(/^([a-z\s A-Z])+$/, "Valid characters from A-Z only")
        .required("Firstname is required"),
    mi: Yup.string()
        .trim()
        .max(1, 'Must be 1 character only')
        .matches(/^([a-z\s A-Z])+$/, "Valid characters from A-Z only"),
    lname: Yup.string()
        .trim()
        .max(30, 'Must be 30 characters or less')
        .matches(/^([a-z\s A-Z])+$/, "Valid characters from A-Z only")
        .required("Lastname is required."),
    phase: Yup.string()
        .trim()
        .max(5, 'Must be 5 characters or less')
        .matches(/^([a-z\s A-Z\s 0-9])+$/, "Please input valid numbers and characters.")
        .required("Phase is required."),
    block: Yup.string()
        .trim()
        .max(5, 'Must be 5 characters or less')
        .matches(/^\d+$/, "Please input valid numbers.")
        .required("Block number is required."),
    lot: Yup.string()
        .trim()
        .max(5, 'Must be 5 characters or less')
        .matches(/^\d+$/, "Please input valid numbers.")
        .required("Lot number is required.")
        .test('account-exists', 'Phase, Block and Lot already exists.', async function (value) {
            try {
              const { block } = this.parent;
              const { phase } = this.parent;
      
              const token = getLocalStorageVariable<string>('token');
              const headers = {
                Authorization: `${token}`,
              };
      
              const response = await axios.post(
                `${apiUrl.url}admin_check_account_duplication/`,
                { phase: phase, block: block, lot: value  },
                { headers }
              );
      
              if (response.data.msg) {
                return false;
              }
      
              return !response.data.exists;
            } catch (error) {
              console.error('Error checking account duplication:', error);
              return false;
            }
          }),
    lotarea: Yup.string()
        .trim()
        .max(10, 'Must be 10 characters or less')
        .matches(/^\d*\.?\d*$/, "Please input valid numbers.")
        .required("Lot area is required."),
    floorarea: Yup.string()
        .trim()
        .max(10, 'Must be 10 characters or less')
        .matches(/^\d*\.?\d*$/, "Please input valid numbers.")
        .required("Floor area is required."),
    dues: Yup.string()
        .trim()
        .max(10, 'Must be 10 characters or less')
        .matches(/^\d*\.?\d*$/, "Please input valid numbers.")
        .required("Monthly Dues is required."),
    confirm: Yup
        .bool()
        .oneOf([true], "Please confirm.")
        .required("Please confirm."),
  });

  interface UserAccount {
    user_acct_no: number;
  }

  interface UserData {
    id: number;
    account: string;
    fname: string;
    mi: string;
    lname: string;
    phase: string;
    block: string;
    lot: string;
    status: number;
  }

interface SortConfig {
    key: keyof UserData;
    direction: 'asc' | 'desc';
  }

const Create_User: React.FC = () =>{
    const failed = useSelector((state: RootState) => state.Admin_CreateUserReducer.error);
    const loading = useSelector((state: RootState) => state.Admin_CreateUserReducer.loading);
    const success = useSelector((state: RootState) => state.Admin_CreateUserReducer.info);
    const token = getLocalStorageVariable<string>('token');
    const [data, setData] = useState<UserAccount[] | null>(null);
    const [dataUser, setUser] = useState<UserData[] | null>(null);
    const user_create = getLocalStorageVariable<string>('user_create');
    const [searchTerm, setSearchTerm] = useState('');
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const ITEMS_PER_PAGE = 20;
    const id = '1';

    // Pagination
    const [currentPage, setCurrentPage] = useState(1);

    // Pagination calculation
    const indexOfLastItem = currentPage * ITEMS_PER_PAGE;
    const indexOfFirstItem = indexOfLastItem - ITEMS_PER_PAGE;

    // ****** decrypt Data *****
    const encryptionKey = process.env.REACT_APP_DE_EN ?? ''; 
    const ivHex = '00000000000000000000000000000000'; // Fixed IV as used in the backend
 
    const decryptData = (encryptedData: string, encryptionKey: string): string => {
        try {
            const key = CryptoJS.enc.Utf8.parse(encryptionKey); // Use Utf8 parsing for consistency
            const iv = CryptoJS.enc.Hex.parse(ivHex);
    
            // Ensure the encryptedData is a valid hex string
            if (!/^[a-fA-F0-9]+$/.test(encryptedData)) {
                console.error("Invalid encrypted data format:", encryptedData);
                return ""; // Return an empty string or a placeholder on error
            }
    
            // Convert the hex string to bytes, then decrypt
            const encryptedHexStr = CryptoJS.enc.Hex.parse(encryptedData); // Interpret hex as bytes
            const encryptedBase64 = CryptoJS.enc.Base64.stringify(encryptedHexStr); // Convert bytes to Base64
    
            // Decrypt the Base64 string
            const bytes = CryptoJS.AES.decrypt(encryptedBase64, key, {
                iv: iv,
                mode: CryptoJS.mode.CBC,
                padding: CryptoJS.pad.Pkcs7,
            });
    
            const decrypted = bytes.toString(CryptoJS.enc.Utf8);
    
            if (!decrypted) {
                console.error("Decryption failed:", encryptedData);
                return ""; // Return an empty string on failure
            }
    
            return decrypted;
        } catch (error) {
            console.error("Error decrypting data:", error, encryptedData);
            return ""; // Return an empty string or placeholder if decryption fails
        }
    };
    
    // ***** Handle Search *****
    const handleSearch = (values: { search: string }) => {
        // setSearchTerm(values.search); // Update search term
        setCurrentPage(1); // Reset pagination
        applyLocalSearch(values.search); // Filter data locally
    };
    
    const applyLocalSearch = (searchTerm: string) => {
        if (!originalUserData) {
            setUser(null); // If originalUserData is null, set filtered data to null
            return;
        }
        
        // Perform a case-insensitive search in fname, lname, and other fields
        const filteredData = originalUserData.filter((user) => {
            const searchLower = searchTerm.toLowerCase();
            return (
                user.account?.toLowerCase().includes(searchLower) ||
                user.fname?.toLowerCase().includes(searchLower) ||
                user.lname?.toLowerCase().includes(searchLower) ||
                user.phase?.toLowerCase().includes(searchLower) ||
                user.block?.toLowerCase().includes(searchLower) ||
                user.lot?.toLowerCase().includes(searchLower)
            );
        });
        setUser(filteredData.length > 0 ? filteredData : null);
    };
    
    // Store the original data for local search
    const [originalUserData, setOriginalUserData] = useState<UserData[] | null>(null);
    
    // Updated fetchUser logic to store the original data
    const fetchUser = useCallback(async () => {
        const headers = {
            Authorization: `${token}`,
        };
    
        try {
            const res: AxiosResponse<UserData[]> = await axios.get(`${apiUrl.url}admin_get_user`,{ headers, params: { search: searchTerm } });
    
            if (res.data.length > 0) {
                const decryptedData = res.data.map((user) => ({
                    ...user, fname: decryptData(user.fname, encryptionKey), lname: decryptData(user.lname, encryptionKey),
                }));
                setOriginalUserData(decryptedData); // Store the original data for filtering
                setUser(decryptedData); // Set the data to be displayed
            } else {
                setOriginalUserData(null);
                setUser(null);
            }
        } catch (error) {
            console.error('Error fetching user data:', error);
        }
    }, [searchTerm, token, encryptionKey]);
    
    

    const fetchData = useCallback(async () => {
        const headers = {
            Authorization: `${token}`,
          };
        try {
            const res: AxiosResponse<UserAccount[]> = await axios.post(`${apiUrl.url}admin_get_system_account/`, { id: id }, { headers });
            setData(res.data);
        } catch (error) {
            console.error('Error: ', error);
            return false;
        }
    }, [id, token]);

    useEffect(() => {
        fetchData();
        fetchUser();
    }, [ fetchData, fetchUser ]);

    const handleItemClick = (path: string) => {
        setSessionVariable('setSelectedItem', path);
        navigate(path);
    };
   
    // ***** Handle submit *****
    const handleSubmit = (values: any) => {
        const payload = {
            account: values.account,
            fname: values.fname,
            mi: values.mi,
            lname: values.lname,
            phase: values.phase,
            block: values.block,
            lot: values.lot,
            lotarea: values.lotarea,
            floorarea: values.floorarea,
            dues: values.dues,
        }
        dispatch(createRequest(payload));
      };


    // // ***** Sorting Code *****
    const [sortConfig, setSortConfig] = useState<SortConfig | null>(null);

    // Sorting logic
    const sortedData = React.useMemo(() => {
        if (!dataUser || !sortConfig) return dataUser;
        const sortedArray = [...dataUser];
        sortedArray.sort((a, b) => {
            const aValue = a[sortConfig.key];
            const bValue = b[sortConfig.key];
            if (aValue < bValue) return sortConfig.direction === 'asc' ? -1 : 1;
            if (aValue > bValue) return sortConfig.direction === 'asc' ? 1 : -1;
            return 0;
        });
        return sortedArray;
    }, [dataUser, sortConfig]);

    // Handle sorting updates
    const handleSort = (key: keyof UserData) => {
        setSortConfig((prevSortConfig) => {
            if (prevSortConfig && prevSortConfig.key === key) {
                return {
                    key,
                    direction: prevSortConfig.direction === 'asc' ? 'desc' : 'asc',
                };
            } else {
                return { key, direction: 'asc' };
            }
        });
        setCurrentPage(1); // Reset to first page on sort change
    };

    // Apply pagination after sorting
    const paginatedData = React.useMemo(() => {
        return sortedData?.slice(indexOfFirstItem, indexOfLastItem);
    }, [sortedData, indexOfFirstItem, indexOfLastItem]);

    // Pagination handler
    const paginate = (pageNumber: number) => setCurrentPage(pageNumber);
    const totalPages = Math.ceil((sortedData?.length || 0) / ITEMS_PER_PAGE);


    return (
        <Formik
            enableReinitialize={true}
            initialValues={{ 
                account: data ? Number(data[0]?.user_acct_no) : "",
                fname: "",
                mi: "",
                lname: "",
                phase: "",
                block: "",
                lot: "",
                lotarea: "",
                floorarea: "",
                dues: "",
                confirm: false,
            }}
            validationSchema={CreateSchema}
            onSubmit={handleSubmit}
            >
            {({ errors, touched }) => (
            <div className="container-fluid ">
                {loading?<LoadingPage/>:""}
                {failed?<CreateUserFailed/>:""}
                {success?<CreateUserSuccess/>:""}
                {user_create != '1'? <AuthAccessFailed />:""}

                <Row className="justify-content-md-center">
                    <Col>
                        <SubMenu/>
                    </Col>
                    <Col xs lg="10"  className="p-3 bg-white rounded shadow-sm ">
                        <h4 className="text-primary" ><AiOutlineUserAdd size="30" /> Create Account</h4>
                            <Breadcrumb>
                                <Breadcrumb.Item onClick={() => handleItemClick('/admin_user_menu')}>User Menu</Breadcrumb.Item>
                                <Breadcrumb.Item active>Create User</Breadcrumb.Item>
                            </Breadcrumb>
                            <hr/>
                        <div style = {{ width:"400px"}} />
                        Create new account by inputing details below.
                        <Alert style={{border: "1px solid rgb(128, 128, 128)", backgroundColor:'#c9dbff'}} className='mt-2 shadow-lg'>
                        <Form>
                            <Row>
                                <Col sm>
                                    <FloatingLabel label="Account Number" className="mb-2">
                                        <Field
                                            type="text"
                                            name="account"
                                            maxLength="10"
                                            disabled={true}
                                            placeholder="Account Number"
                                            className={`rounded w-100 form-control ${touched.account && errors.account ? 'is-invalid' : touched.account ? 'is-valid' : ''}`}
                                        />
                                        <ErrorMessage name="account">
                                            {msg => <div className="invalid-feedback">{msg}</div>}
                                        </ErrorMessage>
                                    </FloatingLabel>
                                </Col>
                                <Col sm>
                                </Col>
                                <Col sm>
                                </Col>
                            </Row>

                            <Row>
                                <Col sm>
                                    <FloatingLabel label="Phase" className="mb-2">
                                        <Field
                                            type="text"
                                            name="phase"
                                            maxLength="5"
                                            placeholder="Phase"
                                            className={`rounded w-100 form-control ${touched.phase && errors.phase ? 'is-invalid' : touched.phase ? 'is-valid' : ''}`}
                                        />
                                        <ErrorMessage name="phase">
                                            {msg => <div className="invalid-feedback">{msg}</div>}
                                        </ErrorMessage>
                                    </FloatingLabel>
                                </Col>
                                <Col sm>
                                    <FloatingLabel label="Block" className="mb-2">
                                        <Field
                                            type="text"
                                            name="block"
                                            maxLength="5"
                                            placeholder="Block"
                                            className={`rounded w-100 form-control ${touched.block && errors.block ? 'is-invalid' : touched.block ? 'is-valid' : ''}`}
                                        />
                                        <ErrorMessage name="block">
                                            {msg => <div className="invalid-feedback">{msg}</div>}
                                        </ErrorMessage>
                                    </FloatingLabel>
                                </Col>
                                <Col sm>
                                    <FloatingLabel label="Lot" className="mb-2">
                                        <Field
                                            type="text"
                                            name="lot"
                                            maxLength="5"
                                            placeholder="Lot"
                                            className={`rounded w-100 form-control ${touched.lot && errors.lot ? 'is-invalid' : touched.lot ? 'is-valid' : ''}`}
                                        />
                                        <ErrorMessage name="lot">
                                            {msg => <div className="invalid-feedback">{msg}</div>}
                                        </ErrorMessage>
                                    </FloatingLabel>
                                </Col>
                            </Row>

                            <Row>
                                <Col sm>
                                    <FloatingLabel label="Firstname" className="mb-2">
                                        <Field
                                            type="text"
                                            name="fname"
                                            maxLength="30"
                                            placeholder="Firstname"
                                            className={`rounded w-100 form-control ${touched.fname && errors.fname ? 'is-invalid' : touched.fname ? 'is-valid' : ''}`}
                                        />
                                        <ErrorMessage name="fname">
                                            {msg => <div className="invalid-feedback">{msg}</div>}
                                        </ErrorMessage>
                                    </FloatingLabel>
                                </Col>
                                <Col sm>
                                    <FloatingLabel label="MI" className="mb-2">
                                        <Field
                                            type="text"
                                            name="mi"
                                            maxLength="1"
                                            placeholder="MI"
                                            className={`rounded w-100 form-control ${touched.mi && errors.mi ? 'is-invalid' : touched.mi ? 'is-valid' : ''}`}
                                        />
                                        <ErrorMessage name="mi">
                                            {msg => <div className="invalid-feedback">{msg}</div>}
                                        </ErrorMessage>
                                    </FloatingLabel>
                                </Col>
                                <Col sm>
                                    <FloatingLabel label="Lastname" className="mb-2">
                                        <Field
                                            type="text"
                                            name="lname"
                                            maxLength="30"
                                            placeholder="Lastname"
                                            className={`rounded w-100 form-control ${touched.lname && errors.lname ? 'is-invalid' : touched.lname ? 'is-valid' : ''}`}
                                        />
                                        <ErrorMessage name="lname">
                                            {msg => <div className="invalid-feedback">{msg}</div>}
                                        </ErrorMessage>
                                    </FloatingLabel>
                                </Col>
                            </Row>

                            <Row>
                                <Col sm>
                                    <FloatingLabel label="Lot Area " className="mb-2">
                                        <Field
                                            type="text"
                                            name="lotarea"
                                            maxLength="10"
                                            placeholder="Lot Area "
                                            className={`rounded w-100 form-control ${touched.lotarea && errors.lotarea ? 'is-invalid' : touched.lotarea ? 'is-valid' : ''}`}
                                        />
                                        <ErrorMessage name="lotarea">
                                            {msg => <div className="invalid-feedback">{msg}</div>}
                                        </ErrorMessage>
                                    </FloatingLabel>
                                </Col>
                                <Col sm>
                                    <FloatingLabel label="Floor Area" className="mb-2">
                                        <Field
                                            type="text"
                                            name="floorarea"
                                            maxLength="10"
                                            placeholder="Floor Area"
                                            className={`rounded w-100 form-control ${touched.floorarea && errors.floorarea ? 'is-invalid' : touched.floorarea ? 'is-valid' : ''}`}
                                        />
                                        <ErrorMessage name="floorarea">
                                            {msg => <div className="invalid-feedback">{msg}</div>}
                                        </ErrorMessage>
                                    </FloatingLabel>
                                </Col>
                                <Col sm>
                                    <FloatingLabel label="Dues" className="mb-2">
                                        <Field
                                            type="text"
                                            name="dues"
                                            maxLength="10"
                                            placeholder="Dues"
                                            className={`rounded w-100 form-control ${touched.dues && errors.dues ? 'is-invalid' : touched.dues ? 'is-valid' : ''}`}
                                        />
                                        <ErrorMessage name="dues">
                                            {msg => <div className="invalid-feedback">{msg}</div>}
                                        </ErrorMessage>
                                    </FloatingLabel>
                                </Col>
                            </Row>
                            <br/>
                                <Col sm> 
                                    <label>
                                        <Field type="checkbox" name="confirm" />
                                        &nbsp;Please confirm for creating new account.
                                    </label>
                                    <ErrorMessage name="confirm">
                                        {msg => <div style={{color:'red',padding:'5px'}}>{msg}</div>}
                                    </ErrorMessage>
                                </Col>
                            <br/>
                            <div className="d-grid gap-2">
                                <Button variant="primary" type="submit" className="btn btn-primary btn-block rounded-pill mb-3" >
                                    <AiOutlineSend size="20"/> Submit
                                </Button>
                            </div>
                        </Form>
                        </Alert>

                        <hr className="mt-5" />
                        <h4 className="text-primary mt-4" ><AiOutlineBars size="30" /> User Account List</h4>

                        <Formik
                            enableReinitialize={true}
                            initialValues={{ 
                                search: "",
                            }}
                            validationSchema={SearchSchema}
                            onSubmit={handleSearch}
                            >
                                {({ errors, touched }) => (
                                <Form>
                                    <Row>
                                        <Col xs lg="6"  className="bg-white rounded">
                                            <FloatingLabel label="&#x1F50E; Search..." className="mb-2">
                                                <Field
                                                    type="search"
                                                    name="search"
                                                    maxLength="50"
                                                    placeholder="&#x1F50E; Search..."
                                                    style={{border: "1px solid rgb(128, 128, 128)"}}
                                                    className={`rounded w-100 form-control ${touched.search && errors.search ? 'is-invalid' : touched.search ? 'is-valid' : ''}`}
                                                />
                                                <ErrorMessage name="search">
                                                    {msg => <div className="invalid-feedback">{msg}</div>}
                                                </ErrorMessage>
                                            </FloatingLabel>
                                            <Button type="submit" variant="outline-success" style={{display:'none'}}><BiSearchAlt size="20" className='ms-2 me-2'/></Button>
                                        </Col>
                                    </Row>
                                </Form>
                                )}
                            </Formik>

                        <Table responsive striped bordered hover size="sm">
                            <thead>
                                <tr className="text-center">
                                    <th className="bg-secondary text-white">#</th>
                                    <th onClick={() => handleSort('account')} className="sortable bg-secondary text-white">
                                        Account # &emsp;{sortConfig?.key === 'account' ? (sortConfig.direction === 'asc' ? <FaSortUp /> : <FaSortDown />) : <FaSort />}
                                    </th>
                                    <th onClick={() => handleSort('lname')} className="sortable bg-secondary text-white" >
                                        Name &emsp; {sortConfig?.key === 'lname' ? (sortConfig.direction === 'asc' ? <FaSortUp /> : <FaSortDown />) : <FaSort />}
                                    </th>
                                    <th onClick={() => handleSort('phase')} className="sortable bg-secondary text-white">
                                        Phase &emsp; {sortConfig?.key === 'phase' ? (sortConfig.direction === 'asc' ? <FaSortUp /> : <FaSortDown />) : <FaSort />}
                                    </th>
                                    <th onClick={() => handleSort('block')} className="sortable bg-secondary text-white">
                                        Block &emsp; {sortConfig?.key === 'block' ? (sortConfig.direction === 'asc' ? <FaSortUp /> : <FaSortDown />) : <FaSort />}
                                    </th>
                                    <th onClick={() => handleSort('lot')} className="sortable bg-secondary text-white">
                                        Lot &emsp; {sortConfig?.key === 'lot' ? (sortConfig.direction === 'asc' ? <FaSortUp /> : <FaSortDown />) : <FaSort />}
                                    </th>
                                    <th onClick={() => handleSort('status')} className="sortable bg-secondary text-white">
                                        Status &emsp; {sortConfig?.key === 'status' ? (sortConfig.direction === 'asc' ? <FaSortUp /> : <FaSortDown />) : <FaSort />}
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {paginatedData?.length ? (
                                    paginatedData.map((r, i) => (
                                        <tr key={i} className="align-middle text-secondary">
                                            <td className="text-center align-middle">{i + 1 + indexOfFirstItem}</td>
                                            <td className="text-center align-middle">{r.account}</td>
                                            <td>
                                                {r.fname} {r.mi}. {r.lname}
                                            </td>
                                            <td className="text-center align-middle">{r.phase}</td>
                                            <td className="text-center align-middle">{r.block}</td>
                                            <td className="text-center align-middle">{r.lot}</td>
                                            <td className="text-center align-middle">
                                                {r.status === 1 ? <strong className="text-success">Active</strong> : <strong className="text-danger">Inactive</strong>}
                                            </td>
                                        </tr>
                                    ))
                                ) : (
                                    <tr>
                                        <td colSpan={9} className="text-danger text-center">No user account found...</td>
                                    </tr>
                                )}
                            </tbody>
                        </Table>

                        {dataUser?.length && dataUser.length > ITEMS_PER_PAGE && (
                                <Pagination>
                                    <Pagination.First onClick={() => paginate(1)} />
                                    <Pagination.Prev onClick={() => paginate(currentPage - 1)} />

                                    {Array.from({ length: totalPages }).map((_, index) => {
                                        // Show the current page and two pages before and after it
                                        if (index >= currentPage - 2 && index <= currentPage + 2) {
                                        return (
                                            <Pagination.Item
                                            key={index}
                                            active={index + 1 === currentPage}
                                            onClick={() => paginate(index + 1)}
                                            >
                                            {index + 1}
                                            </Pagination.Item>
                                        );
                                        }

                                        return null;
                                    })}

                                    {/* Show ellipsis between Pagination.Prev and Pagination.Next */}
                                    {currentPage + 2 < totalPages && (
                                        <Pagination.Ellipsis key={`ellipsis`} />
                                    )}

                                    <Pagination.Next onClick={() => paginate(currentPage + 1)} />
                                    <Pagination.Last onClick={() => paginate(totalPages)} />
                                </Pagination>
                            )}
                    </Col>
                </Row>
            </div>
                )}
        </Formik>
    );
};

export default Create_User;