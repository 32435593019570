import { call, put, takeLatest } from 'redux-saga/effects';
import { AxiosResponse } from 'axios';
import { 
  ActionTypes, 
  updateAdminUserSuccess, 
  updateAdminUserFailure, 
  redirect,
  updateAdminPasswordSuccess, 
  updateAdminPasswordFailure, 
  updateAdminPasswordRedirect,
  // createAuthSuccess, 
  // createAuthFailure, 
  // redirectAuth,
} from './adminHeadersActions';
import apiUrl from '../../../../components/apiurl';
import axios from 'axios';
import { getLocalStorageVariable, setLocalStorageVariable } from '../../../../components/localStorage';


function* adminUpdateAdminUser(action: any): Generator<any, void, AxiosResponse> {
  try {
    const token = getLocalStorageVariable<string>('token');
    const headers = {
      Authorization: `${token}`,
    };

    const response: AxiosResponse = yield call(axios.post, `${apiUrl.url}headers_admin_profile/update`, action.payload, { headers });
    const user = response.data;

      if(user!=''){
        yield put(updateAdminUserSuccess(user));
        setLocalStorageVariable('avatar', action.payload.avatar);
        yield put(redirect('/admin_profile'));

          const response: AxiosResponse = yield call(axios.post, `${apiUrl.url}send_email`, {
          // const response = await axios.post(`${apiUrl.url}send_email`, {
            to: action.payload.email,
            subject: 'Update User Account',
            html: `
              <div style="background-color: rgb(243,243,243); padding: 30px;">
                <div style="font-family:Arial, Helvetica, sans-serif; font-size: 14px; border: 1px solid #d1d1d1">
                  <div style="background-color: #9ffcca; color: #383d3a; padding: 5px; border: none; text-align: center;">
                  <a href="https://pearsportal.com" target="_blank"><img src="https://billing.pearsportal.com/linao.png" style="width:160px; height:100px" /></a>
                  </div>
                        <div style="padding: 30px; background-color: #FFF">
                          Hi ${action.payload.fname},
                        <br/>
                        <br/>
                          Your account information updated succesfully.<br/>
                          <div style="padding-left: 10px;">
                            <table style="border: none; padding: 3px; font-weight: bold">
                              <tr>
                                <td>Name</td>
                                <td style="padding-left:5px; padding-right:5px">:</td>
                                <td>${action.payload.fname} ${action.payload.mi}. ${action.payload.lname}</td>
                              </tr>
                              <tr>
                                <td>E-mail</td>
                                <td style="padding-left:5px; padding-right:5px">:</td>
                                <td>${action.payload.email}</td>
                              </tr> 
                              <tr>
                                <td>Cellphone</td>
                                <td style="padding-left:5px; padding-right:5px">:</td>
                                <td>${action.payload.cellphone}</td>
                              </tr>                
                            </table>
                          <br/>
                            If this is not you please contact the system administrator immediately.<br/>
                            Please give us some feedback to improve the system.<br/>Thank you.
                          <br/><br/>
                          Best regards,<br/>
                          Casa Mira - Linao Application Team
                      </div>
                    </div>
                  </div>
              </div>`,
          }, { headers });
    
          if (response.status === 200) {
            console.log('Email sent successfully');
          } else {
            console.error('Failed to send email');
          }

      }else{
        yield put(updateAdminUserFailure('failed'));
      }
  } catch (error) {
    const errorMessage = (error as any).message; 
    yield put(updateAdminUserFailure(errorMessage));
  }
}


// function* adminCreateAuth(action: any): Generator<any, void, AxiosResponse> {
//   try {
//     const token = getLocalStorageVariable<string>('token');
//     const headers = {
//       Authorization: `${token}`,
//     };

//     console.log(action.payload)
//     const response: AxiosResponse = yield call(axios.post, `${apiUrl.url}admin_create_auth`, action.payload, { headers });
//     const auth = response.data;

//       if(auth!=''){
//         yield put(createAuthSuccess(auth));
//         yield put(redirectAuth('/admin_settings_access_settings'));
//       }else{
//         yield put(createAuthFailure('failed'));
//       }
//   } catch (error) {
//     const errorMessage = (error as any).message; 
//     yield put(createAuthFailure(errorMessage));
//   }
// }

// ***** Update Password *****
function* updatePassword(action: any): Generator<any, void, AxiosResponse> {
  try {
    const token = getLocalStorageVariable<string>('token');
    const headers = {
      Authorization: `${token}`,
    };

    const response: AxiosResponse = yield call(axios.post, `${apiUrl.url}headers_admin_profile/update_password`, action.payload, { headers });
    const user = response.data;

      if(user!=''){
        yield put(updateAdminPasswordSuccess(user));
        // setLocalStorageVariable('avatar', action.payload.avatar);
        yield put(updateAdminPasswordRedirect('/admin_profile'));

          const response: AxiosResponse = yield call(axios.post, `${apiUrl.url}send_email`, {
          // const response = await axios.post(`${apiUrl.url}send_email`, {
            to: action.payload.email,
            subject: 'Password Update',
            html: `
              <div style="background-color: rgb(243,243,243); padding: 30px;">
                <div style="font-family:Arial, Helvetica, sans-serif; font-size: 14px; border: 1px solid #d1d1d1">
                  <div style="background-color: #9ffcca; color: #383d3a; padding: 5px; border: none; text-align: center;">
                  <a href="https://pearsportal.com" target="_blank"><img src="https://billing.pearsportal.com/linao.png" style="width:160px; height:100px" /></a>
                  </div>
                    <div style="padding: 30px; background-color: #FFF">
                      Hi ${action.payload.fname},
                    <br/>
                    <br/>
                      <b>Your password is updated succesfully.</b><br/>
                        <br/>
                        If this is not you please contact the system administrator immediately.<br/>
                        Please give us some feedback to improve the system.<br/>Thank you.
                      <br/><br/>
                      Best regards,<br/>
                      Casa Mira - Linao Application Team
                </div>
              </div>
			      </div>`,
          }, { headers });
    
          if (response.status === 200) {
            console.log('Email sent successfully');
          } else {
            console.error('Failed to send email');
          }

      }else{
        yield put(updateAdminPasswordFailure('failed'));
      }
  } catch (error) {
    const errorMessage = (error as any).message; 
    yield put(updateAdminPasswordFailure(errorMessage));
  }
}

function* watchAdminCreateUser() {
  yield takeLatest(ActionTypes.ADMIN_UPDATE_ADMIN_USER_REQUEST, adminUpdateAdminUser);
  // yield takeLatest(ActionTypes.ADMIN_CREATE_AUTH_REQUEST, adminCreateAuth);
  yield takeLatest(ActionTypes.ADMIN_UPDATE_PASSWORD_REQUEST, updatePassword);
}

export default watchAdminCreateUser;
