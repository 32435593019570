import React, { useState, useEffect, useCallback } from 'react';
import axios, { AxiosResponse } from 'axios';
import { 
    Row, 
    Col,
    InputGroup,
    Button,
    Alert,
    Table,
    Breadcrumb,
} from 'react-bootstrap';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { AiOutlineSend } from "react-icons/ai";
import { AiOutlineFileDone, AiOutlineDollar, AiOutlineDelete, AiOutlineForm, AiOutlineUnorderedList, AiOutlineTool } from "react-icons/ai";
import { BsClipboard2Minus } from "react-icons/bs";
import { BiSearchAlt } from "react-icons/bi";
import SubMenu from '../submenu';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { setSessionVariable, getSessionVariable } from '../../../components/sessionStorage';
import { AuthAccessFailed } from '../../../components/loader';
import { getLocalStorageVariable } from '../../../components/localStorage';
import apiUrl from '../../../components/apiurl';
import { paymentRequest, paymentSearchRequest } from './redux/adminPaymentActions';
import { RootState } from '../../../redux/reducers';
import { LoadingPage, CreateCreditMemoFailed, CreateCreditMemoSuccess, SearchFailed } from './loader';

  
const SearchSchema = Yup.object().shape({
    search: Yup.string()
        .trim()
        .max(10, 'Must be 10 characters or less')
        .required("Account # is required"),
    payment_no: Yup.string()
        .trim()
        .max(10, 'Must be 10 characters or less')
        .required("Memo # is required"),
    paymentfor: Yup.string()
        .trim()
        .max(20, 'Must be 20 characters or less')
        .required("Payment For is required"),
  });

const CreateSchema = Yup.object().shape({
    fname: Yup.string()
        .trim()
        .max(30, 'Must be 30 characters or less')
        .required("Firstname is required"),
    mi: Yup.string()
        .trim()
        .max(1, 'Must be 1 character only')
        .matches(/^([a-z\s A-Z])+$/, "Valid characters from A-Z only"),
    lname: Yup.string()
        .trim()
        .max(30, 'Must be 30 characters or less')
        .required("Lastname is required."),
    phase: Yup.string()
        .trim()
        .required("Phase is required."),
    block: Yup.string()
        .trim()
        .matches(/^\d+$/, "Please input valid numbers.")
        .required("Block number is required."),
    lot: Yup.string()
        .trim()
        .matches(/^\d+$/, "Please input valid numbers.")
        .required("Lot number is required."),
    date: Yup.string()
        .required("Date is required")
        .test('isValidDate', 'Invalid date', function (value) {
            if (!value) {
              return true;
            }
            const parsedDate = new Date(value);
            return !isNaN(parsedDate.getTime());
          }),
    total_amount: Yup.string()
        .trim()
        .max(20, 'Must be 20 characters or less')
        // .matches(/^\d*\.?\d*$/, "Please input valid numbers.")
        .test('is-not-negative', 'Amount cannot be negative value or invalid.', value => {
            if (typeof value === 'string') {
              const numericValue = parseFloat(value);
              return !isNaN(numericValue) && numericValue >= 0;
            }
            return false; 
          })
        .required("Amount to apply is required."),
    tbd: Yup.string()
        .trim()
        .max(20, 'Must be 20 characters or less')
        // .matches(/^\d*\.?\d*$/, "Please input valid numbers.")
        .test('is-not-negative', 'TBD cannot be negative value or invalid.', value => {
            if (typeof value === 'string') {
              const numericValue = parseFloat(value);
              return !isNaN(numericValue) && numericValue >= 0;
            }
            return false; 
          })
          .required('TBD is required.'),
    memo: Yup.string()
        .trim()
        .max(100, 'Must be 100 characters or less')
        .required("Memo is required."),
    confirm: Yup
        .bool()
        .oneOf([true], "Please confirm.")
        .required("Please confirm."),
  });

  interface SystemData {
    user_acct_no: number;
    charge_amount: number;
    // association_no: number;
    // memo_no: number;
    payment_no: number;
  }

  interface AssociationData {
    id: number;
    association_no: number;
    user_id: number;
    account: number;
    fname: string;
    mi: string;
    lname: string;
    phase: string;
    block: string;
    lot: string;
    cdate: string;
    total_amount: number;
    balance: number;
    accounting_id: number;
    manager_id: number;
    applied: string;
    editable: boolean;
  }

const ITEMS_PER_PAGE = 50;

const Credit_Memo: React.FC = () =>{
    const loading = useSelector((state: RootState) => state.Admin_PaymentReducer.loading);
    const success = useSelector((state: RootState) => state.Admin_PaymentReducer.redirectPath);
    const failed = useSelector((state: RootState) => state.Admin_PaymentReducer.error);
    const searchfailed = useSelector((state: RootState) => state.Admin_PaymentReducer.searcherror);
    const token = getLocalStorageVariable<string>('token');
    const [systemData, setSystemData] = useState<SystemData[] | null>(null);
    const [associationAllData, setAllAssociationData] = useState<AssociationData[] | null>(null);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [tbdAmount, setTBD] = useState<any>(0);
    const [creditamount, setCreditAmount] = useState<any>(0);
    const [memo, setMemo] = useState<any>('');
    const [cDate, setCDate] = useState('');
    // const [overapplied, setOverApplied] = useState<any>(0);
    const [paymentfor, setPaymentFor] = useState('association');
    const accountSession = getSessionVariable<any>('account');
    const fname = getSessionVariable<string>('fname');
    const mi = getSessionVariable<string>('mi');
    const lname = getSessionVariable<string>('lname');
    const phase = getSessionVariable<string>('phase');
    const block = getSessionVariable<string>('block');
    const lot = getSessionVariable<string>('lot');
    const id = '1';
    const payment_create = getLocalStorageVariable<string>('payment_create');

    // ***** Fetch System Data *****
    const fetchSystemData = useCallback(async () => {
        const headers = {
            Authorization: `${token}`,
          };
        try {
            const res: AxiosResponse<SystemData[]> = await axios.post(`${apiUrl.url}admin_get_system_account/`, { id: id }, { headers });
            setSystemData(res.data);
        } catch (error) {
            console.error('Error: ', error);
            return false;
        }
    }, [id, token]);


    // ***** Fetch the User Association Bill *****
    const fetchAllAssociation = useCallback(async () => {
        const headers = {
            Authorization: `${token}`,
          };
        try {
            const res: AxiosResponse<AssociationData[]> = await axios.get(`${apiUrl.url}admin_payment/bill`, { headers, params: { search: accountSession, paymentfor: paymentfor } });
            setAllAssociationData(res.data);
        } catch (error) {
            console.error('Error: ', error);
        }
    }, [accountSession, paymentfor, token]);

    useEffect(() => {
        fetchSystemData();
        fetchAllAssociation();
    }, [ fetchAllAssociation, fetchSystemData ]);

    // **** Handle edit in the table ******
    const handleClick = (id: number) => {
        if (associationAllData) {
          const updatedData = associationAllData.map((item) =>
            item.id === id ? { ...item, editable: true } : item
          );
          setAllAssociationData(updatedData);
        }
      };
      
      const handleCellChange = (id: number, value: any) => {
        if (associationAllData) {
          const updatedData = associationAllData.map((item) =>
            item.id === id ? { ...item, applied: value.replace(/[^\d.]/g, '') } : item
          );
          setAllAssociationData(updatedData);
        }
      };

      const handleBlurOrEnter = (id: number, value: string) => {
        const item = associationAllData?.find((item) => item.id === id);
            if (!item) {
                console.error("Item not found in state.");
                return;
            }
        const balanceValue = item.balance;
            if (parseFloat(value) > balanceValue) {
                setTBD(parseFloat(creditamount) - (totalApplied || 0));
                return;
            }else{
                const formattedValue = parseFloat(value).toLocaleString("en-US", { maximumFractionDigits: 2, minimumFractionDigits: 2 });
                handleCellChange(id, formattedValue);
                setTBD(parseFloat(creditamount) - (totalApplied || 0));
            }
      };

      const handleKeyDown = (id: number, e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === 'Enter') {
          const target = e.currentTarget as HTMLInputElement;
          target.blur();
          handleBlurOrEnter(id, target.value);
        }
      };

    // **** Total the amount applied ******
    const totalApplied = associationAllData?.reduce((total, item) => {
        const appliedValue = parseFloat(item.applied);
        const balanceValue = item.balance;
      
        if (!isNaN(appliedValue) && !isNaN(balanceValue) && appliedValue <= balanceValue) {
          return total + appliedValue;
        } else {
        //   console.error(`Invalid applied value for item with ID ${item.id}`);
          return total;
        }
      }, 0);
      

    // **** Set current date ******
    useEffect(() => {
        const currentDate = new Date();
        const formattedDate = formatDate(currentDate);
        setCDate(formattedDate);
    }, []); 

    const formatDate = (date: Date): string => {
        const year = date.getFullYear();
        const month = (date.getMonth() + 1).toString().padStart(2, '0'); 
        const day = date.getDate().toString().padStart(2, '0');

        return `${year}-${month}-${day}`;
    };

    // **** Total Amount on blur ******
    const handleAmountBlur = (value: string) => {
        setCreditAmount(parseFloat(value));
        setTBD(parseFloat(value) - (totalApplied || 0));
      };
    
    const handleMemoBlur = (value: string) => {
        setMemo(value);
      };

    const handleDateChange = (event: any) => {
        const newDate = event.target.value;
            setCDate(newDate);
      };

    const handleSearch = (values: any) => {
        const { search } = values;
        dispatch(paymentSearchRequest(search));
      };
    
    const handleItemClick = (path: string) => {
        setSessionVariable('setSelectedItem', path);
        navigate(path);
    };

    const handleSubmit = (values: any) => {
        let ctr = 0;
        if (associationAllData) {
            associationAllData?.forEach((r, i) => {
                if(parseFloat(r.applied) <= r.balance && r.applied != ''){
                    const totalAmountWithoutCommas = values.total_amount.replace(/,/g, '');
                    const tbdWithoutCommas = values.tbd.replace(/,/g, '');
                    const payload = {
                        payment_no: systemData ? Number(systemData[0]?.payment_no) : 0,
                        type: 'credit',
                        user_id: r.user_id,
                        account: r.account,
                        fname: r.fname,
                        mi: r.mi,
                        lname: r.lname,
                        phase: r.phase,
                        block: r.block,
                        lot: r.lot,
                        date: values.date,
                        total_amount: parseFloat(totalAmountWithoutCommas),
                        tbd: parseFloat(tbdWithoutCommas),
                        memo: values.memo,
                        association_no: r.association_no,
                        association_amount: r.total_amount,
                        balance: r.balance - parseFloat(r.applied),
                        applied: parseFloat(r.applied),
                        loadfor: 'details',
                    };
                    dispatch(paymentRequest(payload));
                }
                if((ctr == 0) && (parseFloat(r.applied) <= r.balance && r.applied != '')){
                    ctr = ctr + 1
                    const totalAmountWithoutCommas = values.total_amount.replace(/,/g, '');
                    const tbdWithoutCommas = values.tbd.replace(/,/g, '');
                    const payload = {
                        payment_no: systemData ? Number(systemData[0]?.payment_no) : 0,
                        type: 'credit',
                        user_id: r.user_id,
                        account: r.account,
                        fname: r.fname,
                        mi: r.mi,
                        lname: r.lname,
                        phase: r.phase,
                        block: r.block,
                        lot: r.lot,
                        date: values.date,
                        total_amount: parseFloat(totalAmountWithoutCommas),
                        tbd: parseFloat(tbdWithoutCommas),
                        memo: values.memo,
                        association_no: r.association_no,
                        association_amount: r.total_amount,
                        balance: r.balance - parseFloat(r.applied),
                        applied: parseFloat(r.applied),
                        loadfor: 'headers',
                    };
                    dispatch(paymentRequest(payload));
                }
            });
        } else {
            console.error("systemData is null");
        }
    };

    return (
        <div>
            {loading?<LoadingPage/>:""}
            {success?<CreateCreditMemoSuccess/>:""}
            {failed?<CreateCreditMemoFailed/>:""}
            {searchfailed?<SearchFailed/>:""}

            <div className="container-fluid ">
                {payment_create != '1'? <AuthAccessFailed />:""}

                <Row className="justify-content-md-center">
                    <Col>
                        <SubMenu/>
                    </Col>
                    <Col xs lg="10"  className="p-3 bg-white rounded shadow-sm ">
                        <h4 className="text-danger" ><AiOutlineDollar size="30" /> Payment</h4>
                            <Breadcrumb>
                                <Breadcrumb.Item onClick={() => handleItemClick('/admin_payment_menu')}>Payment Menu</Breadcrumb.Item>
                                <Breadcrumb.Item active>Payment</Breadcrumb.Item>
                            </Breadcrumb>
                            <hr/>
                        <div style = {{ width:"400px"}} />
                        <Alert style={{border: "1px solid rgb(128, 128, 128)", backgroundColor: '#fff5f5'}} className='mt-2 shadow-lg'>
                            <Formik
                                enableReinitialize={true}
                                initialValues={{ 
                                    search: accountSession || '',
                                    payment_no: systemData ? Number(systemData[0]?.payment_no) : "",
                                    paymentfor: '',
                                }}
                                validationSchema={SearchSchema}
                                onSubmit={handleSearch}
                                >
                                <Form>
                                    <Row>
                                        <Col sm>   
                                            <InputGroup className="mt-1 mb-1">
                                                <InputGroup className="w-25">
                                                    <InputGroup.Text className="w-100">
                                                        Payment #
                                                    </InputGroup.Text>
                                                </InputGroup>
                                                <Field 
                                                    type="text" 
                                                    name="payment_no" 
                                                    maxLength="10"
                                                    placeholder="Payment No"
                                                    className="w-75 form-control"
                                                    disabled={true}
                                                />
                                                <ErrorMessage name="payment_no">
                                                    {msg => <div style={{color:'red',padding:'5px'}}>{msg}</div>}
                                                </ErrorMessage>
                                            </InputGroup>
                                        </Col>
                                        <Col sm>   
                                            <InputGroup className="mt-1 mb-1">
                                                <InputGroup className="w-25">
                                                    <InputGroup.Text className="w-100">
                                                        For <strong className='text-danger'>&nbsp;*</strong>
                                                    </InputGroup.Text>
                                                </InputGroup>
                                                    <Field 
                                                        as="select" 
                                                        name="paymentfor" 
                                                        maxLength="10"
                                                        placeholder="Account Number"
                                                        className="w-75 form-control"
                                                        // disabled={true}
                                                    >
                                                        <option value="">- Payment For -</option>
                                                        <option value="association">Association Bill</option>
                                                        <option value="association_adj">Association Debit Memo</option>
                                                        <option value="water">Water Bill</option>
                                                        <option value="water_adj">Water Debit Memo</option>
                                                    </Field>
                                                <ErrorMessage name="paymentfor">
                                                    {msg => <div style={{color:'red',padding:'5px'}}>{msg}</div>}
                                                </ErrorMessage>
                                            </InputGroup>
                                        </Col>
                                        <Col sm>   
                                            <InputGroup className="mt-1 mb-1">
                                                <InputGroup className="w-25">
                                                    <InputGroup.Text className="w-100">
                                                        Account # 
                                                    </InputGroup.Text>
                                                </InputGroup>
                                                <Field 
                                                    type="text" 
                                                    name="search" 
                                                    maxLength="10"
                                                    placeholder="Account #"
                                                    className="w-50 form-control"
                                                />
                                                <Button type="submit" variant="outline-success"><BiSearchAlt size="20" className='ms-2 me-2'/></Button>
                                                <ErrorMessage name="search">
                                                    {msg => <div style={{color:'red',padding:'5px'}}>{msg}</div>}
                                                </ErrorMessage>
                                            </InputGroup>
                                        </Col>
                                    </Row>
                                </Form>
                            </Formik>
                            
                            <Formik
                                enableReinitialize={true}
                                initialValues={{
                                    fname: fname || '',
                                    mi: mi || '',
                                    lname: lname || '',
                                    phase: phase || '',
                                    block: block || '',
                                    lot: lot || '',
                                    date: cDate,
                                    total_amount: creditamount != null ? creditamount.toLocaleString("en-US", { maximumFractionDigits: 2, minimumFractionDigits: 2 }) : 0,
                                    tbd: tbdAmount != null ? tbdAmount.toLocaleString("en-US", { maximumFractionDigits: 2, minimumFractionDigits: 2 }) : 0,
                                    memo: memo,
                                    applied: '',
                                    confirm: false,
                                }}
                                validationSchema={CreateSchema}
                                onSubmit={handleSubmit}
                                >
                                <Form>
                                    <Alert style={{border: "1px solid rgb(128, 128, 128)"}} className='mt-2 bg-light'>
                                    <Row>
                                        <Col sm>   
                                            <InputGroup className="mt-1 mb-1">
                                                <InputGroup className="w-25">
                                                    <InputGroup.Text className="w-100">
                                                        Firstname 
                                                    </InputGroup.Text>
                                                </InputGroup>
                                                <Field 
                                                    type="text" 
                                                    name="fname"
                                                    maxLength="30"
                                                    className="w-75 form-control"
                                                    disabled={true}
                                                />
                                                <ErrorMessage name="fname">
                                                    {msg => <div style={{color:'red',padding:'5px'}}>{msg}</div>}
                                                </ErrorMessage>
                                            </InputGroup>
                                        </Col>
                                        <Col sm>   
                                            <InputGroup className="mt-1 mb-1">
                                                <InputGroup className="w-25">
                                                    <InputGroup.Text className="w-100">
                                                        MI
                                                    </InputGroup.Text>
                                                </InputGroup>
                                                <Field 
                                                    type="text" 
                                                    name="mi" 
                                                    maxLength="1"
                                                    className="w-75 form-control"
                                                    disabled={true}
                                                />
                                                <ErrorMessage name="mi">
                                                    {msg => <div style={{color:'red',padding:'5px'}}>{msg}</div>}
                                                </ErrorMessage>
                                            </InputGroup>
                                        </Col>
                                        <Col sm>   
                                            <InputGroup className="mt-1 mb-1">
                                                <InputGroup className="w-25">
                                                    <InputGroup.Text className="w-100">
                                                        Lastname 
                                                    </InputGroup.Text>
                                                </InputGroup>
                                                <Field 
                                                    type="text" 
                                                    name="lname" 
                                                    maxLength="30"
                                                    className="w-75 form-control"
                                                    disabled={true}
                                                />
                                                <ErrorMessage name="lname">
                                                    {msg => <div style={{color:'red',padding:'5px'}}>{msg}</div>}
                                                </ErrorMessage>
                                            </InputGroup>
                                        </Col>
                                    </Row>

                                    <Row>
                                        <Col sm>   
                                            <InputGroup className="mt-1 mb-1">
                                                <InputGroup className="w-25">
                                                    <InputGroup.Text className="w-100">
                                                        Phase
                                                    </InputGroup.Text>
                                                </InputGroup>
                                                <Field 
                                                    type="text" 
                                                    name="phase" 
                                                    maxLength="5"
                                                    className="w-75 form-control"
                                                    disabled={true}
                                                />
                                                <ErrorMessage name="phase">
                                                    {msg => <div style={{color:'red',padding:'5px'}}>{msg}</div>}
                                                </ErrorMessage>
                                            </InputGroup>
                                        </Col>
                                        <Col sm>   
                                            <InputGroup className="mt-1 mb-1">
                                                <InputGroup className="w-25">
                                                    <InputGroup.Text className="w-100">
                                                        Block 
                                                    </InputGroup.Text>
                                                </InputGroup>
                                                <Field 
                                                    type="text" 
                                                    name="block" 
                                                    maxLength="5"
                                                    className="w-75 form-control"
                                                    disabled={true}
                                                />
                                                <ErrorMessage name="block">
                                                    {msg => <div style={{color:'red',padding:'5px'}}>{msg}</div>}
                                                </ErrorMessage>
                                            </InputGroup>
                                        </Col>
                                        <Col sm>   
                                            <InputGroup className="mt-1 mb-1">
                                                <InputGroup className="w-25">
                                                    <InputGroup.Text className="w-100">
                                                        Lot 
                                                    </InputGroup.Text>
                                                </InputGroup>
                                                <Field 
                                                    type="text" 
                                                    name="lot" 
                                                    maxLength="5"
                                                    className="w-75 form-control"
                                                    disabled={true}
                                                />
                                                <ErrorMessage name="lot">
                                                    {msg => <div style={{color:'red',padding:'5px'}}>{msg}</div>}
                                                </ErrorMessage>
                                            </InputGroup>
                                        </Col>
                                    </Row>
                                </Alert>

                                    <Row>
                                        <Col sm>   
                                            <InputGroup className="mt-1 mb-1">
                                                <InputGroup className="w-25">
                                                    <InputGroup.Text className="w-100">
                                                        Date <strong className='text-danger'>&nbsp;*</strong>
                                                    </InputGroup.Text>
                                                </InputGroup>
                                                <Field 
                                                    type="date" 
                                                    name="date"
                                                    className="w-75 form-control text-center"
                                                    onChange={(event:any) => handleDateChange(event)}
                                                />
                                                <ErrorMessage name="date">
                                                    {msg => <div style={{color:'red',padding:'5px'}}>{msg}</div>}
                                                </ErrorMessage>
                                            </InputGroup>
                                        </Col>
                                        <Col sm>   
                                            <InputGroup className="mt-1 mb-1">
                                                <InputGroup className="w-25">
                                                    <InputGroup.Text className="w-100">
                                                        MOP <strong className='text-danger'>&nbsp;*</strong>
                                                    </InputGroup.Text>
                                                </InputGroup>
                                                    <Field 
                                                        as="select" 
                                                        name="security" 
                                                        maxLength="10"
                                                        placeholder="Account Number"
                                                        className="w-75 form-control"
                                                        // disabled={true}
                                                    >
                                                        <option value="cash">Cash</option>
                                                        <option value="check">Check</option>
                                                        <option value="bank">Bank Transfer</option>
                                                        <option value="wallet">Online Wallet</option>
                                                    </Field>
                                                <ErrorMessage name="security">
                                                    {msg => <div style={{color:'red',padding:'5px'}}>{msg}</div>}
                                                </ErrorMessage>
                                            </InputGroup>
                                        </Col>
                                        <Col sm> </Col>
                                    </Row>
                                    <Row>
                                        <Col sm>   
                                            <InputGroup className="mt-1 mb-1">
                                                <InputGroup className="w-25">
                                                    <InputGroup.Text className="w-100">
                                                        Amount <strong className='text-danger'>&nbsp;*</strong>
                                                    </InputGroup.Text>
                                                </InputGroup>
                                                <Field 
                                                    type="text" 
                                                    name="total_amount" 
                                                    maxLength="11"
                                                    placeholder="0.00"
                                                    className="w-75 form-control text-end"
                                                    onBlur={(e: any) => handleAmountBlur(e.target.value)}
                                                />
                                                <ErrorMessage name="total_amount">
                                                    {msg => <div style={{color:'red',padding:'5px'}}>{msg}</div>}
                                                </ErrorMessage>
                                            </InputGroup>
                                        </Col>
                                        <Col sm>   
                                            <InputGroup className="mt-1 mb-1">
                                                <InputGroup className="w-25">
                                                    <InputGroup.Text className="w-100">
                                                        TBD
                                                    </InputGroup.Text>
                                                </InputGroup>
                                                <Field 
                                                    type="text" 
                                                    name="tbd"
                                                    className="w-75 form-control text-end"
                                                    disabled={true}
                                                />
                                                <ErrorMessage name="tbd">
                                                    {msg => <div style={{color:'red',padding:'5px'}}>{msg}</div>}
                                                </ErrorMessage>
                                            </InputGroup>
                                        </Col>
                                        <Col sm> </Col>
                                    </Row>

                                    <Row>
                                        <Col sm={10}>   
                                            <InputGroup className="mt-1 mb-1">
                                                <InputGroup className="w-25">
                                                    <InputGroup.Text className="w-100">
                                                        Remarks <strong className='text-danger'>&nbsp;*</strong>
                                                    </InputGroup.Text>
                                                </InputGroup>
                                                <Field 
                                                    type="text" 
                                                    name="memo" 
                                                    maxLength="100"
                                                    placeholder="Memo"
                                                    className="w-75 form-control"
                                                    onBlur={(e: any) => handleMemoBlur(e.target.value)}
                                                />
                                                <ErrorMessage name="memo">
                                                    {msg => <div style={{color:'red',padding:'5px'}}>{msg}</div>}
                                                </ErrorMessage>
                                            </InputGroup>
                                        </Col>
                                        <Col sm> </Col>
                                    </Row>

                                    <Table responsive striped bordered hover size="sm" className='mt-3'>
                                        <thead>
                                            <tr className="text-center border-secondary">
                                                <th>#</th>
                                                <th>Date</th>
                                                <th>Bill #</th>
                                                <th>Name</th>
                                                <th>Phase</th>
                                                <th>Block</th>
                                                <th>Lot</th>
                                                <th>Total</th>
                                                <th>Balance</th>
                                                <th>Applied Amount</th>
                                            </tr>
                                        </thead>
                                            {associationAllData?.length!=0? (
                                                <tbody>
                                                    {associationAllData?.map((r, i) => (
                                                    <tr key={i} className="align-middle text-secondary">
                                                        <td className="text-center align-middle">{i + 1}</td>
                                                        <td className="text-center align-middle">{(new Date(r.cdate)).toLocaleDateString("en-PH")}</td>
                                                        <td className="text-center align-middle">{r.association_no}</td>
                                                        <td className="">{r.fname} {r.mi}. {r.lname}</td>
                                                        <td className="text-center align-middle">{r.phase}</td>
                                                        <td className="text-center align-middle">{r.block}</td>
                                                        <td className="text-center align-middle">{r.lot}</td>
                                                        <td className="text-end">
                                                            {(r.total_amount).toLocaleString("en-US", { maximumFractionDigits: 2, minimumFractionDigits: 2 })}
                                                        </td>
                                                        <td className="text-end">
                                                            {(r.balance).toLocaleString("en-US", { maximumFractionDigits: 2, minimumFractionDigits: 2 })}
                                                        </td>
                                                        <td
                                                            onClick={() => handleClick(r.id)}
                                                            style={{ cursor: 'pointer' }}
                                                            >
                                                            {r.editable ? (
                                                                <Field
                                                                type="number"
                                                                name="applied"
                                                                style={{border:'none', backgroundColor:'transparent' }}
                                                                className='w-100 text-end'
                                                                value={r.applied !== undefined ? r.applied : ''}
                                                                onChange={(e: any) => handleCellChange(r.id, e.target.value)}
                                                                onKeyDown={(e: any) => handleKeyDown(r.id, e)}
                                                                onBlur={(e: any) => handleBlurOrEnter(r.id, e.target.value)}
                                                              />
                                                            ) : (
                                                                r.applied !== undefined ? r.applied : ''
                                                            )}
                                                            </td>
                                                    </tr>
                                                    ))}
                                                </tbody>
                                            ) : (
                                                <tbody>
                                                    <tr><td colSpan={15} className='text-danger'>No association bill found...</td></tr>
                                                </tbody>
                                            )}
                                                <tbody>
                                                    <tr><td colSpan={15} className='p-2'></td></tr>
                                                    <tr>
                                                        <td colSpan={7} className='text-center fw-bold'>Total Amount</td>
                                                        <td className='fw-bold text-end'>{associationAllData?.reduce((total, currentValue) => total = total + currentValue.total_amount,0).toLocaleString("en-US", { maximumFractionDigits: 2, minimumFractionDigits: 2 })}</td>
                                                        <td className='fw-bold text-end'>{associationAllData?.reduce((total, currentValue) => total = total + currentValue.balance,0).toLocaleString("en-US", { maximumFractionDigits: 2, minimumFractionDigits: 2 })}</td>
                                                        {/* <td className='fw-bold text-end pe-3'>{associationAllData?.reduce((total, currentValue) => total + parseFloat(currentValue?.applied || '0'), 0).toLocaleString("en-US", { maximumFractionDigits: 2, minimumFractionDigits: 2 })}</td> */}
                                                        <td className='fw-bold text-end pe-3'>{(creditamount - tbdAmount).toLocaleString("en-US", { maximumFractionDigits: 2, minimumFractionDigits: 2 })}</td>
                                                    </tr>
                                                </tbody>
                                    </Table>
                                    <div className='text-danger'>
                                        {(creditamount - tbdAmount)==0?<div>Credit memo needs to be applied to at least one Association Bill.</div>:''}
                                    </div>
                                    <br/>
                                        <Col sm> 
                                            <label>
                                                <Field type="checkbox" name="confirm" />
                                                &nbsp;Please confirm to save credit memo.
                                            </label>
                                            <ErrorMessage name="confirm">
                                                {msg => <div style={{color:'red',padding:'5px'}}>{msg}</div>}
                                            </ErrorMessage>
                                        </Col>
                                    <br/>
                                    <div className="d-grid gap-2">
                                        <Button variant="primary" type="submit" className="btn btn-primary btn-block rounded-pill mb-3" >
                                            <AiOutlineSend size="20"/> Save
                                        </Button>
                                    </div>
                                </Form>
                            </Formik>
                        </Alert>
                    </Col>
                </Row>
            </div>
        </div>
    );
};

export default Credit_Memo;