import React, { useState, useEffect, useCallback } from 'react';
import axios, { AxiosResponse } from 'axios';
import { 
    Row, 
    Col,
    InputGroup,
    Button,
    Alert,
    Image,
    Breadcrumb,
    Modal,
} from 'react-bootstrap';
import { Formik, Field, Form, ErrorMessage, useFormikContext } from 'formik';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import * as Yup from 'yup';
import { AiOutlineSend, AiOutlineIdcard } from "react-icons/ai";
import { PiEyeLight } from "react-icons/pi";
import { PiEyeSlash } from "react-icons/pi";
import { MdOutlinePassword } from "react-icons/md";
import { BiSearchAlt } from "react-icons/bi";
// import me from '../../../images/avatar/23.jpg';
import SubMenu from '../submenu';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../redux/reducers';
import { updateAdminUserRequest, updateAdminPasswordRequest } from './redux/adminHeadersActions';
// import { LoadingPage, UpdateAdminUserSuccess, CreateUserFailed, AuthAccessFailed } from '../../../components/loader';
import { LoadingPage } from '../../../components/loader';
import { UpdateUserSuccess, UpdateUserFailed, UpdatePasswordSuccess, UpdatePasswordFailed } from './loader';
import { getLocalStorageVariable } from '../../../components/localStorage';
import apiUrl from '../../../components/apiurl';
import CryptoJS from 'crypto-js';

declare const require: {
    context(directory: string, useSubdirectories?: boolean, regExp?: RegExp): {
      keys(): string[];
      <T>(id: string): T;
    };
  };
  const importAll = (context: any) => context.keys().map(context);
  const images = importAll(require.context('../../../images/avatar/', false, /\.(png|jpe?g|svg)$/));

  interface MyVerticallyCenteredModalProps {
    show: boolean;
    onHide: () => void;
    setAvatar: React.Dispatch<React.SetStateAction<string | null>>;
    setShow: React.Dispatch<React.SetStateAction<boolean>>; 
  }

  const MyVerticallyCenteredModal: React.FC<MyVerticallyCenteredModalProps> = ({ show, onHide, setAvatar, setShow }) => {
    const { setFieldValue } = useFormikContext();

    const handleImageClick = (src: string) => {
        setFieldValue('avatar', src);
        setAvatar(src);
        handleClose();
      };
    
      const handleClose = () => {
        setShow(false);
      };
    
      return (
        <Modal size="lg" aria-labelledby="contained-modal-title-vcenter" centered show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">Select Avatar</Modal.Title>
        </Modal.Header>
        <Modal.Body className="grid-example">
          <div>
            <div className="d-flex flex-wrap">
              {images.map((src: any, index: any) => (
                <div key={index} onClick={() => handleImageClick(src)}>
                  <div className="p-2">
                    <img src={src} alt={`Avatar ${index}`} width="60" height="60" style={{cursor:'pointer'}} />
                  </div>
                </div>
              ))}
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={onHide}>Close</Button>
        </Modal.Footer>
      </Modal>
    );
  }

const CreateSchema = Yup.object().shape({
    id: Yup.string()
        .required("ID is required"),
    fname: Yup.string()
        .trim()
        .max(30, 'Must be 30 characters or less')
        .matches(/^([a-z\s A-Z])+$/, "Valid characters from A-Z only")
        .required("Firstname is required"),
    mi: Yup.string()
        .trim()
        .max(1, 'Must be 1 character only')
        .matches(/^([a-z\s A-Z])+$/, "Valid characters from A-Z only"),
    lname: Yup.string()
        .trim()
        .max(30, 'Must be 30 characters or less')
        .matches(/^([a-z\s A-Z])+$/, "Valid characters from A-Z only")
        .required("Lastname is required."),
    pin: Yup.string()
        .trim()
        .max(6, 'Must be 6 character only')
        .matches(/^[0-9]{6}$/, "Please input 6-digit pin code")
        .required('PIN is required'),
    cellphone: Yup.string()
        .trim()
        .max(11, 'Must be 11 character only')
        .matches(/^[0-9]{11}$/, "Please input valid cellphone # ex: 09XXXXXXXX.")
        .required("Cellphone number is required."),
    email: Yup.string()
        .trim()
        .max(50, 'Must be 50 character only')
        .email("Enter a valid email.")
        .required("Email address is required."),
    // password: Yup.string()
    //     .required("Password is required.")
    //     .min(6, "Password length must be at least 6 characters."),
    // confirm_password: Yup.string()
    //     .required("Confirm Password is required")
    //     .min(6, "Password length must be at least 6 characters.")
    //     .oneOf([Yup.ref("password")], "Passwords do not match."),
    avatar: Yup.string()
        .trim()
        .max(50, 'Must be 50 character only'),
    confirm: Yup
        .bool()
        .oneOf([true], "Please confirm.")
        .required("Please confirm."),
  });

  const ChangePasswordSchema = Yup.object().shape({
    password: Yup.string()
        .required("Password is required")
        .min(8, "Password length must be at least 8 characters")
        .matches(/[a-z]/, "Password must contain at least one lowercase letter")
        .matches(/[A-Z]/, "Password must contain at least one uppercase letter")
        .matches(/[0-9]/, "Password must contain at least one number")
        .matches(/[@$!%*?&]/, "Password must contain at least one special character (@, $, !, %, *, ?, &)")
        .trim(),
    confirm_password: Yup.string()
        .required("Confirm Password is required")
        .oneOf([Yup.ref("password")], "Passwords do not match"),
    confirm_change: Yup
        .bool()
        .oneOf([true], "Please confirm.")
        .required("Please confirm."),
  });


  interface UserData {
    id: number;
    auth: string;
    fname: string;
    mi: string;
    lname: string;
    cellphone: string;
    email: string;
    pin: string;
  }

const Profile: React.FC = () =>{
    const failed = useSelector((state: RootState) => state.Admin_HeadersReducer.error);
    const loading = useSelector((state: RootState) => state.Admin_HeadersReducer.loading);
    const success = useSelector((state: RootState) => state.Admin_HeadersReducer.info);
    const password_failed = useSelector((state: RootState) => state.Admin_HeadersReducer.password_error);
    const password_loading = useSelector((state: RootState) => state.Admin_HeadersReducer.password_loading);
    const password_success = useSelector((state: RootState) => state.Admin_HeadersReducer.password_info);
    const userId = getLocalStorageVariable<number>('userId');
    const userAuth = getLocalStorageVariable<string>('userAuth');
    const userAvatar = getLocalStorageVariable<string>('avatar');
    const token = getLocalStorageVariable<string>('token');
    const [avatar, setAvatar] = useState<string | null>(userAvatar);
    const [modalShow, setModalShow] = useState(false);

    const [dataUser, setUser] = useState<UserData[] | null>(null);
    const [auth, setAuth] = useState('');
    const [fname, setFname] = useState('');
    const [mi, setMi] = useState('');
    const [lname, setLname] = useState('');
    const [cellphone, setCellphone] = useState('');
    const [email, setEmail] = useState('');
    const [pin, setPin] = useState('');
    const dispatch = useDispatch();
    const [showModal, setShowModal] = useState(false);
    const [updateValues, setUpdateValues] = useState({
        id: 0,
        status: 0,
    });

    // ****** decrypt Data *****
    const encryptionKey = process.env.REACT_APP_DE_EN ?? ''; 
    const ivHex = '00000000000000000000000000000000'; // Fixed IV as used in the backend

    const decryptData = (encryptedData: string, encryptionKey: string): string => {
        const key = CryptoJS.enc.Utf8.parse(encryptionKey); // Use Utf8 parsing for consistency
        const iv = CryptoJS.enc.Hex.parse(ivHex);
    
        // Convert the hex string to bytes, then decrypt
        const encryptedHexStr = CryptoJS.enc.Hex.parse(encryptedData); // Interpret hex as bytes
        const encryptedBase64 = CryptoJS.enc.Base64.stringify(encryptedHexStr); // Convert bytes to Base64
    
        // Decrypt the Base64 string
        const bytes = CryptoJS.AES.decrypt(encryptedBase64, key, {
        iv: iv,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7,
        });
    
        return bytes.toString(CryptoJS.enc.Utf8);
    };

    const [showConfirmPassword, setShowConfirmPassword] = useState(false); // State for confirm password visibility
    const toggleConfirmPasswordVisibility = () => {
        setShowConfirmPassword(prevState => !prevState); // Toggle confirm password visibility
    };

    const [showPassword, setShowPassword] = useState(false); // State for password visibility
    const togglePasswordVisibility = () => {
        setShowPassword(prevState => !prevState); // Toggle password visibility
    };

    const [showPin, setShowPin] = useState(false); // State for pin visibility
    const togglePinVisibility = () => {
        setShowPin(prevState => !prevState); // Toggle pin visibility
    };
  

    const fetchUser = useCallback(async () => {
        const headers = {
            Authorization: `${token}`,
          };
        try {
            const res: AxiosResponse<UserData[]> = await axios.get(`${apiUrl.url}admin_get_admin_user/profile`, { headers, params: { id: userId } });
            setUser(res.data.length > 0 ? res.data : null);
            setAuth(res.data[0].auth);
            setFname(decryptData(res.data[0].fname, encryptionKey));
            setMi(res.data[0].mi);
            setLname(decryptData(res.data[0].lname, encryptionKey));
            setCellphone(decryptData(res.data[0].cellphone, encryptionKey));
            setEmail(decryptData(res.data[0].email, encryptionKey));
            setPin(res.data[0].pin);
        } catch (error) {
            console.error('Error: ', error);
        }
    }, [userId, token]);

    useEffect(() => {
        fetchUser();
    }, [ fetchUser ]);
   
    const handleSubmit = (values: any) => {
        const payload = {
            id: values.id,
            fname: values.fname,
            mi: values.mi,
            lname: values.lname,
            pin: values.pin,
            cellphone: values.cellphone,
            email: values.email,
            avatar: values.avatar,
        }
        dispatch(updateAdminUserRequest(payload));
      };


    // ****** Change password modal ******
    const handleStatusUpdate = (values: any) => {
        setUpdateValues(values);
        setShowModal(true);
    };


    const executeChangePassword = (values: any) => {
        const payload = {
            id: values.id,
            fname: fname,
            email: email,
            password: values.password,
        }
        dispatch(updateAdminPasswordRequest(payload));
        // console.log(payload);
      };

    const handleCloseModal = () => {
        setShowModal(false);
    };

    return (
        <div>
        <Formik
            enableReinitialize={true}
            initialValues={{
                id: userId,
                fname: fname,
                mi: mi,
                lname: lname,
                pin: pin,
                cellphone: cellphone,
                email: email,
                avatar: avatar,
                confirm: false,
            }}
            validationSchema={CreateSchema}
            onSubmit={handleSubmit}
            >
            {({ errors, touched }) => (
            <div className="container-fluid ">
                {loading?<LoadingPage/>:""}
                {failed?<UpdateUserFailed/>:""}
                {success?<UpdateUserSuccess/>:""}
                {/* {user_create != '1'? <AuthAccessFailed />:""} */}

                <Row className="justify-content-md-center">
                    <Col lg="2">
                        <SubMenu/>
                    </Col>
                    <Col xs lg="10"  className="p-3 bg-white rounded shadow-sm ">
                        <h4 className="text-primary" ><AiOutlineIdcard size="30" /> Profile</h4>
                        
                            <Breadcrumb>
                                <Breadcrumb.Item href="/admin_dashboard">Dashboard</Breadcrumb.Item>
                                <Breadcrumb.Item active>Create User</Breadcrumb.Item>
                            </Breadcrumb>
                            <hr/>
                        <div style = {{ width:"400px"}} />
                        <Alert style={{border: "1px solid rgb(128, 128, 128)"}} className='mt-2 bg-white shadow-lg'>
                            <Row >
                                <Col sm={3} className='text-center'>
                                    <Image src={avatar || '/static/media/1.1f1d0984d6e0f46c338d.jpg'} width="150" height="150" roundedCircle /><br/>
                                    <Button onClick={() => setModalShow(true)} variant="outline-primary w-75" className='btn btn-block rounded-pill mb-3 mt-2'><BiSearchAlt size="20" className='ms-2 me-2'/> Change Avatar</Button>
                                    <MyVerticallyCenteredModal show={modalShow} onHide={() => setModalShow(false)} setAvatar={setAvatar} setShow={setModalShow} />
                                    <Field 
                                        type="text" 
                                        name="avatar"
                                        maxLength="50"
                                        placeholder="Avatar"
                                        className="w-75 form-control"
                                        hidden
                                        disabled={true}
                                    />
                                    <Field 
                                        type="text" 
                                        name="id"
                                        maxLength="10"
                                        placeholder="Avatar"
                                        className="w-75 form-control"
                                        hidden
                                        disabled={true}
                                    />
                                </Col>
                                <Col sm={9}>
                                    <Alert style={{ backgroundColor:'#e6ffe3'}}>
                                        <Row >
                                            <Col sm={2} className='text-muted'>
                                                User Id
                                            </Col>
                                            <Col sm>
                                                <strong>{userId}</strong>
                                            </Col>
                                        </Row>
                                        <Row >
                                            <Col sm={2} className='text-muted'>
                                                Name
                                            </Col>
                                            <Col sm>
                                                <strong>{fname} {mi} {lname}</strong>
                                            </Col>
                                        </Row>
                                        <Row >
                                            <Col sm={2} className='text-muted'>
                                                Access
                                            </Col>
                                            <Col sm>
                                                <strong>{auth}</strong>
                                            </Col>
                                        </Row>
                                        <Row >
                                            <Col sm={2} className='text-muted'>
                                                Cellphone
                                            </Col>
                                            <Col sm>
                                                <strong>{cellphone}</strong>
                                            </Col>
                                            <Col sm={2} className='text-muted'>
                                                Email
                                            </Col>
                                            <Col sm>
                                                <strong>{email}</strong>
                                            </Col>
                                        </Row>
                                    </Alert>
                                </Col>
                            </Row>

                        <Form>
                            <Row>
                                <Col sm>
                                    <FloatingLabel label="Firstname" className="mb-2">
                                        <Field
                                            type="text"
                                            name="fname"
                                            maxLength="30"
                                            placeholder="Firstname"
                                            className={`w-100 form-control ${touched.fname && errors.fname ? 'is-invalid' : touched.fname ? 'is-valid' : ''}`}
                                        />
                                        <ErrorMessage name="fname">
                                            {msg => <div className="invalid-feedback">{msg}</div>}
                                        </ErrorMessage>
                                    </FloatingLabel>
                                </Col>
                                <Col sm>
                                    <FloatingLabel label="MI" className="mb-2">
                                        <Field
                                            type="text"
                                            name="mi"
                                            maxLength="1"
                                            placeholder="MI"
                                            className={`w-100 form-control ${touched.mi && errors.mi ? 'is-invalid' : touched.mi ? 'is-valid' : ''}`}
                                        />
                                        <ErrorMessage name="mi">
                                            {msg => <div className="invalid-feedback">{msg}</div>}
                                        </ErrorMessage>
                                    </FloatingLabel>
                                </Col>
                                <Col sm>
                                    <FloatingLabel label="Lastname" className="mb-2">
                                        <Field
                                            type="text"
                                            name="lname"
                                            maxLength="30"
                                            placeholder="Lastname"
                                            className={`w-100 form-control ${touched.lname && errors.lname ? 'is-invalid' : touched.lname ? 'is-valid' : ''}`}
                                        />
                                        <ErrorMessage name="lname">
                                            {msg => <div className="invalid-feedback">{msg}</div>}
                                        </ErrorMessage>
                                    </FloatingLabel>
                                </Col>
                            </Row>

                            <Row>
                                <Col sm>   
                                    <FloatingLabel label="PIN" className="mb-2">
                                        <Field
                                            type={showPin ? 'text' : 'password'} // Toggle between text and password
                                            name="pin"
                                            maxLength="6"
                                            placeholder="PIN"
                                            className={`w-100 form-control ${touched.pin && errors.pin ? 'is-invalid' : touched.pin ? 'is-valid' : ''}`}
                                        />
                                            <span 
                                                onClick={togglePinVisibility}
                                                style={{
                                                    position: 'absolute',
                                                    right: '30px',
                                                    top: '50%',
                                                    transform: 'translateY(-50%)',
                                                    cursor: 'pointer',
                                                    color: '#6c757d' // Optional: adjust color as needed
                                                }}
                                                >
                                                {showPin ? <PiEyeSlash size='25' /> : <PiEyeLight size='25' />}
                                            </span>
                                        <ErrorMessage name="pin">
                                            {msg => <div className="invalid-feedback">{msg}</div>}
                                        </ErrorMessage>
                                    </FloatingLabel>
                                </Col>
                                <Col sm>
                                </Col>
                            </Row>    

                            <Row>
                                <Col sm>   
                                    <FloatingLabel label="Cellphone" className="mb-2">
                                        <Field
                                            type="text"
                                            name="cellphone"
                                            maxLength="11"
                                            placeholder="Cellphone"
                                            className={`w-100 form-control ${touched.cellphone && errors.cellphone ? 'is-invalid' : touched.cellphone ? 'is-valid' : ''}`}
                                        />
                                        <ErrorMessage name="cellphone">
                                            {msg => <div className="invalid-feedback">{msg}</div>}
                                        </ErrorMessage>
                                    </FloatingLabel>
                                </Col>
                                <Col sm>
                                </Col>
                            </Row>

                            <Row>
                                <Col sm>   
                                <FloatingLabel label="Email" className="mb-2">
                                        <Field
                                            type="text"
                                            name="email"
                                            maxLength="50"
                                            placeholder="Email"
                                            className={`w-100 form-control ${touched.email && errors.email ? 'is-invalid' : touched.email ? 'is-valid' : ''}`}
                                        />
                                        <ErrorMessage name="email">
                                            {msg => <div className="invalid-feedback">{msg}</div>}
                                        </ErrorMessage>
                                    </FloatingLabel>
                                </Col>
                                <Col sm>
                                    <Button variant="outline-primary w-100" onClick={handleStatusUpdate} className="btn btn-block rounded-pill mt-2" >
                                        <MdOutlinePassword size="20"/> Change Password
                                    </Button>
                                </Col>
                            </Row>

                            <br/>
                                <Col sm> 
                                    <label>
                                        <Field type="checkbox" name="confirm" />
                                        &nbsp;Please confirm for editing your account.
                                    </label>
                                    <ErrorMessage name="confirm">
                                        {msg => <div style={{color:'red',padding:'5px'}}>{msg}</div>}
                                    </ErrorMessage>
                                </Col>
                            <br/>
                            <div className="d-grid gap-2">
                                <Button variant="primary" type="submit" className="btn btn-primary btn-block rounded-pill mb-3" >
                                    <AiOutlineSend size="20"/> Save
                                </Button>
                            </div>
                        </Form>
                        </Alert>
                    </Col>
                </Row>
            </div>
         )}
         </Formik>
 
             <Formik
                 enableReinitialize={true}
                 initialValues={{
                     id: userId,
                     fname: fname,
                     email: email,
                     password: "",
                     confirm_password: "",
                     confirm_change: false,
                 }}
                 validationSchema={ChangePasswordSchema}
                 onSubmit={executeChangePassword}
                 >
                     {({ errors, touched }) => (
                     <div className="container-fluid ">
                         {password_loading?<LoadingPage/>:""}
                         {password_failed?<UpdatePasswordFailed/>:""}
                         {password_success?<UpdatePasswordSuccess/>:""}
                         
                         <Modal show={showModal} onHide={handleCloseModal} centered variant='light'>
                             <Modal.Header className="d-flex justify-content-center">
                                 <Modal.Title><MdOutlinePassword size="80" /> Change Password</Modal.Title>
                             </Modal.Header>
                             <Modal.Body className="justify-content-center">
                                 <Form>
                                     <div>
                                         <Col lg='12'> 
                                             <Alert variant='danger' className=''>
                                                 Input your new password and confirm.
                                             </Alert>
                                         </Col>
 
                                             <Row>
                                                 <Col sm>
                                                     <FloatingLabel label="New Password" className="mb-2">
                                                         <Field
                                                             type={showPassword ? 'text' : 'password'} // Toggle between text and password
                                                             name="password"
                                                             placeholder="New Password"
                                                             className={`w-100 form-control ${touched.password && errors.password ? 'is-invalid' : touched.password ? 'is-valid' : ''}`}
                                                         />
                                                             <span 
                                                                 onClick={togglePasswordVisibility}
                                                                 style={{
                                                                     position: 'absolute',
                                                                     right: '30px',
                                                                     top: '50%',
                                                                     transform: 'translateY(-50%)',
                                                                     cursor: 'pointer',
                                                                     color: '#6c757d' // Optional: adjust color as needed
                                                                 }}
                                                                 >
                                                                 {showPassword ? <PiEyeSlash size='25' /> : <PiEyeLight size='25' />}
                                                             </span>
                                                         <ErrorMessage name="password">
                                                             {msg => <div className="invalid-feedback">{msg}</div>}
                                                         </ErrorMessage>
                                                     </FloatingLabel>
                                                 </Col>
                                             </Row>
                                             <Row>
                                                 <Col sm>
                                                     <FloatingLabel label="Confirm Password" className="mb-2">
                                                         <Field
                                                             type={showConfirmPassword ? 'text' : 'password'} // Toggle between text and password
                                                             name="confirm_password"
                                                             placeholder="Confirm Password"
                                                             className={`w-100 form-control ${touched.confirm_password && errors.confirm_password ? 'is-invalid' : touched.confirm_password ? 'is-valid' : ''}`}
                                                         />
                                                             <span 
                                                                 onClick={toggleConfirmPasswordVisibility}
                                                                 style={{
                                                                     position: 'absolute',
                                                                     right: '30px',
                                                                     top: '50%',
                                                                     transform: 'translateY(-50%)',
                                                                     cursor: 'pointer',
                                                                     color: '#6c757d' // Optional: adjust color as needed
                                                                 }}
                                                                 >
                                                                 {showConfirmPassword ? <PiEyeSlash size='25' /> : <PiEyeLight size='25' />}
                                                             </span>
                                                         <ErrorMessage name="confirm_password">
                                                             {msg => <div className="invalid-feedback">{msg}</div>}
                                                         </ErrorMessage>
                                                     </FloatingLabel>
                                                 </Col>
                                             </Row>
                                             <Col sm className='mt-2 mb-3'> 
                                                 <label>
                                                     <Field type="checkbox" name="confirm_change" />
                                                     &nbsp;Please confirm to change password.
                                                 </label>
                                                 <ErrorMessage name="confirm_change">
                                                     {msg => <div style={{color:'red',padding:'5px'}}>{msg}</div>}
                                                 </ErrorMessage>
                                             </Col>
                                             <Col sm className='d-flex justify-content-center mt-2'> 
                                                 <Button variant="secondary" onClick={handleCloseModal} className='m-2'>Cancel</Button>
                                                 <Button variant="primary" type="submit" className='m-2' >Update</Button>
                                             </Col>
                                     </div>
                                 </Form>
                             </Modal.Body>
                             <Modal.Footer className="d-flex justify-content-center">
                                 
                                 {/* <Button variant="primary" onClick={executeUpdate}>Update</Button> */}
                             </Modal.Footer>
                         </Modal>
                         
                     </div>
                 )}
             </Formik>
        </div>
    );
};

export default Profile;