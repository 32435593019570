import { Payment_Bill, Search_Account, Credit_Memo, Debit_Memo } from './adminPaymentTypes';

export enum PaymentActionTypes {
    // Create
    ADMIN_PAYMENT_CREATE_REQUEST = 'ADMIN_PAYMENT_CREATE_REQUEST',
    ADMIN_PAYMENT_CREATE_SUCCESS = 'ADMIN_PAYMENT_CREATE_SUCCESS',
    ADMIN_PAYMENT_CREATE_FAILURE = 'ADMIN_PAYMENT_CREATE_FAILURE',
    ADMIN_PAYMENT_CREATE_REDIRECT = 'ADMIN_PAYMENT_CREATE_REDIRECT',
    // Search
    ADMIN_PAYMENT_SEARCH_REQUEST = 'ADMIN_PAYMENT_SEARCH_REQUEST',
    ADMIN_PAYMENT_SEARCH_SUCCESS = 'ADMIN_PAYMENT_SEARCH_SUCCESS',
    ADMIN_PAYMENT_SEARCH_FAILURE = 'ADMIN_PAYMENT_SEARCH_FAILURE',
    ADMIN_PAYMENT_SEARCH_REDIRECT = 'ADMIN_PAYMENT_SEARCH_REDIRECT',
    // // Credit Memo
    // ADMIN_PAYMENT_CREDIT_MEMO_REQUEST = 'ADMIN_PAYMENT_CREDIT_MEMO_REQUEST',
    // ADMIN_PAYMENT_CREDIT_MEMO_SUCCESS = 'ADMIN_PAYMENT_CREDIT_MEMO_SUCCESS',
    // ADMIN_PAYMENT_CREDIT_MEMO_FAILURE = 'ADMIN_PAYMENT_CREDIT_MEMO_FAILURE',
    // ADMIN_PAYMENT_CREDIT_MEMO_REDIRECT = 'ADMIN_PAYMENT_CREDIT_MEMO_REDIRECT',
    // // Debit Memo
    // ADMIN_PAYMENT_DEBIT_MEMO_REQUEST = 'ADMIN_PAYMENT_DEBIT_MEMO_REQUEST',
    // ADMIN_PAYMENT_DEBIT_MEMO_SUCCESS = 'ADMIN_PAYMENT_DEBIT_MEMO_SUCCESS',
    // ADMIN_PAYMENT_DEBIT_MEMO_FAILURE = 'ADMIN_PAYMENT_DEBIT_MEMO_FAILURE',
    // ADMIN_PAYMENT_DEBIT_MEMO_REDIRECT = 'ADMIN_PAYMENT_DEBIT_MEMO_REDIRECT',
  }

  export type PaymentAction =
    | CreateRequestAction
    | CreateSuccessAction
    | CreateFailureAction
    | CreateRedirectAction
    // Search
    | SearchRequestAction
    | SearchSuccessAction
    | SearchFailureAction
    | SearchRedirectAction;
    //  // Credit Memo
    // | CreditRequestAction
    // | CreditSuccessAction
    // | CreditFailureAction
    // | CreditRedirectAction
    // // Credit Memo
    // | DebitRequestAction
    // | DebitSuccessAction
    // | DebitFailureAction
    // | DebitRedirectAction;

// ***** Create Payment *****  
  interface CreateRequestAction {
    type: PaymentActionTypes.ADMIN_PAYMENT_CREATE_REQUEST;
    payload: Payment_Bill;
  }
  interface CreateSuccessAction {
    type: PaymentActionTypes.ADMIN_PAYMENT_CREATE_SUCCESS;
    payload: Payment_Bill;
  }
  interface CreateFailureAction {
    type: PaymentActionTypes.ADMIN_PAYMENT_CREATE_FAILURE;
    payload: string;
  }
  interface CreateRedirectAction {
    type: PaymentActionTypes.ADMIN_PAYMENT_CREATE_REDIRECT;
    payload: string;
  }
  
  export const paymentRequest = (info: Payment_Bill): CreateRequestAction => ({
    type: PaymentActionTypes.ADMIN_PAYMENT_CREATE_REQUEST,
    payload: info,
  });
  
  export const paymentSuccess = (info: Payment_Bill): CreateSuccessAction => ({
    type: PaymentActionTypes.ADMIN_PAYMENT_CREATE_SUCCESS,
    payload: info,
  });
  
  export const paymentFailure = (error: string): CreateFailureAction => ({
    type: PaymentActionTypes.ADMIN_PAYMENT_CREATE_FAILURE,
    payload: error,
  });

  export const paymentRedirect = (path: string): CreateRedirectAction => ({
    type: PaymentActionTypes.ADMIN_PAYMENT_CREATE_REDIRECT,
    payload: path,
  });
  

  // ***** Search Association *****  
  interface SearchRequestAction {
    type: PaymentActionTypes.ADMIN_PAYMENT_SEARCH_REQUEST;
    payload: Search_Account;
  }
  interface SearchSuccessAction {
    type: PaymentActionTypes.ADMIN_PAYMENT_SEARCH_SUCCESS;
    payload: Search_Account;
  }
  interface SearchFailureAction {
    type: PaymentActionTypes.ADMIN_PAYMENT_SEARCH_FAILURE;
    payload: string;
  }
  interface SearchRedirectAction {
    type: PaymentActionTypes.ADMIN_PAYMENT_SEARCH_REDIRECT;
    payload: string;
  }
  
  export const paymentSearchRequest = (search: string): SearchRequestAction => ({
    type: PaymentActionTypes.ADMIN_PAYMENT_SEARCH_REQUEST,
    payload: {search},
  });
  
  export const paymentSearchSuccess = (info: Search_Account): SearchSuccessAction => ({
    type: PaymentActionTypes.ADMIN_PAYMENT_SEARCH_SUCCESS,
    payload: info,
  });
  
  export const paymentSearchFailure = (error: string): SearchFailureAction => ({
    type: PaymentActionTypes.ADMIN_PAYMENT_SEARCH_FAILURE,
    payload: error,
  });

  export const paymentSearchRedirect = (path: string): SearchRedirectAction => ({
    type: PaymentActionTypes.ADMIN_PAYMENT_SEARCH_REDIRECT,
    payload: path,
  });


//   // ***** Credit Memo *****  
//   interface CreditRequestAction {
//     type: AssociationActionTypes.CREDIT_MEMO_REQUEST;
//     payload: Credit_Memo;
//   }
//   interface CreditSuccessAction {
//     type: AssociationActionTypes.CREDIT_MEMO_SUCCESS;
//     payload: Credit_Memo;
//   }
//   interface CreditFailureAction {
//     type: AssociationActionTypes.CREDIT_MEMO_FAILURE;
//     payload: string;
//   }
//   interface CreditRedirectAction {
//     type: AssociationActionTypes.CREDIT_MEMO_REDIRECT;
//     payload: string;
//   }
  
//   export const creditMemoRequest = (info: Credit_Memo): CreditRequestAction => ({
//     type: AssociationActionTypes.CREDIT_MEMO_REQUEST,
//     payload: info,
//   });
  
//   export const creditMemoSuccess = (info: Credit_Memo): CreditSuccessAction => ({
//     type: AssociationActionTypes.CREDIT_MEMO_SUCCESS,
//     payload: info,
//   });
  
//   export const creditMemoFailure = (error: string): CreditFailureAction => ({
//     type: AssociationActionTypes.CREDIT_MEMO_FAILURE,
//     payload: error,
//   });

//   export const creditMemoRedirect = (path: string): CreditRedirectAction => ({
//     type: AssociationActionTypes.CREDIT_MEMO_REDIRECT,
//     payload: path,
//   });


  
//   // ***** Debit Memo *****  
//   interface DebitRequestAction {
//     type: AssociationActionTypes.DEBIT_MEMO_REQUEST;
//     payload: Debit_Memo;
//   }
//   interface DebitSuccessAction {
//     type: AssociationActionTypes.DEBIT_MEMO_SUCCESS;
//     payload: Debit_Memo;
//   }
//   interface DebitFailureAction {
//     type: AssociationActionTypes.DEBIT_MEMO_FAILURE;
//     payload: string;
//   }
//   interface DebitRedirectAction {
//     type: AssociationActionTypes.DEBIT_MEMO_REDIRECT;
//     payload: string;
//   }
  
//   export const debitMemoRequest = (info: Debit_Memo): DebitRequestAction => ({
//     type: AssociationActionTypes.DEBIT_MEMO_REQUEST,
//     payload: info,
//   });
  
//   export const debitMemoSuccess = (info: Debit_Memo): DebitSuccessAction => ({
//     type: AssociationActionTypes.DEBIT_MEMO_SUCCESS,
//     payload: info,
//   });
  
//   export const debitMemoFailure = (error: string): DebitFailureAction => ({
//     type: AssociationActionTypes.DEBIT_MEMO_FAILURE,
//     payload: error,
//   });

//   export const debitMemoRedirect = (path: string): DebitRedirectAction => ({
//     type: AssociationActionTypes.DEBIT_MEMO_REDIRECT,
//     payload: path,
//   });