import React, { useState, useEffect, useRef }  from 'react';
import {
    Modal,
    Spinner,
    Button,
    Image
} from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { RootState } from '../redux/reducers';
import { PiWarningDuotone } from "react-icons/pi";
import { PiCheckCircleDuotone } from "react-icons/pi";
import loading from '../images/linao.png';
import logo from '../images/logo.png';

export const LoadingPage = () => {
    // const [visibleSpinners, setVisibleSpinners] = useState([false, false, false, false, false]);

    // useEffect(() => {
    //     // Create delays for each spinner
    //     const timeouts = visibleSpinners.map((_, index) =>
    //         setTimeout(() => {
    //             setVisibleSpinners((prev) =>
    //                 prev.map((visible, i) => (i === index ? true : visible))
    //             );
    //         }, index * 300) // 500ms delay between each spinner
    //     );

    //     return () => timeouts.forEach((timeout) => clearTimeout(timeout)); // Cleanup on unmount
    // }, []);

    return (
        <div
          style={{
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            backgroundColor: 'rgba(0, 0, 0, 0.5)', // Semi-transparent background
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            zIndex: 9999, // Higher z-index for modal-like effect
          }}
        >
          <div style={{ position: 'relative', width: '100px', height: '100px' }}>
            {/* Image in the center */}
            <img
              src={loading}
              alt="center-image"
              style={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                zIndex: 1,
                width: '120px',
                height: '80px',
              }}
            />
      
            {/* Custom Spinner with two opposing segments */}
            <div
              style={{
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100px',
                height: '100px',
                borderRadius: '50%',
                border: '5px solid transparent',
                borderTop: '5px solid rgb(232,177,31)',
                borderBottom: '5px solid rgb(232,177,31)',
                animation: 'spin 1s linear infinite',
              }}
            />
          </div>
      
          {/* CSS for animation */}
          <style>
            {`
              @keyframes spin {
                0% { transform: rotate(0deg); }
                100% { transform: rotate(360deg); }
              }
            `}
          </style>
        </div>
      );
}


// export const CreateUserSuccess = () => {
//     const redirectPath = useSelector((state: RootState) => state.Admin_CreateUserReducer.redirectPath);
//     const closeButtonRef = useRef<HTMLButtonElement | null>(null); 
//     const navigate = useNavigate();
//     const [show, setShow] = useState(true);

//     const handleClose = () => {
//         setShow(false);
//         // if (redirectPath) {
//         //     navigate(redirectPath);
//             window.location.reload();
//         //   }
//     }
//     useEffect(() => {
//         if (show && closeButtonRef.current) {
//           closeButtonRef.current.focus();
//         }
//       }, [show]);
//     return (
//         <Modal show={show} onHide={handleClose} centered>
//             <Modal.Header  className="d-flex justify-content-center">
//                 <Modal.Title>
//                     <div className='text-success'>
//                         <PiCheckCircleDuotone size={100}/>
//                     </div>
//                 </Modal.Title>
//             </Modal.Header>
//             <Modal.Body className="d-flex justify-content-center">
//                 <div>
//                     <h4>Transaction successful!</h4>
//                     <p>User account recorded successfully.</p>
//                 </div>
//             </Modal.Body>
//             <Modal.Footer className="d-flex justify-content-center">
//                 {/* <Button variant="secondary" onClick={handleClose} >Yes</Button> */}
//                 <Button variant="secondary" onClick={handleClose} ref={closeButtonRef as React.RefObject<HTMLButtonElement>}>Close</Button>
//             </Modal.Footer>
//         </Modal>
//     );
// }

export const CreateAdminUserSuccess = () => {
    const redirectPath = useSelector((state: RootState) => state.Admin_SettingsReducer.redirectPath);
    const closeButtonRef = useRef<HTMLButtonElement | null>(null); 
    const navigate = useNavigate();
    const [show, setShow] = useState(true);

    const handleClose = () => {
        setShow(false);
        if (redirectPath) {
            navigate(redirectPath);
            window.location.reload();
          }
    }
    useEffect(() => {
        if (show && closeButtonRef.current) {
          closeButtonRef.current.focus();
        }
      }, [show]);
    return (
        <Modal show={show} onHide={handleClose} centered>
            <Modal.Header  className="d-flex justify-content-center">
                <Modal.Title>
                    <div className='text-success'>
                        <PiCheckCircleDuotone size={100}/>
                    </div>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className="d-flex justify-content-center">
                <div>
                    <h4>Transaction successful!</h4>
                    <p>User account recorded successfully.</p>
                </div>
            </Modal.Body>
            <Modal.Footer className="d-flex justify-content-center">
                {/* <Button variant="secondary" onClick={handleClose} >Yes</Button> */}
                <Button variant="secondary" onClick={handleClose} ref={closeButtonRef as React.RefObject<HTMLButtonElement>}>Close</Button>
            </Modal.Footer>
        </Modal>
    );
}

export const UpdateAdminUserSuccess = () => {
    const redirectPath = useSelector((state: RootState) => state.Admin_HeadersReducer.redirectPath);
    const closeButtonRef = useRef<HTMLButtonElement | null>(null); 
    const navigate = useNavigate();
    const [show, setShow] = useState(true);

    const handleClose = () => {
        setShow(false);
        if (redirectPath) {
            navigate(redirectPath);
            window.location.reload();
          }
    }
    useEffect(() => {
        if (show && closeButtonRef.current) {
          closeButtonRef.current.focus();
        }
      }, [show]);
    return (
        <Modal show={show} onHide={handleClose} centered>
            <Modal.Header  className="d-flex justify-content-center">
                <Modal.Title>
                    <div className='text-success'>
                        <PiCheckCircleDuotone size={100}/>
                    </div>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className="d-flex justify-content-center">
                <div>
                    <h4>Recorded successfully!</h4>
                    <p>User account updated successfully.</p>
                </div>
            </Modal.Body>
            <Modal.Footer className="d-flex justify-content-center">
                {/* <Button variant="secondary" onClick={handleClose} >Yes</Button> */}
                <Button variant="secondary" onClick={handleClose} ref={closeButtonRef as React.RefObject<HTMLButtonElement>}>Close</Button>
            </Modal.Footer>
        </Modal>
    );
}

export const CreateUserFailed = () => {
    const closeButtonRef = useRef<HTMLButtonElement | null>(null); 
    const [show, setShow] = useState(true);
    const handleClose = () => setShow(false);
    useEffect(() => {
        if (show && closeButtonRef.current) {
          closeButtonRef.current.focus();
        }
      }, [show]);
    return (
        <Modal show={show} onHide={handleClose} centered>
            <Modal.Header  className="d-flex justify-content-center">
                <Modal.Title>
                    <div className='text-danger'>
                        <PiWarningDuotone size={100}/>
                    </div>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className="d-flex justify-content-center">
                <div>
                    <h4>Transaction Failed</h4>
                    <p>Server might be down or unavailable, or email might be invalid or non-existent email address. Please contact system administrator and try again!</p>
                </div>
            </Modal.Body>
            <Modal.Footer className="d-flex justify-content-center">
                <Button variant="secondary" onClick={handleClose} ref={closeButtonRef as React.RefObject<HTMLButtonElement>}>Close</Button>
            </Modal.Footer>
        </Modal>
    );
}

export const SearchAccountFailed = () => {
    const [show, setShow] = useState(true);
    const closeButtonRef = useRef<HTMLButtonElement | null>(null); 
    const handleClose = () => {
        setShow(false);
        sessionStorage.clear();
        window.location.reload();
    }
    useEffect(() => {
        if (show && closeButtonRef.current) {
          closeButtonRef.current.focus();
        }
      }, [show]);

    return (
        <Modal show={show} onHide={handleClose} centered>
            <Modal.Header  className="d-flex justify-content-center">
                <Modal.Title>
                    <div className='text-danger'>
                        <PiWarningDuotone size={100}/>
                    </div>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className="d-flex justify-content-center">
                <div>
                    <h4>Search Failed!</h4>
                    <p>User account not found. Please contact systems administrator and try again!</p>
                </div>
            </Modal.Body>
            <Modal.Footer className="d-flex justify-content-center">
                <Button variant="secondary" onClick={handleClose} ref={closeButtonRef as React.RefObject<HTMLButtonElement>}>Close</Button>
            </Modal.Footer>
        </Modal>
    );
}


export const ForgotResetSuccess = () => {
    const redirectPath = useSelector((state: RootState) => state.Admin_LoginReducer.redirectPath);
    const closeButtonRef = useRef<HTMLButtonElement | null>(null); 
    const navigate = useNavigate();
    const [show, setShow] = useState(true);

    const handleClose = () => {
        setShow(false);
        if (redirectPath) {
            navigate(redirectPath);
            window.location.reload();
          }
    }
    useEffect(() => {
        if (show && closeButtonRef.current) {
            closeButtonRef.current.focus();
        }
    }, [show]);
    return (
        <Modal show={show} onHide={handleClose} centered>
            <Modal.Header  className="d-flex justify-content-center">
                <Modal.Title>
                    <div className='text-success'>
                        <PiCheckCircleDuotone size={100}/>
                    </div>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className="d-flex justify-content-center">
                <div>
                    <h4>Password Reset!</h4>
                    <p>Password reset successful. <br/>Please check your email for your temporary password.</p>
                </div>
            </Modal.Body>
            <Modal.Footer className="d-flex justify-content-center">
                <Button variant="secondary" onClick={handleClose} ref={closeButtonRef as React.RefObject<HTMLButtonElement>}>Close</Button>
            </Modal.Footer>
        </Modal>
    );
}


export const AuthAccessFailed: React.FC = () => {
    const closeButtonRef = useRef<HTMLButtonElement | null>(null); 
    const navigate = useNavigate();
    const [show, setShow] = useState(true);

    const handleClose = () => {
        setShow(false);
            navigate('/admin_dashboard');
            window.location.reload();
    }
    useEffect(() => {
        if (show && closeButtonRef.current) {
            closeButtonRef.current.focus();
        }
    }, [show]);
    return (
        <Modal show={show} onHide={handleClose} centered>
            <Modal.Header  className="d-flex justify-content-center">
                <Modal.Title>
                    <div className='text-danger'>
                        <PiWarningDuotone size={100}/>
                    </div>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className="d-flex justify-content-center">
                <div>
                    <h4>Access Failed</h4>
                    <p>You are unauthorized to access this link, Please contact system administrator and try again!</p>
                </div>
            </Modal.Body>
            <Modal.Footer className="d-flex justify-content-center">
                <Button variant="secondary" onClick={handleClose} ref={closeButtonRef as React.RefObject<HTMLButtonElement>}>Close</Button>
            </Modal.Footer>
        </Modal>
    );
}



export const CreateAssociationSuccess = () => {
    const redirectPath = useSelector((state: RootState) => state.Admin_AssociationReducer.redirectPath);
    const closeButtonRef = useRef<HTMLButtonElement | null>(null); 
    const navigate = useNavigate();
    const [show, setShow] = useState(true);

    const handleClose = () => {
        setShow(false);
        if (redirectPath) {
            // navigate(redirectPath);
            sessionStorage.clear();
            window.location.reload();
          }
    }
    useEffect(() => {
        if (show && closeButtonRef.current) {
          closeButtonRef.current.focus();
        }
      }, [show]);

    return (
        <Modal show={show} onHide={handleClose} centered>
            <Modal.Header  className="d-flex justify-content-center">
                <Modal.Title>
                    <div className='text-success'>
                        <PiCheckCircleDuotone size={100}/>
                    </div>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className="d-flex justify-content-center">
                <div>
                    <h4>Transaction successful!</h4>
                    <p>Association bill generated successfully.</p>
                </div>
            </Modal.Body>
            <Modal.Footer className="d-flex justify-content-center">
                <Button variant="secondary" onClick={handleClose} ref={closeButtonRef as React.RefObject<HTMLButtonElement>}>Close</Button>
            </Modal.Footer>
        </Modal>
    );
}

export const CreateAssociationFailed = () => {
    const [show, setShow] = useState(true);
    const closeButtonRef = useRef<HTMLButtonElement | null>(null); 
    const handleClose = () => {
        setShow(false);
    }
    useEffect(() => {
        if (show && closeButtonRef.current) {
          closeButtonRef.current.focus();
        }
      }, [show]);

    return (
        <Modal show={show} onHide={handleClose} centered>
            <Modal.Header  className="d-flex justify-content-center">
                <Modal.Title>
                    <div className='text-danger'>
                        <PiWarningDuotone size={100}/>
                    </div>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className="d-flex justify-content-center">
                <div>
                    <h4>Transaction Failed!</h4>
                    <p>Some data are invalid or there are problems in the account. Please contact systems administrator and try again!</p>
                </div>
            </Modal.Body>
            <Modal.Footer className="d-flex justify-content-center">
                <Button variant="secondary" onClick={handleClose} ref={closeButtonRef as React.RefObject<HTMLButtonElement>}>Close</Button>
            </Modal.Footer>
        </Modal>
    );
}



export const CreateCreditMemoSuccess = () => {
    const redirectPath = useSelector((state: RootState) => state.Admin_AssociationReducer.redirectPath);
    const closeButtonRef = useRef<HTMLButtonElement | null>(null); 
    const navigate = useNavigate();
    const [show, setShow] = useState(true);

    const handleClose = () => {
        setShow(false);
        if (redirectPath) {
            navigate(redirectPath);
            window.location.reload();
          }
    }
    useEffect(() => {
        if (show && closeButtonRef.current) {
          closeButtonRef.current.focus();
        }
      }, [show]);

    return (
        <Modal show={show} onHide={handleClose} centered>
            <Modal.Header  className="d-flex justify-content-center">
                <Modal.Title>
                    <div className='text-success'>
                        <PiCheckCircleDuotone size={100}/>
                    </div>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className="d-flex justify-content-center">
                <div>
                    <h4>Transaction successful!</h4>
                    <p>Credit memo recorded successfully.</p>
                </div>
            </Modal.Body>
            <Modal.Footer className="d-flex justify-content-center">
                <Button variant="secondary" onClick={handleClose} ref={closeButtonRef as React.RefObject<HTMLButtonElement>}>Close</Button>
            </Modal.Footer>
        </Modal>
    );
}

export const CreateCreditMemoFailed = () => {
    const [show, setShow] = useState(true);
    const closeButtonRef = useRef<HTMLButtonElement | null>(null); 
    const handleClose = () => {
        setShow(false);
    }
    useEffect(() => {
        if (show && closeButtonRef.current) {
          closeButtonRef.current.focus();
        }
      }, [show]);

    return (
        <Modal show={show} onHide={handleClose} centered>
            <Modal.Header  className="d-flex justify-content-center">
                <Modal.Title>
                    <div className='text-danger'>
                        <PiWarningDuotone size={100}/>
                    </div>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className="d-flex justify-content-center">
                <div>
                    <h4>Transaction Failed!</h4>
                    <p>Some data are invalid or there are problems in the account. Please contact systems administrator and try again!</p>
                </div>
            </Modal.Body>
            <Modal.Footer className="d-flex justify-content-center">
                <Button variant="secondary" onClick={handleClose} ref={closeButtonRef as React.RefObject<HTMLButtonElement>}>Close</Button>
            </Modal.Footer>
        </Modal>
    );
}


