import { PaymentState } from './adminPaymentTypes';
import {  PaymentAction, PaymentActionTypes } from './adminPaymentActions';

const initialState: PaymentState = {
  info: null,
  loading: false,
  error: null,
  redirectPath: null,
  searcherror: null,
};

const associationReducer = (state = initialState, action: PaymentAction): PaymentState => {
  switch (action.type) {
    // Create
    case PaymentActionTypes.ADMIN_PAYMENT_CREATE_REQUEST:
      return { ...state, loading: true, error: null };
    case PaymentActionTypes.ADMIN_PAYMENT_CREATE_SUCCESS:
      return { ...state, info: action.payload, loading: false, error: null };
    case PaymentActionTypes.ADMIN_PAYMENT_CREATE_FAILURE:
      return { ...state, loading: false, error: action.payload };
    case PaymentActionTypes.ADMIN_PAYMENT_CREATE_REDIRECT:
      return {...state, redirectPath: action.payload };
    // Search
    case PaymentActionTypes.ADMIN_PAYMENT_SEARCH_REQUEST:
      return { ...state, loading: true, error: null };
    case PaymentActionTypes.ADMIN_PAYMENT_SEARCH_SUCCESS:
      return { ...state, info: action.payload, loading: false, error: null };
    case PaymentActionTypes.ADMIN_PAYMENT_SEARCH_FAILURE:
      return { ...state, loading: false, searcherror: action.payload };
    case PaymentActionTypes.ADMIN_PAYMENT_SEARCH_REDIRECT:
      return {...state, redirectPath: action.payload };
    // // Credit Memo
    // case AssociationActionTypes.CREDIT_MEMO_REQUEST:
    //   return { ...state, loading: true, error: null };
    // case AssociationActionTypes.CREDIT_MEMO_SUCCESS:
    //   return { ...state, info: action.payload, loading: false, error: null };
    // case AssociationActionTypes.CREDIT_MEMO_FAILURE:
    //   return { ...state, loading: false, error: action.payload };
    // case AssociationActionTypes.CREDIT_MEMO_REDIRECT:
    //   return {...state, redirectPath: action.payload };
    // // Debit Memo
    // case AssociationActionTypes.DEBIT_MEMO_REQUEST:
    //   return { ...state, loading: true, error: null };
    // case AssociationActionTypes.DEBIT_MEMO_SUCCESS:
    //   return { ...state, info: action.payload, loading: false, error: null };
    // case AssociationActionTypes.DEBIT_MEMO_FAILURE:
    //   return { ...state, loading: false, error: action.payload };
    // case AssociationActionTypes.DEBIT_MEMO_REDIRECT:
    //   return {...state, redirectPath: action.payload };
    default:
      return state;
  }
};

export default associationReducer;