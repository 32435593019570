import React, { useState, useEffect, useRef }  from 'react';
import {
    Modal,
    Spinner,
    Button
} from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { RootState } from '../../../redux/reducers';
import { PiWarningDuotone } from "react-icons/pi";
import { PiCheckCircleDuotone } from "react-icons/pi";

export const UpdateUserSuccess = () => {
    const redirectPath = useSelector((state: RootState) => state.Admin_HeadersReducer.redirectPath);
    const closeButtonRef = useRef<HTMLButtonElement | null>(null); 
    const navigate = useNavigate();
    const [show, setShow] = useState(true);

    const handleClose = () => {
        setShow(false);
        if (redirectPath) {
            navigate(redirectPath);
            window.location.reload();
          }
    }
    useEffect(() => {
        if (show && closeButtonRef.current) {
          closeButtonRef.current.focus();
        }
      }, [show]);
    return (
        <Modal show={show} onHide={handleClose} centered>
            <Modal.Header  className="d-flex justify-content-center">
                <Modal.Title>
                    <div className='text-success'>
                        <PiCheckCircleDuotone size={100}/>
                    </div>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className="d-flex justify-content-center">
                <div>
                    <h4>Recorded successfully!</h4>
                    <p>User account updated successfully.</p>
                </div>
            </Modal.Body>
            <Modal.Footer className="d-flex justify-content-center">
                {/* <Button variant="secondary" onClick={handleClose} >Yes</Button> */}
                <Button variant="secondary" onClick={handleClose} ref={closeButtonRef as React.RefObject<HTMLButtonElement>}>Close</Button>
            </Modal.Footer>
        </Modal>
    );
}


export const UpdateUserFailed = () => {
    const [show, setShow] = useState(true);
    const closeButtonRef = useRef<HTMLButtonElement | null>(null); 
    const handleClose = () => {
        setShow(false);
    }
    useEffect(() => {
        if (show && closeButtonRef.current) {
          closeButtonRef.current.focus();
        }
      }, [show]);

    return (
        <Modal show={show} onHide={handleClose} centered>
            <Modal.Header  className="d-flex justify-content-center">
                <Modal.Title>
                    <div className='text-danger'>
                        <PiWarningDuotone size={100}/>
                    </div>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className="d-flex justify-content-center">
                <div>
                    <h4>Transaction Failed!</h4>
                    <p>Some data are invalid or there are problems in the account. Please contact systems administrator and try again!</p>
                </div>
            </Modal.Body>
            <Modal.Footer className="d-flex justify-content-center">
                <Button variant="secondary" onClick={handleClose} ref={closeButtonRef as React.RefObject<HTMLButtonElement>}>Close</Button>
            </Modal.Footer>
        </Modal>
    );
}


// ***** Password Update Status *****

export const UpdatePasswordSuccess = () => {
    const redirectPath = useSelector((state: RootState) => state.Admin_HeadersReducer.password_redirectPath);
    const closeButtonRef = useRef<HTMLButtonElement | null>(null); 
    const navigate = useNavigate();
    const [show, setShow] = useState(true);

    const handleClose = () => {
        setShow(false);
        if (redirectPath) {
            navigate(redirectPath);
            window.location.reload();
          }
    }
    useEffect(() => {
        if (show && closeButtonRef.current) {
          closeButtonRef.current.focus();
        }
      }, [show]);
    return (
        <Modal show={show} onHide={handleClose} centered>
            <Modal.Header  className="d-flex justify-content-center">
                <Modal.Title>
                    <div className='text-success'>
                        <PiCheckCircleDuotone size={100}/>
                    </div>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className="d-flex justify-content-center">
                <div>
                    <h4>Recorded successfully!</h4>
                    <p>Password updated successfully.</p>
                </div>
            </Modal.Body>
            <Modal.Footer className="d-flex justify-content-center">
                {/* <Button variant="secondary" onClick={handleClose} >Yes</Button> */}
                <Button variant="secondary" onClick={handleClose} ref={closeButtonRef as React.RefObject<HTMLButtonElement>}>Close</Button>
            </Modal.Footer>
        </Modal>
    );
}


export const UpdatePasswordFailed = () => {
    const [show, setShow] = useState(true);
    const closeButtonRef = useRef<HTMLButtonElement | null>(null); 
    const handleClose = () => {
        setShow(false);
    }
    useEffect(() => {
        if (show && closeButtonRef.current) {
          closeButtonRef.current.focus();
        }
      }, [show]);

    return (
        <Modal show={show} onHide={handleClose} centered>
            <Modal.Header  className="d-flex justify-content-center">
                <Modal.Title>
                    <div className='text-danger'>
                        <PiWarningDuotone size={100}/>
                    </div>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className="d-flex justify-content-center">
                <div>
                    <h4>Transaction Failed!</h4>
                    <p>Some data are invalid or there are problems in the account. Please contact systems administrator and try again!</p>
                </div>
            </Modal.Body>
            <Modal.Footer className="d-flex justify-content-center">
                <Button variant="secondary" onClick={handleClose} ref={closeButtonRef as React.RefObject<HTMLButtonElement>}>Close</Button>
            </Modal.Footer>
        </Modal>
    );
}



