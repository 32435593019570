import React, { useState, useEffect, useCallback } from 'react';
import axios, { AxiosResponse } from 'axios';
import apiUrl from '../../../components/apiurl';
import { getLocalStorageVariable } from '../../../components/localStorage';
import { 
    Row,
    Col,
    Alert,
    Button,
    Pagination,
} from 'react-bootstrap';
import SubMenu from '../submenu';
// import { AiOutlineDashboard, AiOutlineGroup, AiOutlineCalculator, AiOutlineDollar } from "react-icons/ai";
import { IoWaterOutline } from "react-icons/io5";
import { UserData } from './redux/waterTypes';
import Barcode from 'react-barcode';
import CryptoJS from 'crypto-js';
// import { useNavigate } from 'react-router-dom';
// import { getLocalStorageVariable } from '../../../components/localStorage';


const Water: React.FC = () =>{
    const userId = getLocalStorageVariable<string>('userId');
    // const userAuth = getLocalStorageVariable<string>('userAuth');
    // const userAvatar = getLocalStorageVariable<string>('avatar');
    const token = getLocalStorageVariable<string>('token');
    const [data, setData] = useState<UserData[] | null>(null); 
    const [page, setPage] = useState(1);
    const [pageSize] = useState(12); // Adjust according to your needs
    const [accountNo, setAccount] = useState<any | null>(null);
    const [fName, setFName] = useState<String | null>(null);
    const [lName, setLName] = useState<String | null>(null);

     // ****** decrypt Data *****
     const encryptionKey = process.env.REACT_APP_DE_EN ?? ''; 
     const ivHex = '00000000000000000000000000000000'; // Fixed IV as used in the backend
 
     const decryptData = (encryptedData: string, encryptionKey: string): string => {
         const key = CryptoJS.enc.Utf8.parse(encryptionKey); // Use Utf8 parsing for consistency
         const iv = CryptoJS.enc.Hex.parse(ivHex);
     
         // Convert the hex string to bytes, then decrypt
         const encryptedHexStr = CryptoJS.enc.Hex.parse(encryptedData); // Interpret hex as bytes
         const encryptedBase64 = CryptoJS.enc.Base64.stringify(encryptedHexStr); // Convert bytes to Base64
     
         // Decrypt the Base64 string
         const bytes = CryptoJS.AES.decrypt(encryptedBase64, key, {
         iv: iv,
         mode: CryptoJS.mode.CBC,
         padding: CryptoJS.pad.Pkcs7,
         });
     
         return bytes.toString(CryptoJS.enc.Utf8);
     };

    // ****** Get the Associaiton Bill ****
    const fetchData = useCallback(async () => {
        const headers = {
            Authorization: `${token}`,
          };

        try {
            const res: AxiosResponse<UserData[]> = await axios.get(`${apiUrl.url}get_water`, { headers, params: { id: userId, page: page, pageSize: pageSize } });
            setData(res.data.length > 0 ? res.data : null);
            setAccount(res.data.length > 0 ? res.data[0].account : null);
            if (res.data && res.data.length > 0 && res.data[0].fname) {
                setFName(decryptData(res.data[0].fname, encryptionKey));
                setLName(decryptData(res.data[0].lname, encryptionKey));
            } else {
                setFName(null); // Default value if fname is missing or decryption fails
                setLName(null);
            }
        } catch (error) {
            console.error('Error: ', error);
        }
    }, [userId, token, page, pageSize]);

    useEffect(() => {
        fetchData();
    }, [fetchData]);


    const totalBalance = data?.reduce((total, item) => total + item.balance, 0);

    // console.log(data);

    // const navigate = useNavigate();

    // useEffect(() => {
    //     const userId = getLocalStorageVariable<string>('userId');
    //     const userAuth = getLocalStorageVariable<string>('userAuth');

    //     if (!userId && userAuth!='client') {
    //         navigate('/admin_login'); 
    //     }
    // }, [navigate]);
    const zeroPad = (num:any, places:any) => String(num).padStart(places, '0')

    return (
        <div className="container-fluid "> 
            <Row className="justify-content-md-center">
                <Col sm={3}>
                    <SubMenu/>
                </Col>
                <Col xs lg="9" className="p-3 bg-white rounded">
                    <Row className="justify-content-md-center">
                        <Col >
                        <div style = {{ width:"400px"}} />
                        <h4 className="text-info" ><IoWaterOutline size="30"/> Water Bill</h4>
                        <hr/>
                            <Row >
                                <Col sm={12}>
                                    <Alert style={{ backgroundColor:'#fff'}}>
                                        <Row >
                                            <Col sm={2} className='text-muted'>
                                                Account # :
                                            </Col>
                                            <Col sm>
                                                <strong>{zeroPad(accountNo, 8)}</strong>
                                            </Col>
                                        </Row>
                                        <Row >
                                            <Col sm={2} className='text-muted'>
                                                Name :
                                            </Col>
                                            <Col sm>
                                                <strong>{fName} {lName}</strong>
                                            </Col>
                                        </Row>
                                        <Row >
                                            <Col sm={2} className='text-muted'>
                                                Total Balance :
                                            </Col>
                                            <Col sm>
                                                {
                                                    (totalBalance !== undefined && totalBalance <= 0) ?
                                                        <strong className="text-success size-5">
                                                            ₱ {(totalBalance)?.toLocaleString('en-US',{minimumFractionDigits: 2})}
                                                        </strong>:
                                                        <strong className="text-danger size-5">
                                                            ₱ {(totalBalance)?.toLocaleString('en-US',{minimumFractionDigits: 2})}
                                                        </strong>
                                                }
                                            </Col>
                                        </Row>
                                    </Alert>
                                </Col>
                            </Row>
                            
                            <Row >
                                <Col sm={12}>
                                    <Alert variant='warning'>
                                        <Row >
                                            <Col sm={12} className='text-secondary'>
                                                <strong>Note :</strong> If you will pay online you will be charge 10 for system charge and 2.5% for payment gateway.
                                            </Col>
                                        </Row>
                                    </Alert>
                                </Col>
                            </Row>

                            <Row >
                                <Col sm>
                                    {data ? (
                                        <div>
                                            {data.map((r, i) => (
                                                <Col key={i} className="my-3 mt-0 rounded shadow-lg" style={{border: "1px solid rgb(128, 128, 128)", backgroundColor: '#d4fcfc'}}>
                                                    <Row >
                                                        <Col lg={8}>
                                                            <Alert className='mb-0 bg-white' style={{border: "none"}}>
                                                                <Row>
                                                                    <Col sm={7}>
                                                                    <Row>
                                                                            <Col sm={4}>Bill # : </Col>
                                                                            <Col><strong>{r.water_no}</strong></Col>
                                                                        </Row>
                                                                        <Row>
                                                                            <Col sm={4}>Account # : </Col>
                                                                            <Col><strong>{zeroPad(r.account, 8)}</strong></Col>
                                                                        </Row>
                                                                        <Row>
                                                                            <Col sm={4}>Name : </Col>
                                                                            <Col><strong>{decryptData(r.fname, encryptionKey)} {r.mi}. {decryptData(r.lname, encryptionKey)}</strong></Col>
                                                                        </Row>
                                                                        <Row>
                                                                            <Col sm={4}>Address : </Col>
                                                                            <Col><strong>Phase {r.phase} Block {r.block} Lot {r.lot}</strong></Col>
                                                                        </Row>
                                                                    </Col>
                                                                    <Col sm className='text-center' >
                                                                        <Barcode value={r.water_no.toString()} width={2} height={30} />
                                                                    </Col>
                                                                </Row>
                                                                <Row className='mt-2'>
                                                                    <Col sm>
                                                                        <div className="media text-muted">
                                                                            <div className="media-body  small lh-125">
                                                                                <span className="d-block">Bill Date : {new Date(r.cdate).toLocaleDateString("en-US",{ year: 'numeric', month: 'short', day: 'numeric' })}</span>
                                                                                <div className="d-flex justify-content-between align-items-center w-100">
                                                                                    <strong className="text-danger">Due Date : {new Date(new Date(r.cdate).getTime() + 15 * 24 * 60 * 60 * 1000).toLocaleDateString("en-PH",{ year: 'numeric', month: 'short', day: 'numeric' })}</strong>
                                                                                </div>
                                                                                
                                                                                {/* <span className="d-block">Date To : {new Date(r.bill_dateto).toLocaleDateString("en-US",{ year: 'numeric', month: 'long', day: 'numeric' })}</span> */}
                                                                            </div>
                                                                        </div>
                                                                    </Col>
                                                                    <Col>
                                                                    <div className="media-body  small lh-125">Date Coverage</div>
                                                                        <div className="media text-muted">
                                                                            <div className="media-body  small lh-125">
                                                                                <span className="">From : {new Date(r.date_from).toLocaleDateString("en-US",{ year: 'numeric', month: 'short', day: 'numeric' })}</span>
                                                                                <span className=""> - To : {new Date(r.date_to).toLocaleDateString("en-US",{ year: 'numeric', month: 'short', day: 'numeric' })}</span>
                                                                                
                                                                                {/* <span className="d-block">Date To : {new Date(r.bill_dateto).toLocaleDateString("en-US",{ year: 'numeric', month: 'long', day: 'numeric' })}</span> */}
                                                                            </div>
                                                                        </div>
                                                                    </Col>
                                                                </Row>
                                                                
                                                            </Alert>
                                                        </Col>
                                                        <Col sm className="p-3 pe-4 ps-4 border-left border-gray justify-content-between text-end">
                                                            <h3>₱ {(r.total_amount).toLocaleString('en-US',{minimumFractionDigits: 2})}</h3>
                                                                <div className="media text-muted pt-1">
                                                                    <div className="media-body mb-2 small lh-125">
                                                                        <div className=" w-100">
                                                                        {r.balance<=0?
                                                                            <strong className="text-success size-5">
                                                                                Balance : ₱ {(r.balance).toLocaleString('en-US',{minimumFractionDigits: 2})}
                                                                            </strong>:
                                                                            <strong className="text-danger size-5">
                                                                                Balance : ₱ {(r.balance).toLocaleString('en-US',{minimumFractionDigits: 2})}
                                                                            </strong>
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                
                                                            <div className="d-grid gap-2 mt-4">
                                                                {r.balance<=0?(<Button className="btn btn-success rounded-pill" disabled={true}>Paid</Button>):
                                                                (r.balance>=0?<Button  href={`./bills_us_payupload?id=${r.user_id}`} variant="btn btn-danger rounded-pill">Report Payment</Button>:
                                                                <Button  href={`./bills_us_payupload?id=${r.user_id}`} variant="btn btn-warning rounded-pill">For Verification</Button>)}
                                                            </div>
                                                            {/* <div className="d-grid gap-2 mt-1">
                                                                <Button variant="btn btn-outline-primary rounded-pill"> View Details ...</Button>
                                                            </div> */}
                                                        </Col>
                                                    </Row>
                                                </Col>
                                            ))}
                                        </div>
                                    ) : (
                                        <p>No more Water Bill...</p>
                                    )}
                                </Col>
                            </Row>

                            <Pagination className='mt-3'>
                                <Pagination.Prev onClick={()=> setPage((prevPage) => prevPage - 1)} disabled={page === 1} />
                                <Pagination.Item>Page {page}</Pagination.Item>
                                <Pagination.Next onClick={() => setPage((prevPage) => prevPage + 1)} />
                            </Pagination>
                        </Col>
                        
                    </Row>
                </Col>
            </Row>
        </div>
    );
};

export default Water;