// import React, { useState, useEffect, useRef } from 'react';
// import { Row, Col, Alert, Breadcrumb } from 'react-bootstrap';
// import SubMenu from '../submenu';
// import axios from 'axios';
// import { AiOutlineComment } from "react-icons/ai";
// import apiUrl from '../../../components/apiurl';
// import { getLocalStorageVariable } from '../../../components/localStorage';
// import { useNavigate } from 'react-router-dom';
// import { getSessionVariable } from '../../../components/sessionStorage';

// interface Message {
//   id: number;
//   sender: string | null;
//   content: string;
//   timestamp: string;
//   recipient: string;
// }

// const Messages: React.FC = () => {
//   const [messages, setMessages] = useState<Message[]>([]);
//   const sender = getLocalStorageVariable<string>('userId'); // Current user ID
//   const token = getLocalStorageVariable<string>('token'); // Auth token
//   const receiver = getSessionVariable<string>('user_id_receiver'); // Receiver user ID from session
//   const [content, setContent] = useState(''); // Message content
//   const userAvatar = getLocalStorageVariable<string>('avatar'); // Sender avatar
//   const [receiverAvatar, setReceiverAvatar] = useState<string>(''); // Receiver avatar
//   const messagesContainerRef = useRef<HTMLDivElement>(null);
//   const navigate = useNavigate();

//   const headers = {
//     Authorization: `${token}`,
//   };

//   // ***** Fetch Messages Between Sender and Receiver *****
//   const fetchMessages = () => {
//     if (sender && receiver) {
//       axios
//         .get(`${apiUrl.url}messages/message_display/${receiver}/${sender}`, { headers })
//         .then((response) => {
//           setMessages(response.data);
//           scrollToBottom();
//         })
//         .catch((error) => console.error(error));
//     }
//   };

//   // ***** Scroll to Bottom of the Messages *****
//   const scrollToBottom = () => {
//     if (messagesContainerRef.current) {
//       messagesContainerRef.current.scrollTop = messagesContainerRef.current.scrollHeight;
//     }
//   };

//   useEffect(() => {
//     fetchMessages();
//   }, [sender, receiver]);

//   // ***** Send Message *****
//   // const sendMessage = () => {
//   //   if (!content.trim()) return; // Prevent sending empty messages

//   //   const newMessage = {
//   //     sender,
//   //     content,
//   //     recipient: receiver,
//   //   };

//   //   axios
//   //     .post(`${apiUrl.url}messages`, newMessage, { headers })
//   //     .then((response) => {
//   //       setMessages([...messages, { ...response.data, timestamp: new Date().toISOString() }]);
//   //       setContent('');
//   //       scrollToBottom();
//   //     })
//   //     .catch((error) => console.error(error));
//   // };
//   const sendMessage = () => {
//     if (!content.trim()) return; // Prevent sending empty messages
  
//     const newMessage: Message = {
//       id: 0, // Temporary ID until the server response provides the correct ID
//       sender,
//       content,
//       recipient: receiver || '', // Ensure this is a string
//       timestamp: new Date().toISOString(),
//     };
  
//     axios
//       .post(`${apiUrl.url}messages`, newMessage, { headers })
//       .then((response) => {
//         setMessages((prevMessages) => [
//           ...prevMessages,
//           {
//             ...response.data,
//             timestamp: new Date().toISOString(), // Use server response for ID
//           } as Message, // Cast to `Message` to satisfy TypeScript
//         ]);
//         setContent(''); // Clear the input field
//         scrollToBottom();
//       })
//       .catch((error) => console.error(error));
//   };
  

  // // ***** Fetch Receiver's Avatar *****
  // const fetchReceiverAvatar = () => {
  //   if (receiver) {
  //     axios
  //       .get(`${apiUrl.url}messages/message_list_user_details/${receiver}`, { headers })
  //       .then((response) => {
  //         setReceiverAvatar(response.data[0].avatar || '/static/media/default.d1c5ffe2b3cea9d80c7b.jpg');
  //       })
  //       .catch((error) => console.error(error));
  //   }
  // };

  // useEffect(() => {
  //   fetchReceiverAvatar();
  // }, [receiver]);
  

//   // ***** Format Timestamp *****
//   const formattedTimestamp = (timestamp: string) => {
//     const date = new Date(timestamp);
//     const timeOptions: Intl.DateTimeFormatOptions = { hour: 'numeric', minute: '2-digit', hour12: true };
//     const dateOptions: Intl.DateTimeFormatOptions = { month: 'short', day: 'numeric' };

//     const formattedTime = new Intl.DateTimeFormat('en-US', timeOptions).format(date);
//     const formattedDate = new Intl.DateTimeFormat('en-US', dateOptions).format(date);
//     return `${formattedTime} | ${formattedDate}`;
//   };

//   // ***** Render Component *****
//   return (
//     <div className="container-fluid">
//       <Row className="justify-content-md-center">
//         <Col>
//           <SubMenu />
//         </Col>
//         <Col lg="10" className="p-3 bg-white rounded shadow-sm">
//           <h4 className="text-primary">
//             <AiOutlineComment size="30" /> Messages
//           </h4>
//           <Breadcrumb>
//             <Breadcrumb.Item onClick={() => navigate('/admin_user_menu')}>User Menu</Breadcrumb.Item>
//             <Breadcrumb.Item onClick={() => navigate('/admin_user_list')}>User List</Breadcrumb.Item>
//             <Breadcrumb.Item active>User Details</Breadcrumb.Item>
//           </Breadcrumb>
//           <hr />
//           <section>
//             <Alert className="shadow-sm" style={{ border: "1px solid #808080" }}>
//               <div
//                 className="p-3 shadow-sm"
//                 style={{
//                   position: 'relative',
//                   height: '500px',
//                   overflowY: 'auto',
//                   backgroundColor: 'white',
//                 }}
//                 ref={messagesContainerRef}
//               >
//                 {messages.map((message, index) => (
//                   <div key={index}>
//                     {message.sender === sender ? (
//                       // Message sent by the current user
//                       <div className="d-flex flex-row justify-content-end">
//                         <div>
//                           <p className="medium p-2 me-3 mb-1 text-white rounded-3 bg-primary">
//                             {message.content || 'Error: Message content missing'}
//                           </p>
//                           <p className="small me-3 mb-3 text-muted">
//                             {formattedTimestamp(message.timestamp)}
//                           </p>
//                         </div>
//                         <img
//                           src={userAvatar || '/static/media/default.d1c5ffe2b3cea9d80c7b.jpg'}
//                           alt="Sender Avatar"
//                           style={{ width: '45px', height: '100%' }}
//                         />
//                       </div>
//                     ) : (
//                       // Message received from the other user
//                       <div className="d-flex flex-row justify-content-start">
//                         <img
//                           src={receiverAvatar || '/static/media/default.d1c5ffe2b3cea9d80c7b.jpg'}
//                           alt="Receiver Avatar"
//                           style={{ width: '45px', height: '100%' }}
//                         />
//                         <div>
//                           <p className="medium p-2 ms-3 mb-1 rounded-3" style={{ backgroundColor: '#e1e1e1' }}>
//                             {message.content || 'Error: Message content missing'}
//                           </p>
//                           <p className="small me-3 mb-3 text-muted">
//                             {formattedTimestamp(message.timestamp)}
//                           </p>
//                         </div>
//                       </div>
//                     )}
//                   </div>
                
//                 ))}
//               </div>

//               <div className="text-muted d-flex justify-content-start align-items-center pt-3 mt-2">
//                 <input
//                   type="text"
//                   className="form-control form-control-md rounded-pill"
//                   placeholder="Type message"
//                   value={content}
//                   onChange={(e) => setContent(e.target.value)}
//                   onKeyDown={(e) => {
//                     if (e.key === 'Enter') {
//                       e.preventDefault();
//                       sendMessage();
//                     }
//                   }}
//                 />
//               </div>
//             </Alert>
//           </section>
//         </Col>
//       </Row>
//     </div>
//   );
// };

// export default Messages;

import React, { useState, useEffect, useRef } from 'react';
import { Row, Col, Alert, Breadcrumb } from 'react-bootstrap';
import SubMenu from '../submenu';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { AiOutlineComment } from "react-icons/ai";
import apiUrl from '../../../components/apiurl';
import { getLocalStorageVariable } from '../../../components/localStorage';
import { getSessionVariable } from '../../../components/sessionStorage';

interface Message {
  id: number;
  sender: string | null;
  content: string;
  timestamp: string;
  recipient?: string;
  isSeen?: boolean;
}

const Messages: React.FC = () => {
  const [messages, setMessages] = useState<Message[]>([]);
  const sender = getLocalStorageVariable<string>('userId');
  const token = getLocalStorageVariable<string>('token');
  // const receiver = getLocalStorageVariable<string>('receiverId'); // Receiver fetched from session/local storage
  const receiver = getSessionVariable<string>('user_id_receiver'); // Receiver user ID from session
  const fname = getSessionVariable<any>('fname');
  const mi = getSessionVariable<any>('mi');
  const lname = getSessionVariable<any>('lname');
  const [content, setContent] = useState('');
  const [recAvatar, setRecAvatar] = useState<string | null>(null);
  const userAvatar = getLocalStorageVariable<string>('avatar');
  const messagesContainerRef = useRef<HTMLDivElement>(null);
  const navigate = useNavigate();

  const headers = {
    Authorization: `${token}`,
  };

  // Scroll the message container to the bottom on new message
  useEffect(() => {
    if (messagesContainerRef.current) {
      messagesContainerRef.current.scrollTop = messagesContainerRef.current.scrollHeight;
    }
  }, [messages]);

  // Fetch messages between sender and receiver
  const fetchMessages = () => {
    if (sender && receiver) {
      axios.get(`${apiUrl.url}messages/message_display/${sender}/${receiver}`, { headers })
        .then((response) => {
          setMessages(response.data);
          setRecAvatar(response.data[0]?.avatar || null); // Update receiver avatar if available
          markAsRead(sender, receiver);
        })
        .catch((error) => console.error(error));
    }
  };

  useEffect(() => {
    fetchMessages();
  }, [sender, receiver]);

  // Mark messages as read
  const markAsRead = (sender: string, recipient: string) => {
    axios.put(`${apiUrl.url}messages/${sender}/${recipient}`, null, { headers })
      .then(() => {
        console.log('Messages marked as read');
      })
      .catch((error) => console.error(error));
  };

  // Send a new message
  const sendMessage = () => {
    if (content.trim()) {
      axios.post(`${apiUrl.url}messages`, { sender, content, recipient: receiver }, { headers })
        .then((response) => {
          setMessages([...messages, { id: response.data.id, sender, content, timestamp: new Date().toISOString() }]);
          setContent('');
        })
        .catch((error) => console.error(error));
    }
  };

    // ***** Fetch Receiver's Avatar *****
    const fetchReceiverAvatar = () => {
      if (receiver) {
        axios
          .get(`${apiUrl.url}messages/message_list_user_details/${receiver}`, { headers })
          .then((response) => {
            setRecAvatar(response.data[0].avatar || '/static/media/default.d1c5ffe2b3cea9d80c7b.jpg');
          })
          .catch((error) => console.error(error));
      }
    };
  
    useEffect(() => {
      fetchReceiverAvatar();
    }, [receiver]);

  // Format timestamp
  const formattedTimestamp = (timestamp: string) => {
    const date = new Date(timestamp);
    const timeOptions: Intl.DateTimeFormatOptions = { hour: 'numeric', minute: '2-digit', hour12: true };
    const dateOptions: Intl.DateTimeFormatOptions = { month: 'short', day: 'numeric' };
    return `${new Intl.DateTimeFormat('en-US', timeOptions).format(date)} | ${new Intl.DateTimeFormat('en-US', dateOptions).format(date)}`;
  };

  return (
    <div className="container-fluid">
      <Row className="justify-content-md-center">
        <Col>
          <SubMenu />
        </Col>
        <Col lg="10" className="p-3 bg-white rounded shadow-sm">
          <h4 className="text-primary"><AiOutlineComment size="30" /> Message</h4>
          <Breadcrumb>
             <Breadcrumb.Item onClick={() => navigate('/admin_user_menu')}>User Menu</Breadcrumb.Item>
             <Breadcrumb.Item onClick={() => navigate('/admin_user_list')}>User List</Breadcrumb.Item>
             <Breadcrumb.Item active>User Details</Breadcrumb.Item>
          </Breadcrumb>
          <hr />
          <section>
            <div className="row">
              <div className="col-md-12">
                <Alert style={{ border: "1px solid rgb(128, 128, 128)" }}>
                  <>Chat with <strong className='text-primary ' style={{ fontSize: '20px' }}>{fname} {mi}. {lname}</strong></>
                  <div className="card-body">
                    <div className="row">
                      <div className="col-md-12">
                        <div
                          className="p-3 shadow-sm"
                          style={{ position: 'relative', height: '550px', overflowY: 'auto', backgroundColor: 'white' }}
                          ref={messagesContainerRef}
                        >
                          {messages.map((message, index) => (
                            <div key={index}>
                              {sender === message.sender ? (
                                // Sent by the current user
                                <div className="d-flex flex-row justify-content-end">
                                  <div>
                                    <p className="medium p-2 me-3 mb-1 text-white rounded-3 bg-primary">
                                      {message.content}
                                    </p>
                                    <p className="small me-3 mb-3 text-muted">{formattedTimestamp(message.timestamp)}</p>
                                  </div>
                                  <img
                                    src={userAvatar || '/static/media/default.d1c5ffe2b3cea9d80c7b.jpg'}
                                    alt="avatar"
                                    style={{ width: '45px', height: '100%' }}
                                  />
                                </div>
                              ) : (
                                // Received by the current user
                                <div className="d-flex flex-row justify-content-start">
                                  <img
                                    src={recAvatar || '/static/media/default.d1c5ffe2b3cea9d80c7b.jpg'}
                                    alt="avatar"
                                    style={{ width: '45px', height: '100%' }}
                                  />
                                  <div>
                                    <p className="medium p-2 ms-3 mb-1 rounded-3" style={{ backgroundColor: '#e1e1e1' }}>
                                      {message.content}
                                    </p>
                                    <p className="small ms-3 mb-3 text-muted">{formattedTimestamp(message.timestamp)}</p>
                                  </div>
                                </div>
                              )}
                            </div>
                          ))}
                        </div>

                        <div className="text-muted d-flex justify-content-start align-items-center pt-3 mt-2">
                          <input
                            type="text"
                            className="form-control form-control-md rounded-pill"
                            placeholder="Type message"
                            value={content}
                            onChange={(e) => setContent(e.target.value)}
                            onKeyDown={(e) => {
                              if (e.key === 'Enter') {
                                e.preventDefault();
                                sendMessage();
                              }
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </Alert>
              </div>
            </div>
          </section>
        </Col>
      </Row>
    </div>
  );
};

export default Messages;

