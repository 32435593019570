// src/components/LoginForm.tsx
import React, { useState, useEffect } from 'react';
import {
  Button,
  Container,
  Row,
  Alert,
  Col
} from 'react-bootstrap';
import { PiEyeLight } from "react-icons/pi";
import { PiEyeSlash } from "react-icons/pi";
import { AiOutlineLogin } from "react-icons/ai";
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import { AiOutlineSend } from "react-icons/ai";
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { useDispatch } from 'react-redux';
import { loginRequest } from './redux/loginActions';
import logo from '../../../images/linao.png';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState } from '../../../redux/reducers';
import { LoadingPage } from '../../../components/loader';
import { LoginFailed } from './loader';
import { LoginOtp } from './login_otp';
import me from '../../../images/casa.jpg';
import { getLocalStorageVariable } from '../../../components/localStorage';

const LoginSchema = Yup.object().shape({
  email: Yup
    .string()
    .email('Invalid email')
    .required('Email is required!'),
  password: Yup
    .string()
    .required('Password is required!'),
});

const LoginForm: React.FC = () => {
  const redirectPath = useSelector((state: RootState) => state.LoginReducer.redirectPath);
  const failed = useSelector((state: RootState) => state.LoginReducer.error);
  const loading = useSelector((state: RootState) => state.LoginReducer.loading);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    const userId = getLocalStorageVariable<string>('userId');
    const userAuth = getLocalStorageVariable<string>('userAuth');
    if (userId && userAuth=='client') {
      navigate('/dashboard'); 
    }

    // if (redirectPath) {
    //   navigate(redirectPath);
    //   window.location.reload();
    // }
  }, [navigate, dispatch]);

  // Start Genrate OTP
  const [randomString, setRandomString] = useState<string>('');

  const generateRandomString = (length: number): string => {
    const characters = '0123456789';
    let result = '';
    for (let i = 0; i < length; i++) {
      const randomIndex = Math.floor(Math.random() * characters.length);
      result += characters.charAt(randomIndex);
    }
    return result;
  };

  useEffect(() => {
      const generatedString = generateRandomString(6);
      setRandomString(generatedString);
  }, []);
  // End Geerate OTP

  const handleSubmit = (values: any) => {
    const payload = {
      email: values.email,
      password: values.password,
      otp: randomString,
    }
    dispatch(loginRequest(payload));
    
    // const { email, password } = values;
    // dispatch(loginRequest(email, password));
  };

  const handleItemClick = (path: string) => {
    // setSessionVariable('setSelectedItem', path);
    navigate(path);
};

const [showPassword, setShowPassword] = useState(false); // State for password visibility
const togglePasswordVisibility = () => {
  setShowPassword(prevState => !prevState); // Toggle password visibility
};

  return (
    <Formik
      initialValues={{ email: '', password: '' }}
      validationSchema={LoginSchema}
      onSubmit={handleSubmit}
    >
      {({ errors, touched }) => (
      <div style={{
          backgroundImage : `url(${me})`,
          backgroundSize: 'cover',
          backgroundAttachment: 'fixed',
          overflow: 'hidden',
          height:'91vh',
          width:'100%'
        }}>
        <div style={{
          position:'absolute',
          top:'0',right:'0',left:'0',bottom:'0',
          backgroundColor:'rgba(0,0,0,0.2)',
          zIndex:'0'
        }}></div>

      <Container style={{
        position:'relative',
        zIndex:'1'
      }}>
        {loading?<LoadingPage/>:""}
        {failed?<LoginFailed/>:""}
        {redirectPath=='/login_otp'?<LoginOtp/>:""}

        <Row className="justify-content-md-center">
          <Col sm lg="4" className="mt-5 my-3 p-3 bg-white bg-opacity-75 rounded shadow-lg" >
              <div className="text-info text-center mt-3"> <img src={`${logo}`} alt="" width="180" height="110"/></div>
              <hr/>
              
              <Alert variant="primary">
                  <Alert.Heading><AiOutlineLogin size="25" /> Account Login</Alert.Heading>
              </Alert>
                <Form>
                  <Row>
                      <Col sm>
                        <FloatingLabel label="Email address" className="mb-2">
                          <Field
                            type="email"
                            name="email"
                            placeholder="E-mail"
                            className={`w-100 form-control ${touched.email && errors.email ? 'is-invalid' : touched.email ? 'is-valid' : ''}`}
                          />
                          <ErrorMessage name="email">
                            {msg => <div className="invalid-feedback">{msg}</div>}
                          </ErrorMessage>
                        </FloatingLabel> 
                      </Col>
                    </Row>

                    <Row>
                      <Col sm>
                        <FloatingLabel label="Password">
                            <Field
                              type={showPassword ? 'text' : 'password'} // Toggle between text and password
                              name="password"
                              placeholder="Password"
                              className={`w-100 form-control ${touched.password && errors.password ? 'is-invalid' : touched.password ? 'is-valid' : ''}`}
                            />
                            <span 
                              onClick={togglePasswordVisibility}
                              style={{
                                position: 'absolute',
                                right: '30px',
                                top: '50%',
                                transform: 'translateY(-50%)',
                                cursor: 'pointer',
                                color: '#6c757d' // Optional: adjust color as needed
                              }}
                            >
                              {showPassword ? <PiEyeSlash size='25' /> : <PiEyeLight size='25' />}
                            </span>
                          <ErrorMessage name="password">
                            {msg => <div className="invalid-feedback">{msg}</div>}
                          </ErrorMessage>
                        </FloatingLabel>
                      </Col>
                    </Row>

                    <br/>
                    <div className="mb-3">
                        <div onClick={() => handleItemClick('/forgot')} style={{ textDecoration: 'none', color:'blue', cursor: 'pointer' }}>Forgot password?</div>
                    </div>
                    {/* <ReCAPTCHA sitekey="6Le73ZQaAAAAAMiABk5UPq2s-nPc1RCVS0opsrp8" onChange={onChange} /> */}
                  <div className="d-grid gap-2">
                      <Button variant="primary" type="submit" className="btn btn-primary btn-block rounded-pill mb-4" >
                          <AiOutlineSend size="20"/> Login
                      </Button>
                  </div>
                </Form>
            </Col>
          <Col sm lg="7">
          </Col>
        </Row>
      </Container>
      </div>
      )}
    </Formik>
  );
};

export default LoginForm;
