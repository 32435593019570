import React, {useEffect} from 'react';
import { 
    Row, 
    Col,
    Card,
} from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { AiOutlineDelete, AiOutlineUnorderedList } from "react-icons/ai";
import { BsClipboard2Plus, BsClipboard2Minus } from "react-icons/bs";
import { FaRegEdit } from "react-icons/fa";
import { LuClipboardList } from "react-icons/lu";
import { FaWater } from "react-icons/fa";
import { IoWaterOutline } from "react-icons/io5";
import { MdOutlineDiscount } from "react-icons/md";
import SubMenu from '../submenu';


const WaterMenu: React.FC = () =>{
    useEffect(() => {
        const keys = Object.keys(sessionStorage);
    
        keys.forEach((key) => {
          if (key !== 'setSelectedItem') {
            sessionStorage.removeItem(key);
          }
        });
      }, []);

    return (
        <div className="container-fluid ">
            <Row className="justify-content-md-center">
                <Col sm={2}>
                    <SubMenu/>
                </Col>
                <Col xs lg="10"  className="p-3 bg-white rounded shadow-sm">
                    <h4 className="text-info" ><IoWaterOutline size="30" /> Water Bill Menu</h4>
                    <hr/>
                    <div style = {{ width:"400px"}} ></div>
                    <Row xs={1} md={4} className="g-4">
                        <Col>
                            <Link to="/admin_water_single" style={{ textDecoration: 'none'}}>
                                <Card className="mb-3 rounded shadow-lg justify-content-between align-items-center bg-info bg-opacity-75">
                                    <Card.Body >
                                        <Card.Title className='text-white'>
                                            <div className="text-center w-100"><IoWaterOutline  size="90" /></div><br/>Single Entry
                                        </Card.Title>
                                    </Card.Body>
                                </Card>
                            </Link>
                        </Col>
                        <Col>
                            <Link to="/admin_generate_water_bill" style={{ textDecoration: 'none'}}>
                                <Card className="mb-3 rounded shadow-lg justify-content-between align-items-center text-center bg-info bg-opacity-75">
                                    <Card.Body >
                                        <Card.Title className='text-white'>
                                            <div className="text-center w-100"><FaWater size="90" /></div><br/>Generate Water Bill
                                        </Card.Title>
                                    </Card.Body>
                                </Card>
                            </Link>
                        </Col>
                        <Col>
                            <Link to="/admin_water_credit_memo" style={{ textDecoration: 'none'}}>
                                <Card className="mb-3 rounded shadow-lg justify-content-between align-items-center bg-info bg-opacity-75">
                                    <Card.Body >
                                        <Card.Title className='text-white'>
                                            <div className="text-center w-100"><BsClipboard2Minus  size="90" /></div><br/>Credit Memo
                                        </Card.Title>
                                    </Card.Body>
                                </Card>
                            </Link>
                        </Col>
                        <Col>
                            <Link to="/admin_water_debit_memo" style={{ textDecoration: 'none'}}>
                                <Card className="mb-3 rounded shadow-lg justify-content-between align-items-center bg-info bg-opacity-75">
                                    <Card.Body >
                                        <Card.Title className='text-white'>
                                            <div className="text-center w-100"><BsClipboard2Plus  size="90" /></div><br/>Debit Memo
                                        </Card.Title>
                                    </Card.Body>
                                </Card>
                            </Link>
                        </Col>
                        <Col>
                            <Link to="/admin_water_list" style={{ textDecoration: 'none'}}>
                                <Card className="mb-3 rounded shadow-lg justify-content-between align-items-center text-center bg-info bg-opacity-75">
                                    <Card.Body >
                                        <Card.Title className='text-white'>
                                            <div className="text-center w-100"><AiOutlineUnorderedList  size="90" /></div><br/>Bill List
                                        </Card.Title>
                                    </Card.Body>
                                </Card>
                            </Link>
                        </Col>
                        <Col>
                            <Link to="/admin_water_adjustment_list" style={{ textDecoration: 'none'}}>
                                <Card className="mb-3 rounded shadow-lg justify-content-between align-items-center text-center bg-info bg-opacity-75">
                                    <Card.Body >
                                        <Card.Title className='text-white'>
                                            <div className="text-center w-100"><LuClipboardList  size="90" /></div><br/>Adjustment List
                                        </Card.Title>
                                    </Card.Body>
                                </Card>
                            </Link>
                        </Col>
                        <Col>
                            <Link to="/bills_ad_userlist" style={{ textDecoration: 'none'}}>
                                <Card className="mb-3 rounded shadow-lg justify-content-between align-items-center bg-info bg-opacity-75">
                                    <Card.Body >
                                        <Card.Title className='text-white'>
                                            <div className="text-center w-100"><MdOutlineDiscount  size="90" /></div><br/>Discount
                                        </Card.Title>
                                    </Card.Body>
                                </Card>
                            </Link>
                        </Col>
                        <Col>
                            <Link to="/bills_ad_deleteuser" style={{ textDecoration: 'none'}}>
                                <Card className="mb-3 rounded shadow-lg justify-content-between align-items-center text-center bg-info bg-opacity-75">
                                    <Card.Body >
                                        <Card.Title className='text-white'>
                                            <div className="text-center w-100"><FaRegEdit size="90" /></div><br/>Bill Update
                                        </Card.Title>
                                    </Card.Body>
                                </Card>
                            </Link>
                        </Col>
                        <Col>
                            <Link to="/bills_ad_searchactivateuser" style={{ textDecoration: 'none'}}>
                                <Card className="mb-3 rounded shadow-lg justify-content-between align-items-center text-center bg-info bg-opacity-75">
                                    <Card.Body >
                                        <Card.Title className='text-white'>
                                            <div className="text-center w-100"><AiOutlineDelete size="90" /></div><br/>Delete
                                        </Card.Title>
                                    </Card.Body>
                                </Card>
                            </Link>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </div>
    );
};

export default WaterMenu;