import React, { useState, useEffect, useCallback } from 'react';
import ReactToPrint from 'react-to-print';
import axios, { AxiosResponse } from 'axios';
import { 
    Row, 
    Col,
    InputGroup,
    Button,
    Alert,
    Table,
    Breadcrumb,
    Pagination,
} from 'react-bootstrap';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { AiOutlineBars } from "react-icons/ai";
import { TfiPrinter } from "react-icons/tfi";
import { RiFileExcel2Fill } from "react-icons/ri";
import { BiSearchAlt } from "react-icons/bi";
import { FaFileCsv } from "react-icons/fa";
import SubMenu from '../submenu';
import { useNavigate } from 'react-router-dom';
import { setSessionVariable } from '../../../components/sessionStorage';
import { AuthAccessFailed } from '../../../components/loader';
import { getLocalStorageVariable } from '../../../components/localStorage';
import apiUrl from '../../../components/apiurl';
import { CSVLink } from "react-csv";
import * as XLSX from 'xlsx';
import saveAs from 'file-saver';
  
const SearchSchema = Yup.object().shape({
    search: Yup.string()
        .trim(),
  });

const CreateSchema = Yup.object().shape({
    fdate: Yup.string()
        .required("From date is required"),
    tdate: Yup.string()
        .required("To date is required"),
  });


  interface AssociationData {
    association_no: number;
    account: number;
    fname: string;
    mi: string;
    lname: string;
    phase: string;
    block: string;
    lot: string;
    lot_area: number;
    dues: number;
    charge: number;
    cdate: string;
    date_from: string;
    date_to: string;
    total_amount: number;
    balance: number;
    accounting_id: number;
    manager_id: number;
    discount: number;
  }

const ITEMS_PER_PAGE = 50;

const Association_Bill: React.FC = () =>{
    const token = getLocalStorageVariable<string>('token');
    const [associationData, setAssociationData] = useState<AssociationData[] | null>(null);
    const [filteredAssociationData, setFilteredAssociationData] = useState<AssociationData[]>([]);
    const navigate = useNavigate();
    const [searchDateFrom, setSearchDateFrom] = useState('');
    const [searchDateTo, setSearchDateTo] = useState('');

    const association_read = getLocalStorageVariable<string>('association_read');
    const componentRef = React.useRef(null);
    const id = '1';

    // Pagination
    const [currentPage, setCurrentPage] = useState(1);
    const indexOfLastItem = currentPage * ITEMS_PER_PAGE;
    const indexOfFirstItem = indexOfLastItem - ITEMS_PER_PAGE;
    const currentItems = filteredAssociationData?.slice(indexOfFirstItem, indexOfLastItem);
    const paginate = (pageNumber: number) => setCurrentPage(pageNumber);
    const totalPages = Math.ceil((filteredAssociationData?.length || 0) / ITEMS_PER_PAGE);

    const headers = [
        { label: "Date", key: "cdate" },
        { label: "Bill #", key: "association_no" },
        { label: "First Name", key: "fname" },
        { label: "Last Name", key: "lname" },
        { label: "Phase", key: "phase" },
        { label: "Block", key: "block" },
        { label: "Lot", key: "lot" },
        { label: "Lot Area", key: "lot_area" },
        { label: "Dues", key: "dues" },
        { label: "Charge", key: "charge" },
        { label: "Total Amount", key: "total_amount" },
      ];

      const formatDateExcel = (dateString: string) => {
        const options: Intl.DateTimeFormatOptions = { year: 'numeric', month: '2-digit', day: '2-digit' };
        const formattedDate = new Date(dateString).toLocaleDateString('en-US', options);
        return formattedDate;
      };

      const formattedData = filteredAssociationData.map((item) => ({
        ...item,
        date_from: formatDateExcel(item.date_from),
        date_to: formatDateExcel(item.date_to),
        cdate: formatDateExcel(item.cdate),
      }));
      
      const exportToExcel = () => {
        const worksheet = XLSX.utils.json_to_sheet(formattedData);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet 1");
        const excelBuffer = XLSX.write(workbook, {
          bookType: "xlsx",
          type: "array",
        });
        const dataBlob = new Blob([excelBuffer], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        saveAs(dataBlob, "association_bill.xlsx");
      };

// ***** Print List *****
    class ComponentToPrint extends React.Component {
        render() {
          return (
            <div style={{fontSize:'10px'}} className="print-content">
                <Table responsive striped bordered hover size="sm">
                    <thead>
                        <tr className="text-center">
                            <th>#</th>
                            <th>Date</th>
                            <th>Bill #</th>
                            <th>Account #</th>
                            <th>Name</th>
                            <th>Phase</th>
                            <th>Block</th>
                            <th>Lot</th>
                            <th>Balance</th>
                            <th>Total</th>
                        </tr>
                    </thead>
                        {filteredAssociationData?.length!=0? (
                            <tbody>
                                {filteredAssociationData?.map((r, i) => (
                                <tr key={i} className="align-middle text-secondary">
                                    <td className="text-center align-middle">{i + 1}</td>
                                    <td className="text-center align-middle">{(new Date(r.cdate)).toLocaleDateString("en-PH")}</td>
                                    <td className="text-center align-middle">{r.association_no}</td>
                                    <td className="text-center align-middle">{r.account}</td>
                                    <td className="">{r.fname} {r.mi}. {r.lname}</td>
                                    <td className="text-center align-middle">{r.phase}</td>
                                    <td className="text-center align-middle">{r.block}</td>
                                    <td className="text-center align-middle">{r.lot}</td>
                                    <td className="text-end">
                                        {r.balance.toLocaleString("en-US", { maximumFractionDigits: 2, minimumFractionDigits: 2 })}
                                    </td>
                                    <td className="text-end">
                                        {(r.total_amount).toLocaleString("en-US", { maximumFractionDigits: 2, minimumFractionDigits: 2 })}
                                    </td>
                                </tr>
                                ))}
                            </tbody>
                        ) : (
                            <tbody>
                                <tr><td colSpan={15} className='text-danger'>No association bill found...</td></tr>
                            </tbody>
                        )}
                </Table>
                <style>
                    {`
                    @media print {
                        body {
                            margin: 0;
                        }
                        .print-content {
                        }
                        @page {
                            size: A4;
                            margin: 5mm;
                        }
                    }
                    `}
                </style>
            </div>
          );
        }
      }

    useEffect(() => {
        const currentDate = new Date();
        const formattedDate = formatDate(currentDate);
        setSearchDateFrom(formattedDate);
        setSearchDateTo(formattedDate);
      }, []); 
    
      const formatDate = (date: Date): string => {
        const year = date.getFullYear();
        const month = (date.getMonth() + 1).toString().padStart(2, '0'); 
        const day = date.getDate().toString().padStart(2, '0');
    
        return `${year}-${month}-${day}`;
      };

    const fetchAssociation = useCallback(async () => {
        const headers = {
            Authorization: `${token}`,
          };
        try {
            const res: AxiosResponse<AssociationData[]> = await axios.get(`${apiUrl.url}admin_get_association/date_range`, { headers, params: { date_from: searchDateFrom, date_to: searchDateTo  } });
            setAssociationData(res.data.length > 0 ? res.data : null);
            setFilteredAssociationData(res.data);
        } catch (error) {
            console.error('Error: ', error);
        }
    }, [searchDateFrom, searchDateTo, token]);

    useEffect(() => {
        fetchAssociation();
    }, [ fetchAssociation ]);

    const handleDateChange = (event: any, dateType: 'from' | 'to') => {
        const newDate = event.target.value;
        if (dateType === 'from') {
          setSearchDateFrom(newDate);
        } else if (dateType === 'to') {
          setSearchDateTo(newDate);
        }
      };

    const handleSearch = (values: { search: string }) => {
        const searchTerm = values.search.toLowerCase();
        if (associationData) {
          const filteredData = associationData.filter((item) =>
            item.association_no.toString().includes(searchTerm) ||
            item.account.toString().includes(searchTerm) ||
            item.fname.toLowerCase().includes(searchTerm) ||
            item.lname.toLowerCase().includes(searchTerm)
          );
            setFilteredAssociationData(filteredData);
            setCurrentPage(1);
        }
      };
    
    const handleSubmit = (values: any) => {
        
    };

    const handleItemClick = (path: string) => {
        setSessionVariable('setSelectedItem', path);
        navigate(path);
    };

    return (
        <Formik
            enableReinitialize={true}
            initialValues={{ 
                fdate: searchDateFrom,
                tdate: searchDateTo,
            }}
            validationSchema={CreateSchema}
            onSubmit={handleSubmit}
            >
            <div className="container-fluid ">
                {association_read != '1'? <AuthAccessFailed />:""}

                <Row className="justify-content-md-center">
                    <Col>
                        <SubMenu/>
                    </Col>
                    <Col xs lg="10"  className="p-3 bg-white rounded shadow-sm ">
                        <h4 className="text-primary" ><AiOutlineBars size="30" /> Association Bill</h4>
                            <Breadcrumb>
                                <Breadcrumb.Item onClick={() => handleItemClick('/admin_report_menu')}>Reports</Breadcrumb.Item>
                                <Breadcrumb.Item active>Association Bill</Breadcrumb.Item>
                            </Breadcrumb>
                            <hr/>
                        <div style = {{ width:"400px"}} />

                        <Formik
                            enableReinitialize={true}
                            initialValues={{ 
                                search: "",
                            }}
                            validationSchema={SearchSchema}
                            onSubmit={handleSearch}
                            >
                            <Form>
                                <Row>
                                    <Col xs lg="6"  className="bg-white rounded">
                                        <InputGroup className=" mb-3">
                                                <Field 
                                                    type="search" 
                                                    name="search"
                                                    placeholder="&#x1F50E; Search..."
                                                    maxLength="50"
                                                    className="w-50 form-control rounded-pill"
                                                    style={{border: "1px solid rgb(128, 128, 128)"}}
                                                >
                                                </Field>
                                                <Button type="submit" variant="outline-success" style={{display:'none'}}><BiSearchAlt size="20" className='ms-2 me-2'/></Button>
                                            <ErrorMessage name="search">
                                                {msg => <div style={{color:'red',padding:'5px'}}>{msg}</div>}
                                            </ErrorMessage>
                                        </InputGroup>
                                    </Col>
                                    <Col lg="6" className=''>
                                            <div className="btn-toolbar mb-2 mb-md-0 float-end">
                                            <div className="btn-group mr-2">
                                                
                                                <ReactToPrint
                                                    trigger={() => <button type="button"  className="btn btn-md btn-outline-success"><TfiPrinter size="20"/> Print List</button>}
                                                    content={() => componentRef.current}
                                                />
                                                <button type="button" onClick={exportToExcel} className="btn btn-md btn-outline-success"><RiFileExcel2Fill size="20"/> Export to Excel</button>
                                                <CSVLink data={formattedData} headers={headers} filename={"association_bill.csv"}
                                                    className="btn btn-md btn-outline-success"
                                                    target="_blank">
                                                        <FaFileCsv size="20"/> Export to CSV
                                                </CSVLink>
                                            </div>
                                                <div style={{ display: 'none' }}>
                                                    <ComponentToPrint ref={componentRef} />
                                                </div>
                                        </div>
                                    </Col>
                                </Row>
                            </Form>
                        </Formik>

                        {/* Input From and To date of the association bill generated date. */}
                        <Alert style={{border: "1px solid rgb(128, 128, 128)", backgroundColor: '#e6ffe3'}} className='mt-1'>
                        <Form>
                            <Row>
                                <Col sm>   
                                    <InputGroup>
                                        <InputGroup className="w-25">
                                            <InputGroup.Text className="w-100">
                                                Month <strong className='text-danger'>&nbsp;*</strong>
                                            </InputGroup.Text>
                                        </InputGroup>
                                        <Field 
                                            type="date" 
                                            name="fdate"
                                            className="w-75 form-control"
                                            onChange={(event:any) => handleDateChange(event, 'from')}
                                        />
                                        <ErrorMessage name="fdate">
                                            {msg => <div style={{color:'red',padding:'5px'}}>{msg}</div>}
                                        </ErrorMessage>
                                    </InputGroup>
                                </Col>
                                {/* <Col sm>   
                                    <InputGroup>
                                        <InputGroup className="w-25">
                                            <InputGroup.Text className="w-100">
                                                Year <strong className='text-danger'>&nbsp;*</strong>
                                            </InputGroup.Text>
                                        </InputGroup>
                                        <Field 
                                            type="date" 
                                            name="tdate" 
                                            className="w-75 form-control"
                                            onChange={(event:any) => handleDateChange(event, 'to')}
                                        />
                                        <ErrorMessage name="tdate">
                                            {msg => <div style={{color:'red',padding:'5px'}}>{msg}</div>}
                                        </ErrorMessage>
                                    </InputGroup>
                                </Col> */}
                                <Col sm>   
                                    <InputGroup>
                                        <InputGroup className="w-25">
                                            <InputGroup.Text className="w-100">
                                                Year <strong className='text-danger'>&nbsp;*</strong>
                                            </InputGroup.Text>
                                        </InputGroup>
                                            <Field 
                                                as="select" 
                                                name="manager"
                                                className="w-75 form-control"
                                            >
                                                <option value="">- Select Option -</option>
                                                <option value="">2018</option>
                                                <option value="">2019</option>
                                            </Field>
                                        <ErrorMessage name="manager">
                                            {msg => <div style={{color:'red',padding:'5px'}}>{msg}</div>}
                                        </ErrorMessage>
                                    </InputGroup>
                                </Col>
                                <Col sm></Col>
                            </Row>
                        </Form>
                        </Alert>

                        <Table responsive striped bordered hover size="sm">
                            <thead>
                                <tr className="text-center">
                                    <th>#</th>
                                    <th>Date</th>
                                    <th>Bill #</th>
                                    <th>Account #</th>
                                    <th>Name</th>
                                    <th>Phase</th>
                                    <th>Block</th>
                                    <th>Lot</th>
                                    <th>Balance</th>
                                    <th>Total</th>
                                </tr>
                            </thead>
                                {currentItems?.length!=0? (
                                    <tbody>
                                        {currentItems?.map((r, i) => (
                                        <tr key={i} className="align-middle text-secondary">
                                            <td className="text-center align-middle">{i + 1}</td>
                                            <td className="text-center align-middle">{(new Date(r.cdate)).toLocaleDateString("en-PH")}</td>
                                            <td className="text-center align-middle">{r.association_no}</td>
                                            <td className="text-center align-middle">{r.account}</td>
                                            <td className="">{r.fname} {r.mi}. {r.lname}</td>
                                            <td className="text-center align-middle">{r.phase}</td>
                                            <td className="text-center align-middle">{r.block}</td>
                                            <td className="text-center align-middle">{r.lot}</td>
                                            <td className="text-end">
                                                {r.balance.toLocaleString("en-US", { maximumFractionDigits: 2, minimumFractionDigits: 2 })}
                                            </td>
                                            <td className="text-end">
                                                {(r.total_amount).toLocaleString("en-US", { maximumFractionDigits: 2, minimumFractionDigits: 2 })}
                                            </td>
                                        </tr>
                                        ))}
                                    </tbody>
                                ) : (
                                    <tbody>
                                        <tr><td colSpan={15} className='text-danger'>No association bill found...</td></tr>
                                    </tbody>
                                )}
                        </Table>

                        {filteredAssociationData?.length && filteredAssociationData.length > ITEMS_PER_PAGE && (
                                <Pagination>
                                    <Pagination.First onClick={() => paginate(1)} />
                                    <Pagination.Prev onClick={() => paginate(currentPage - 1)} />

                                    {Array.from({ length: totalPages }).map((_, index) => {
                                        if (index >= currentPage - 2 && index <= currentPage + 2) {
                                        return (
                                            <Pagination.Item
                                            key={index}
                                            active={index + 1 === currentPage}
                                            onClick={() => paginate(index + 1)}
                                            >
                                            {index + 1}
                                            </Pagination.Item>
                                        );
                                        }

                                        return null;
                                    })}

                                    {currentPage + 2 < totalPages && (
                                        <Pagination.Ellipsis key={`ellipsis`} />
                                    )}

                                    <Pagination.Next onClick={() => paginate(currentPage + 1)} />
                                    <Pagination.Last onClick={() => paginate(totalPages)} />
                                </Pagination>
                            )}
                    </Col>
                </Row>
            </div>
        </Formik>
    );
};

export default Association_Bill;