import React, { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
// common
import { LoadingPage } from './components/loader';
import Test from './pages/test';
import Header from './pages/common/header';
import Footer from './pages/common/footer';
import Logout from './pages/common/logout';

// admin
import AdminLogin from './pages/admin/login/admin_login';
import AdminForgot from './pages/admin/login/admin_forgot';
import AdminProfile from './pages/admin/headers/profile';
import AdminNotifications from './pages/admin/headers/notifications';
import AdminMessages from './pages/admin/headers/messages';
import AdminDashboard from './pages/admin/dashboard/dashboard';
// admin user
import AdminUserMenu from './pages/admin/user/user_menu';
import AdminCreateUser from './pages/admin/user/create_user';
import AdminUploadUsers from './pages/admin/user/upload_users';
import AdminUpdateUser from './pages/admin/user/update_user';
import AdminUpdateUserList from './pages/admin/user/update_user_list';
import AdminUserList from './pages/admin/user/user_list';
import AdminUserView from './pages/admin/user/user_view';
import AdminStatusUpdate from './pages/admin/user/status_update';
import AdminMessageUser from './pages/admin/user/message_user';
// admin Association
import AdminAssociationMenu from './pages/admin/association/association_menu';
import AdminGenerateAssociation from './pages/admin/association/generate_association';
import AdminAssociationSingle from './pages/admin/association/association_single';
import AdminAssociationCreditMemo from './pages/admin/association/credit_memo';
import AdminAssociationDebitMemo from './pages/admin/association/debit_memo';
import AdminAssociationList from './pages/admin/association/association_list';
import AdminAdjustmentList from './pages/admin/association/adjustment_list';
import AdminAssociationDiscountList from './pages/admin/association/discount_list';
// admin Water
import AdminWaterMenu from './pages/admin/water/water_menu';
import AdminGenerateWaterBill from './pages/admin/water/generate_water';
import AdminWaterSingle from './pages/admin/water/water_single';
import AdminWaterCreditMemo from './pages/admin/water/credit_memo';
import AdminWaterDebitMemo from './pages/admin/water/debit_memo';
import AdminWaterList from './pages/admin/water/water_list';
import AdminWaterAdjustmentList from './pages/admin/water/adjustment_list';
// admin Payment
import AdminPaymentMenu from './pages/admin/payment/payment_menu';
import AdminPayment from './pages/admin/payment/payment';
import AdminVerification from './pages/admin/payment/verification';
// transactions
import AdminTransactions from './pages/admin/transactions/transactions';
// reports
import AdminReportMenu from './pages/admin/report/report_menu';
import AdminReportAssociation from './pages/admin/report/association_bill';
// manage
import AdminSettingsMenu from './pages/admin/settings/settings_menu';
import AdminSettingsCreateAdminUser from './pages/admin/settings/create_admin_user';
import AdminSettingsAccessSettings from './pages/admin/settings/access_settings';
import AdminSettingsMonitoring from './pages/admin/settings/monitoring';

// ***************** users account *****************
import LoginForm from './pages/users/login/login';
import Activation from './pages/users/activation/activation';
import Verify_Email from './pages/users/activation/verify_email';
import Forgot from './pages/users/login/forgot';
// users Headers
import Profile from './pages/users/headers/profile';
import Messages from './pages/users/headers/messages';
import Dashboard from './pages/users/dashboard/dashboard';
import Notifications from './pages/users/headers/notifications';
// Wallet
import Wallet from './pages/users/wallet/wallet';
// Association
import Association from './pages/users/association/association';
// Water
import Water from './pages/users/water/water';


function App() {
  const [isLoading, setIsLoading] = useState(true); // Initialize loading state

  useEffect(() => {
    // Set a timeout to simulate loading delay, e.g., 2 seconds
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 2000);

    // Clean up the timer on component unmount
    return () => clearTimeout(timer);
  }, []);

  return (
    <BrowserRouter>
      {isLoading ? ( // Conditionally render the loader or the main content
        <LoadingPage />
      ) : (
        <>
      <Header/>
      <Routes>
        {/* System */}
        <Route path="/" element={<LoginForm />} />
        <Route path="/test" element={<Test />} />
        <Route path="/header" element={<Header />} />
        <Route path="/logout" element={<Logout />} />
        {/* Admin */}
        <Route path="/admin_login" element={<AdminLogin />} />
        <Route path="/admin_forgot" element={<AdminForgot />} />
        <Route path="/admin_profile" element={<AdminProfile />} />
        <Route path="/admin_notifications" element={<AdminNotifications />} />
        <Route path="/admin_messages" element={<AdminMessages />} />
        <Route path="/admin_dashboard" element={<AdminDashboard />} />
        {/* Admin User*/}
        <Route path="/admin_user_menu" element={<AdminUserMenu />} />
        <Route path="/admin_create_user" element={<AdminCreateUser />} />
        <Route path="/admin_upload_users" element={<AdminUploadUsers />} />
        <Route path="/admin_update_user" element={<AdminUpdateUser />} />
        <Route path="/admin_update_user_list" element={<AdminUpdateUserList />} />
        <Route path="/admin_user_list" element={<AdminUserList />} />
        <Route path="/admin_user_view" element={<AdminUserView />} />
        <Route path="/admin_status_update" element={<AdminStatusUpdate />} />
        <Route path="/admin_message_user" element={<AdminMessageUser />} />
        {/* Admin Association*/}
        <Route path="/admin_association_menu" element={<AdminAssociationMenu />} />
        <Route path="/admin_generate_association" element={<AdminGenerateAssociation />} />
        <Route path="/admin_association_single" element={<AdminAssociationSingle />} />
        <Route path="/admin_association_credit_memo" element={<AdminAssociationCreditMemo />} />
        <Route path="/admin_association_debit_memo" element={<AdminAssociationDebitMemo />} />
        <Route path="/admin_association_list" element={<AdminAssociationList />} />
        <Route path="/admin_adjustment_list" element={<AdminAdjustmentList />} />
        <Route path="/admin_discount_list" element={<AdminAssociationDiscountList />} />
        {/* Admin Water*/}
        <Route path="/admin_water_menu" element={<AdminWaterMenu />} />
        <Route path="/admin_generate_water_bill" element={<AdminGenerateWaterBill />} />
        <Route path="/admin_water_single" element={<AdminWaterSingle />} />
        <Route path="/admin_water_credit_memo" element={<AdminWaterCreditMemo />} />
        <Route path="/admin_water_debit_memo" element={<AdminWaterDebitMemo />} />
        <Route path="/admin_water_list" element={<AdminWaterList />} />
        <Route path="/admin_water_adjustment_list" element={<AdminWaterAdjustmentList />} />
        {/* Admin Payment */}
        <Route path="/admin_payment_menu" element={<AdminPaymentMenu />} />
        <Route path="/admin_payment" element={<AdminPayment />} />
        <Route path="/admin_verification" element={<AdminVerification />} />
        {/* Admin Transactions */}
        <Route path="/admin_transactions" element={<AdminTransactions />} />
        {/* Admin Reports */}
        <Route path="/admin_report_menu" element={<AdminReportMenu />} />
        <Route path="/admin_report_association_bill" element={<AdminReportAssociation />} />
        {/* Admin Manage */}
        <Route path="/admin_settings_menu" element={<AdminSettingsMenu />} />
        <Route path="/admin_settings_create_admin_user" element={<AdminSettingsCreateAdminUser />} />
        <Route path="/admin_settings_access_settings" element={<AdminSettingsAccessSettings />} />
        <Route path="/admin_settings_monitoring" element={<AdminSettingsMonitoring />} />


        {/* Users */}
        <Route path="/login" element={<LoginForm />} />
        <Route path="/activation" element={<Activation />} />
        <Route path="/verify_email" element={<Verify_Email />} />
        <Route path="/forgot" element={<Forgot />} />
        {/* Users Headers */}
        <Route path="/profile" element={<Profile />} />
        <Route path="/messages" element={<Messages />} />
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/notifications" element={<Notifications />} />
        {/* Wallet */}
        <Route path="/wallet" element={<Wallet />} />
        {/* Association */}
        <Route path="/association" element={<Association />} />
        {/* Water */}
        <Route path="/water" element={<Water />} />
      </Routes>
      <Footer/>
      </>
      )}
    </BrowserRouter>
  );
}

export default App;
