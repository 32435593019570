// src/store/auth/sagas.ts
import { call, put, takeLatest } from 'redux-saga/effects';
import { AxiosResponse } from 'axios';
import { 
  AuthActionTypes, 
  loginSuccess, 
  loginFailure, 
  redirect,
  forgotSuccess, 
  forgotFailure, 
  forgotRedirect 
} from './loginActions';
import apiUrl from '../../../../components/apiurl';
import axios from 'axios';
import { setLocalStorageVariable } from '../../../../components/localStorage';


function* login(action: any): Generator<any, void, AxiosResponse> {
    const formatDate = (date: Date): string => {
      const year = date.getFullYear();
      const month = (date.getMonth() + 1).toString().padStart(2, '0'); 
      const day = date.getDate().toString().padStart(2, '0');

      return `${year}-${month}-${day}`;
    };

  try {
    const currentDate = new Date();
    const formattedDate = formatDate(currentDate);

    const { email, password, otp } = action.payload;
    const response: AxiosResponse = yield call(axios.post, `${apiUrl.url}login`, { email, password, otp });
    const user = response.data;

      if(user!='') {
            // yield call(axios.post, `${apiUrl.url}otp/request-otp`, { email, otp });
            yield put(redirect('/login_otp'));
            yield put(loginSuccess(user));

            setLocalStorageVariable('loginEmail', email);
            setLocalStorageVariable('tempuserId', user.id);
            setLocalStorageVariable('tempuserAuth', user.auth);
            setLocalStorageVariable('tempavatar', user.avatar);
            setLocalStorageVariable('temptoken', user.token);
            setLocalStorageVariable('temploginDate', formattedDate);

              const response: AxiosResponse = yield call(axios.post, `${apiUrl.url}send_email_no_token`, {
                to: action.payload.email,
                subject: 'OTP Verification',
                html: `
                  <div style="background-color: rgb(243,243,243); padding: 30px;">
                    <div style="font-family:Arial, Helvetica, sans-serif; font-size: 14px; border: 1px solid #d1d1d1">
                      <div style="background-color: #9ffcca; color: #383d3a; padding: 5px; border: none; text-align: center;">
                      <a href="https://pearsportal.com" target="_blank"><img src="https://billing.pearsportal.com/linao.png" style="width:160px; height:100px" /></a>
                      </div>
                      <div style="padding: 30px; background-color: #FFF">
                      Hi ${action.payload.email},
                      <br/>
                      <br/>
                        Never share your OTP. Keep your code safe. <br/>Your OTP is : <b> ${action.payload.otp}</b><br/>
                        It is valid for 3 minutes.<br/><br/>
                        If this is not you please contact the system administrator immediately.<br/>
                        Please give us some feedback to improve the system.<br/>Thank you.
                        <br/><br/>
                        Best regards,<br/>
                        Casa Mira - Linao Application Team
                      </div>
                    </div>
                  </div>`,
              });

              if (response.status === 200) {
                console.log('Email sent successfully');
              } else {
                console.error('Failed to send email');
              }

            

        // setLocalStorageVariable('userAuth', user[0].auth);
        // console.log(user.token)
      }else{
        yield put(loginFailure('failed'));
      }
  } catch (error) {
    const errorMessage = (error as any).message; 
    yield put(loginFailure(errorMessage));
  }
}


// ************** Forgot *************
function* forgot(action: any): Generator<any, void, AxiosResponse> {
  try {
    const response: AxiosResponse = yield call(axios.post, `${apiUrl.url}login/forgot`, action.payload);
    const user = response.data;

      if(user!=''){
        yield put(forgotSuccess(user));
        yield put(forgotRedirect('/login'));
          const response: AxiosResponse = yield call(axios.post, `${apiUrl.url}send_email_no_token`, {
            to: action.payload.email,
            subject: 'Password Reset',
            html: `
              <div style="background-color: rgb(243,243,243); padding: 30px;">
                <div style="font-family:Arial, Helvetica, sans-serif; font-size: 14px; border: 1px solid #d1d1d1">
                  <div style="background-color: #9ffcca; color: #383d3a; padding: 5px; border: none; text-align: center;">
                  <a href="https://pearsportal.com" target="_blank"><img src="https://billing.pearsportal.com/linao.png" style="width:160px; height:100px" /></a>
                  </div>
                      <div style="padding: 30px; background-color: #FFF">
                        Hi ${action.payload.email},
                        <br/>
                        <br/>
                            Your password in Casa Mira - Linao Application is reset. <br/>Your temporary password is : <b> ${action.payload.password}</b><br/><br/>
                            This password is system generated.<br/>
                            Please update your password after you log in.<br/><br/>
                            If this is not you please contact the system administrator immediately.<br/>
                            Please give us some feedback to improve the system.<br/>Thank you.
                          <br/><br/>
                          Best regards,<br/>
                          Casa Mira - Linao Application Team
                      </div>
                    </div>
              </div>`,
          });
    
          if (response.status === 200) {
            console.log('Email sent successfully');
          } else {
            console.error('Failed to send email');
          }

      }else{
        yield put(forgotFailure('failed'));
      }
  } catch (error) {
    const errorMessage = (error as any).message; 
    yield put(forgotFailure(errorMessage));
  }
}


function* watchLogin() {
  yield takeLatest(AuthActionTypes.LOGIN_REQUEST, login);
  yield takeLatest(AuthActionTypes.FORGOT_REQUEST, forgot);
}

export default watchLogin;
