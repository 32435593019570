import React, { useEffect, useState } from 'react';
import { 
    ListGroup, 
    Row, 
    Col,
    Button,
} from 'react-bootstrap';
import { AiOutlineDashboard, AiOutlineGroup, AiOutlineDollar } from "react-icons/ai";
import { IoWaterOutline } from "react-icons/io5";
import { LuCalendarClock } from "react-icons/lu";
import { LuCalendarPlus } from "react-icons/lu";
import { CgMenuGridR } from "react-icons/cg";
import { LuWallet } from "react-icons/lu";
import { MdStorefront } from "react-icons/md";
import { getLocalStorageVariable } from '../../components/localStorage';
import { setSessionVariable, getSessionVariable } from '../../components/sessionStorage';
import { useNavigate } from 'react-router-dom';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import ReservationForm from './calendar/reservation';
import Offcanvas from 'react-bootstrap/Offcanvas';

type ValuePiece = Date | null;
type Value = ValuePiece | [ValuePiece, ValuePiece];

const SubMenu: React.FC = () =>{
    const userId = getLocalStorageVariable<string>('userId');
    const userAuth = getLocalStorageVariable<string>('userAuth');
    const token = getLocalStorageVariable<string>('token');
    const selectedItem = getSessionVariable<string>('setSelectedItem');
    const navigate = useNavigate();
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    useEffect(() => {
        if (!userId || userAuth!='client' || !token) {
            navigate('/logout'); 
        }
    }, [ navigate, userId, userAuth, token ]);

    const handleItemClick = (path: string) => {
        setSessionVariable('setSelectedItem', path);
        navigate(path);
    };

    const [value, onChange] = useState<Value>(new Date());
    const [selectedDate, setSelectedDate] = useState<Date | Date[]>(new Date());
    // console.log(value)

    const handleDateChange = (date: Date | Date[] | null) => {
        if (date !== null) {
        setSelectedDate(date);
        }
    };

    return (
        <div>
            <Button variant="outline-dark" className="d-lg-none" onClick={handleShow} style={{border: "none"}}>
                <CgMenuGridR size="40" />
            </Button>
            
                <Offcanvas show={show} onHide={handleClose} responsive="lg">
                    <Offcanvas.Header closeButton>
                        <Offcanvas.Title>Menu</Offcanvas.Title>
                    </Offcanvas.Header>
                    <Offcanvas.Body>

                    <Row className="justify-content-md-center mt-3 mb-4 w-100">
                        <Col sm={12}>
                            <ListGroup  variant="flush" className="rounded mb-3" >
                                <ListGroup.Item action variant="light"
                                    style={{ backgroundColor: selectedItem === '/dashboard' ? '#a1a1a1' : 'transparent', color: selectedItem === '/dashboard' ? '#fff' : '#000' }}
                                    onClick={() => handleItemClick('/dashboard')}>
                                    <AiOutlineDashboard size="20"/> &nbsp;Dashboard
                                </ListGroup.Item>
                                {/* <ListGroup.Item action variant="light"
                                    style={{ backgroundColor: selectedItem === '/wallet' ? '#a1a1a1' : 'transparent', color: selectedItem === '/wallet' ? '#fff' : '#000' }}
                                    onClick={() => handleItemClick('/wallet')}>
                                    <LuWallet size="20"/> &nbsp;Wallet
                                </ListGroup.Item> */}
                                <ListGroup.Item action variant="light"
                                    style={{ backgroundColor: selectedItem === '/association' ? '#a1a1a1' : 'transparent', color: selectedItem === '/association' ? '#fff' : '#000' }}
                                    onClick={() => handleItemClick('/association')}>
                                    <AiOutlineGroup size="20"/> &nbsp;Association
                                </ListGroup.Item>
                                <ListGroup.Item action variant="light"
                                    style={{ backgroundColor: selectedItem === '/water' ? '#a1a1a1' : 'transparent', color: selectedItem === '/water' ? '#fff' : '#000' }}
                                    onClick={() => handleItemClick('/water')}>
                                    <IoWaterOutline size="20"/> &nbsp;Water
                                </ListGroup.Item>
                                <ListGroup.Item action variant="light"
                                    style={{ backgroundColor: selectedItem === '/payment' ? '#a1a1a1' : 'transparent', color: selectedItem === '/payment' ? '#fff' : '#000' }}
                                    onClick={() => handleItemClick('/payment')}>
                                    <AiOutlineDollar size="20"/> &nbsp;Payment
                                </ListGroup.Item>
                                <ListGroup.Item action variant="light"
                                    style={{ backgroundColor: selectedItem === '/transactions' ? '#a1a1a1' : 'transparent', color: selectedItem === '/transactions' ? '#fff' : '#000' }}
                                    onClick={() => handleItemClick('/transactions')}>
                                    <LuCalendarClock size="20"/> &nbsp;Transactions
                                </ListGroup.Item>
                                {/* <ListGroup.Item action variant="light"
                                    style={{ backgroundColor: selectedItem === '/marketplace' ? '#a1a1a1' : 'transparent', color: selectedItem === '/marketplace' ? '#fff' : '#000' }}
                                    onClick={() => handleItemClick('/marketplace')}>
                                    <MdStorefront size="20"/> &nbsp;Marketplace
                                </ListGroup.Item> */}
                            </ListGroup>
                            
                            <div className='p-3'>
                                {/* <Calendar onChange={handleDateChange} value={selectedDate} /> */}
                                <Calendar onChange={onChange} calendarType='gregory'  value={value} />
                                {/* {Array.isArray(selectedDate) ? null : <ReservationForm selectedDate={selectedDate as Date} />} */}
                                <Button variant="outline-primary" className='mt-2 w-100'><LuCalendarPlus size='20' /> &nbsp;Reservation</Button><br/>
                                Basketball Court
                            </div>
                            
                        </Col>
                    </Row>

                </Offcanvas.Body>
            </Offcanvas>
        </div>
    );
};

export default SubMenu;