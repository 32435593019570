//local connection
// const apiUrl = {
//     url: "http://localhost:8888/billing/",
// }

//live connection
const apiUrl = {
    url: "https://billing-be.pearsportal.com/billing/",
}

export default apiUrl;



//local connection
// export const homeUrl = {
//     url: "http://localhost:3000/",
// }

//live connection
export const homeUrl = {
    url: "https://billing.pearsportal.com/",
}
