import React, { useState, useEffect } from 'react';
import { Formik, Field, Form,  ErrorMessage } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';
import { AxiosResponse } from 'axios';
import {
    Button,
    Container,
    Row,
    Alert,
    Col
} from 'react-bootstrap';
import { PiEyeLight } from "react-icons/pi";
import { PiEyeSlash } from "react-icons/pi";
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import { AiOutlineSend } from "react-icons/ai";
import { BiSolidUserAccount } from "react-icons/bi";
import { BiSearchAlt } from "react-icons/bi";
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../redux/reducers';
import { activate_searchRequest, activate_updateRequest } from './redux/activateActions';
import { ActivateFailed, ActivateSuccess, ActivateSearchFailed } from './loader';
import { LoadingPage } from '../../../components/loader';
import { getSessionVariable } from '../../../components/sessionStorage';
import apiUrl from '../../../components/apiurl';
import CryptoJS from 'crypto-js';

const SearchSchema = Yup.object().shape({
    account: Yup.string()
        .trim()
        .max(10, 'Must be 10 characters or less')
        .required("Account # is required"),
  });

const CreateSchema = Yup.object().shape({
    fname: Yup.string()
        .trim()
        .max(30, 'Must be 30 characters or less')
        .required("Firstname is required"),
    mi: Yup.string()
        .trim()
        .max(1, 'Must be 1 character only')
        .matches(/^([a-z\s A-Z])+$/, "Valid characters from A-Z only"),
    lname: Yup.string()
        .trim()
        .max(30, 'Must be 30 characters or less')
        .required("Lastname is required"),
    phase: Yup.string()
        .trim()
        .required("Phase is required"),
    block: Yup.string()
        .trim()
        .matches(/^\d+$/, "Please input valid numbers.")
        .required("Block number is required"),
    lot: Yup.string()
        .trim()
        .matches(/^\d+$/, "Please input valid numbers.")
        .required("Lot number is required"),
    pin: Yup.string()
        .trim()
        .max(6, 'Must be 6 number characters only')
        .matches(/^[0-9]{6}$/, "Please input 6-digit pin number code")
        .required('PIN Code is required'),
    cellphone: Yup.string()
        .trim()
        .matches(/^[0-9]{11}$/, "Please input valid cellphone # ex: 09XXXXXXXX")
        .required("Cellphone number is required"),
    email: Yup.string()
        .trim()
        .email("Enter a valid email")
        .required("Email address is required")
        .test('email-exists', 'Email already exists', async function (value) {
            try {
                const response: AxiosResponse = await axios.post(`${apiUrl.url}check_email/`, {email: value});
                    if (response.data.msg) {
                        return false; // Email is considered as already taken
                    }
                return !response.data.exists;
            } catch (error) {
              console.error('Error checking email duplication:', error);
              return false;
            }
          }),
    password: Yup.string()
          .required("Password is required")
          .min(8, "Password length must be at least 8 characters")
          .matches(/[a-z]/, "Password must contain at least one lowercase letter")
          .matches(/[A-Z]/, "Password must contain at least one uppercase letter")
          .matches(/[0-9]/, "Password must contain at least one number")
          .matches(/[@$!%*?&]/, "Password must contain at least one special character (@, $, !, %, *, ?, &)")
          .trim(),
    confirm_password: Yup.string()
          .required("Confirm Password is required")
          .oneOf([Yup.ref("password")], "Passwords do not match"),
    confirm: Yup
        .bool()
        .oneOf([true], "Please confirm")
        .required("Please confirm"),
  });


const Activation: React.FC = () =>{
    const loading = useSelector((state: RootState) => state.User_ActivateReducer.loading);
    const success = useSelector((state: RootState) => state.User_ActivateReducer.redirectPath);
    const failed = useSelector((state: RootState) => state.User_ActivateReducer.error);
    const searchfailed = useSelector((state: RootState) => state.User_ActivateReducer.searcherror);
    const dispatch = useDispatch();
    const accountSession = getSessionVariable<any>('account');
    const fname = getSessionVariable<string>('fname');
    const mi = getSessionVariable<string>('mi');
    const lname = getSessionVariable<string>('lname');
    const phase = getSessionVariable<string>('phase');
    const block = getSessionVariable<string>('block');
    const lot = getSessionVariable<string>('lot');

    // ***** Generate random string *****
    const [randomString, setRandomString] = useState<string>('');

    const generateRandomString = (length: number): string => {
    const characters = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz';
    let result = '';
        for (let i = 0; i < length; i++) {
            const randomIndex = Math.floor(Math.random() * characters.length);
            result += characters.charAt(randomIndex);
        }
        return result;
    };

    useEffect(() => {
        const generatedString = generateRandomString(6);
        setRandomString(generatedString);
    }, []);

    // ***** Search code *****
    const handleSearch = (values: any) => {
        const { account } = values;
        dispatch(activate_searchRequest(account));
      };

    const handleSubmit = (values: any) => {
        if(accountSession==''){
            console.log('Search for user account.')
        }else{
            const payload = {
                account: accountSession,
                pin: values.pin,
                email: values.email,
                password: values.password,
                cellphone: values.cellphone,
                verify_code: randomString,
            }
            dispatch(activate_updateRequest(payload));
        }
      };

    const [showConfirmPassword, setShowConfirmPassword] = useState(false); // State for password visibility
    const toggleConfirmPasswordVisibility = () => {
        setShowConfirmPassword(prevState => !prevState); // Toggle password visibility
    };

    const [showPassword, setShowPassword] = useState(false); // State for password visibility
    const togglePasswordVisibility = () => {
        setShowPassword(prevState => !prevState); // Toggle password visibility
    };

// ****** decrypt Data *****
    const encryptionKey = process.env.REACT_APP_DE_EN ?? ''; 
    const ivHex = '00000000000000000000000000000000'; // Fixed IV as used in the backend

    const decryptData = (encryptedData: string, encryptionKey: string): string => {
        const key = CryptoJS.enc.Utf8.parse(encryptionKey); // Use Utf8 parsing for consistency
        const iv = CryptoJS.enc.Hex.parse(ivHex);

        // Convert the hex string to bytes, then decrypt
        const encryptedHexStr = CryptoJS.enc.Hex.parse(encryptedData); // Interpret hex as bytes
        const encryptedBase64 = CryptoJS.enc.Base64.stringify(encryptedHexStr); // Convert bytes to Base64

        // Decrypt the Base64 string
        const bytes = CryptoJS.AES.decrypt(encryptedBase64, key, {
        iv: iv,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7,
        });

        return bytes.toString(CryptoJS.enc.Utf8);
    };


  return (
    <Container>
        {loading?<LoadingPage/>:""}
        {success?<ActivateSuccess/>:""}
        {failed?<ActivateFailed/>:""}
        {searchfailed?<ActivateSearchFailed/>:""}

        <Row className="justify-content-md-center">
            <Col className="my-3 p-3 bg-white rounded shadow-lg">
                <h4 className="text-info" ><BiSolidUserAccount size="30" /> Account Activation</h4>
                <hr/>
                <div style = {{ width:"400px"}} />
                To activate account :<br/>&nbsp; ○ Search your account number, then input the required details below.
                <Formik
                    initialValues={{ 
                        account: accountSession || '',
                    }}
                    validationSchema={SearchSchema}
                    onSubmit={handleSearch}
                    >
                    {({ errors, touched }) => (
                    <Form>
                        <Row>
                            <Col sm>
                                <FloatingLabel label="&#x1F50E; Search Account Number" className="mb-2">
                                    <Field
                                        type="text"
                                        name="account"
                                        placeholder="&#x1F50E; Search Account Number"
                                        className={`rounded w-100 form-control ${touched.account && errors.account ? 'is-invalid' : touched.account ? 'is-valid' : ''}`}
                                    />
                                    <ErrorMessage name="account">
                                        {msg => <div className="invalid-feedback">{msg}</div>}
                                    </ErrorMessage>
                                </FloatingLabel>
                                <Button hidden type="submit" variant="outline-success"><BiSearchAlt size="45" className='ms-2 me-2'/></Button>
                            </Col>
                        </Row>
                    </Form>
                    )}
                </Formik>


                <Formik
                    initialValues={{
                        fname: decryptData(fname || '', encryptionKey),
                        mi: mi || '',
                        lname: decryptData(lname || '', encryptionKey),
                        phase: phase || '',
                        block: block || '',
                        lot: lot || '',
                        pin: "",
                        cellphone: "",
                        email: "",
                        password: "",
                        confirm_password: "",
                        confirm: false,
                    }}
                    validationSchema={CreateSchema}
                    onSubmit={handleSubmit}
                    >
                    {({ errors, touched }) => (
                    <Form>
                        <Alert style={{border: accountSession?"1px solid rgb(0, 128, 0)":"1px solid rgb(128, 128, 128)", backgroundColor: '#e6ffe3'}} className='mt-2 pt-4'>
                        <Row>
                            <Col sm>
                                <FloatingLabel label="Firstname" className="mb-2">
                                    <Field
                                        type="text"
                                        name="fname"
                                        placeholder="Firstname"
                                        disabled={true}
                                        className={`w-100 form-control ${touched.fname && errors.fname ? 'is-invalid' : touched.fname ? 'is-valid' : ''}`}
                                    />
                                    <ErrorMessage name="fname">
                                        {msg => <div className="invalid-feedback">{msg}</div>}
                                    </ErrorMessage>
                                </FloatingLabel>
                            </Col>
                            <Col sm>
                                <FloatingLabel label="MI" className="mb-2">
                                    <Field
                                        type="text"
                                        name="mi"
                                        placeholder="MI"
                                        disabled={true}
                                        className={`w-100 form-control ${touched.mi && errors.mi ? 'is-invalid' : touched.mi ? 'is-valid' : ''}`}
                                    />
                                    <ErrorMessage name="mi">
                                        {msg => <div className="invalid-feedback">{msg}</div>}
                                    </ErrorMessage>
                                </FloatingLabel>
                            </Col>
                            <Col sm>
                                <FloatingLabel label="Lastname" className="mb-2">
                                    <Field
                                        type="text"
                                        name="lname"
                                        placeholder="Lastname"
                                        disabled={true}
                                        className={`w-100 form-control ${touched.lname && errors.lname ? 'is-invalid' : touched.lname ? 'is-valid' : ''}`}
                                    />
                                    <ErrorMessage name="lname">
                                        {msg => <div className="invalid-feedback">{msg}</div>}
                                    </ErrorMessage>
                                </FloatingLabel>
                            </Col>
                        </Row>

                        <Row>
                            <Col sm>
                                <FloatingLabel label="Phase" className="mb-2">
                                    <Field
                                        type="text"
                                        name="phase"
                                        placeholder="Phase"
                                        disabled={true}
                                        className={`w-100 form-control ${touched.phase && errors.phase ? 'is-invalid' : touched.phase ? 'is-valid' : ''}`}
                                    />
                                    <ErrorMessage name="phase">
                                        {msg => <div className="invalid-feedback">{msg}</div>}
                                    </ErrorMessage>
                                </FloatingLabel>
                            </Col>
                            <Col sm>
                                <FloatingLabel label="Block" className="mb-2">
                                    <Field
                                        type="text"
                                        name="block"
                                        placeholder="Block"
                                        disabled={true}
                                        className={`w-100 form-control ${touched.block && errors.block ? 'is-invalid' : touched.block ? 'is-valid' : ''}`}
                                    />
                                    <ErrorMessage name="block">
                                        {msg => <div className="invalid-feedback">{msg}</div>}
                                    </ErrorMessage>
                                </FloatingLabel>
                            </Col>
                            <Col sm>
                                <FloatingLabel label="Lot" className="mb-2">
                                    <Field
                                        type="text"
                                        name="lot"
                                        placeholder="Lot"
                                        disabled={true}
                                        className={`w-100 form-control ${touched.lot && errors.lot ? 'is-invalid' : touched.lot ? 'is-valid' : ''}`}
                                    />
                                    <ErrorMessage name="lot">
                                        {msg => <div className="invalid-feedback">{msg}</div>}
                                    </ErrorMessage>
                                </FloatingLabel>
                            </Col>
                        </Row>
                        </Alert>

                        <Row>
                            <Col sm>
                                <FloatingLabel label="PIN Code" className="mb-2">
                                    <Field
                                        type="text"
                                        name="pin"
                                        maxLength="6"
                                        placeholder="PIN Code"
                                        className={`w-100 form-control ${touched.pin && errors.pin ? 'is-invalid' : touched.pin ? 'is-valid' : ''}`}
                                    />
                                    <ErrorMessage name="pin">
                                        {msg => <div className="invalid-feedback">{msg}</div>}
                                    </ErrorMessage>
                                </FloatingLabel>
                            </Col>
                            <Col sm>
                            </Col>
                        </Row>

                        <Row>
                            <Col sm>
                                <FloatingLabel label="Cellphone" className="mb-2">
                                    <Field
                                        type="text"
                                        name="cellphone"
                                        maxLength="11"
                                        placeholder="Cellphone"
                                        className={`w-100 form-control ${touched.cellphone && errors.cellphone ? 'is-invalid' : touched.cellphone ? 'is-valid' : ''}`}
                                    />
                                    <ErrorMessage name="cellphone">
                                        {msg => <div className="invalid-feedback">{msg}</div>}
                                    </ErrorMessage>
                                </FloatingLabel>
                            </Col>
                            <Col sm>
                            </Col>
                        </Row>

                        <Row>
                            <Col sm>
                                <FloatingLabel label="E-mail" className="mb-2">
                                    <Field
                                        type="email"
                                        name="email"
                                        placeholder="E-mail"
                                        className={`w-100 form-control ${touched.email && errors.email ? 'is-invalid' : touched.email ? 'is-valid' : ''}`}
                                    />
                                    <ErrorMessage name="email">
                                        {msg => <div className="invalid-feedback">{msg}</div>}
                                    </ErrorMessage>
                                </FloatingLabel>
                            </Col>
                            <Col sm>
                            </Col>
                        </Row>

                        <Row>
                            <Col sm>
                                <FloatingLabel label="Password" className="mb-2">
                                    <Field
                                        type={showPassword ? 'text' : 'password'} // Toggle between text and password
                                        name="password"
                                        placeholder="Password"
                                        className={`w-100 form-control ${touched.password && errors.password ? 'is-invalid' : touched.password ? 'is-valid' : ''}`}
                                    />
                                        <span 
                                            onClick={togglePasswordVisibility}
                                            style={{
                                                position: 'absolute',
                                                right: '30px',
                                                top: '50%',
                                                transform: 'translateY(-50%)',
                                                cursor: 'pointer',
                                                color: '#6c757d' // Optional: adjust color as needed
                                            }}
                                            >
                                            {showPassword ? <PiEyeSlash size='25' /> : <PiEyeLight size='25' />}
                                        </span>
                                    <ErrorMessage name="password">
                                        {msg => <div className="invalid-feedback">{msg}</div>}
                                    </ErrorMessage>
                                </FloatingLabel>
                            </Col>
                            <Col sm>
                                <FloatingLabel label="Confirm Password" className="mb-2">
                                    <Field
                                        type={showConfirmPassword ? 'text' : 'password'} // Toggle between text and password
                                        name="confirm_password"
                                        placeholder="Confirm Password"
                                        className={`w-100 form-control ${touched.confirm_password && errors.confirm_password ? 'is-invalid' : touched.confirm_password ? 'is-valid' : ''}`}
                                    />
                                        <span 
                                            onClick={toggleConfirmPasswordVisibility}
                                            style={{
                                                position: 'absolute',
                                                right: '30px',
                                                top: '50%',
                                                transform: 'translateY(-50%)',
                                                cursor: 'pointer',
                                                color: '#6c757d' // Optional: adjust color as needed
                                            }}
                                            >
                                            {showConfirmPassword ? <PiEyeSlash size='25' /> : <PiEyeLight size='25' />}
                                        </span>
                                    <ErrorMessage name="confirm_password">
                                        {msg => <div className="invalid-feedback">{msg}</div>}
                                    </ErrorMessage>
                                </FloatingLabel>
                            </Col>
                        </Row>

                        <br/>
                            <Col sm> 
                                
                                <label>
                                    <Field type="checkbox" name="confirm" />
                                    &nbsp;Please confirm to activate your account.
                                    <Button variant="link">Terms and Conditions</Button>
                                </label>
                                <ErrorMessage name="confirm">
                                    {msg => <div style={{color:'red',padding:'5px'}}>{msg}</div>}
                                </ErrorMessage>
                            </Col>
                        <br/>
                        <div className="d-grid gap-2">
                            <Button variant="primary" type="submit" className="btn btn-primary btn-block rounded-pill mb-5" >
                                <AiOutlineSend size="20"/> Activate
                            </Button>
                        </div>
                    </Form>
                    )}
                </Formik>
            </Col>
        </Row>
    </Container>
  );
}

export default Activation;