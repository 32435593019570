import React, { useEffect } from 'react';
import { 
    Row, 
    Col,
    Card,
} from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { AiOutlineGroup, AiOutlineDelete, AiOutlineUnorderedList } from "react-icons/ai";
import { BsClipboard2Plus, BsClipboard2Minus } from "react-icons/bs";
import { SlDocs, SlDoc } from "react-icons/sl";
import { MdOutlineDiscount } from "react-icons/md";
import { LuClipboardList } from "react-icons/lu";
import SubMenu from '../submenu';


const BillMenu: React.FC = () =>{
    useEffect(() => {
        const keys = Object.keys(sessionStorage);
    
        keys.forEach((key) => {
          if (key !== 'setSelectedItem') {
            sessionStorage.removeItem(key);
          }
        });
      }, []);
      
    return (
        <div className="container-fluid ">
            <Row className="justify-content-md-center">
                <Col sm={2}>
                    <SubMenu/>
                </Col>
                <Col xs lg="10"  className="p-3 bg-white rounded shadow-sm">
                    <h4 className="text-success" ><AiOutlineGroup size="30" /> Association Bill Menu</h4>
                    <hr/>
                    <div style = {{ width:"400px"}} ></div>
                    <Row xs={1} md={4} className="g-4">
                        <Col>
                            <Link to="/admin_association_single" style={{ textDecoration: 'none'}}>
                                <Card className="mb-3 rounded shadow-lg justify-content-between align-items-center text-center bg-success bg-opacity-75">
                                    <Card.Body >
                                        <Card.Title className='text-white'>
                                            <div className="text-center w-100"><SlDoc size="90" /></div><br/>Single Entry
                                        </Card.Title>
                                    </Card.Body>
                                </Card>
                            </Link>
                        </Col>
                        <Col>
                            <Link to="/admin_generate_association" style={{ textDecoration: 'none'}}>
                                <Card className="mb-3 rounded shadow-lg justify-content-between align-items-center bg-success bg-opacity-75">
                                    <Card.Body >
                                        <Card.Title className='text-white'>
                                            <div className="text-center w-100"><SlDocs size="90" /></div><br/>Generate Bills
                                        </Card.Title>
                                    </Card.Body>
                                </Card>
                            </Link>
                        </Col>
                        <Col>
                            <Link to="/admin_association_credit_memo" style={{ textDecoration: 'none'}}>
                                <Card className="mb-3 rounded shadow-lg justify-content-between align-items-center bg-success bg-opacity-75">
                                    <Card.Body >
                                        <Card.Title className='text-white'>
                                            <div className="text-center w-100"><BsClipboard2Minus  size="90" /></div><br/>Credit Memo
                                        </Card.Title>
                                    </Card.Body>
                                </Card>
                            </Link>
                        </Col>
                        <Col>
                            <Link to="/admin_association_debit_memo" style={{ textDecoration: 'none'}}>
                                <Card className="mb-3 rounded shadow-lg justify-content-between align-items-center text-center bg-success bg-opacity-75">
                                    <Card.Body >
                                        <Card.Title className='text-white'>
                                            <div className="text-center w-100"><BsClipboard2Plus size="90" /></div><br/>Debit Memo
                                        </Card.Title>
                                    </Card.Body>
                                </Card>
                            </Link>
                        </Col>
                        <Col>
                            <Link to="/admin_discount_list" style={{ textDecoration: 'none'}}>
                                <Card className="mb-3 rounded shadow-lg justify-content-between align-items-center text-center bg-success bg-opacity-75">
                                    <Card.Body >
                                        <Card.Title className='text-white'>
                                            <div className="text-center w-100"><MdOutlineDiscount size="90" /></div><br/>Discount
                                        </Card.Title>
                                    </Card.Body>
                                </Card>
                            </Link>
                        </Col>
                        <Col>
                            <Link to="/admin_association_list" style={{ textDecoration: 'none'}}>
                                <Card className="mb-3 rounded shadow-lg justify-content-between align-items-center text-center bg-success bg-opacity-75">
                                    <Card.Body >
                                        <Card.Title className='text-white'><AiOutlineUnorderedList  size="90" /><br/><br/>Bill List</Card.Title>
                                    </Card.Body>
                                </Card>
                            </Link>
                        </Col>
                        <Col>
                            <Link to="/admin_adjustment_list" style={{ textDecoration: 'none'}}>
                                <Card className="mb-3 rounded shadow-lg justify-content-between align-items-center text-center bg-success bg-opacity-75">
                                    <Card.Body >
                                    <Card.Title className='text-white'><LuClipboardList  size="90" /><br/><br/>Adjustment List</Card.Title>
                                    </Card.Body>
                                </Card>
                            </Link>
                        </Col>
                        <Col>
                            <Link to="/bills_ad_searchactivateuser" style={{ textDecoration: 'none'}}>
                                <Card className="mb-3 rounded shadow-lg justify-content-between align-items-center text-center bg-success bg-opacity-75">
                                    <Card.Body >
                                        <Card.Title className='text-white'>
                                            <div className="text-center w-100"> <AiOutlineDelete size="90" /></div><br/>Delete
                                        </Card.Title>
                                    </Card.Body>
                                </Card>
                            </Link>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </div>
    );
};

export default BillMenu;