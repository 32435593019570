import React, { useState, useEffect, useCallback } from 'react';
import axios, { AxiosResponse } from 'axios';
import { 
    Row, 
    Col,
    InputGroup,
    Button,
    Alert,
    Breadcrumb,
} from 'react-bootstrap';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { AiOutlineSend } from "react-icons/ai";
import { BsClipboard2Plus } from "react-icons/bs";
import { BiSearchAlt } from "react-icons/bi";
import SubMenu from '../submenu';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { setSessionVariable, getSessionVariable } from '../../../components/sessionStorage';
import { AuthAccessFailed } from '../../../components/loader';
import { getLocalStorageVariable } from '../../../components/localStorage';
import apiUrl from '../../../components/apiurl';
import { debitMemoRequest, associationSearchRequest } from './redux/adminAssociationActions';
import { RootState } from '../../../redux/reducers';
import { LoadingPage, CreateCreditMemoFailed, CreateCreditMemoSuccess, SearchAccountFailed } from '../../../components/loader';

  
const SearchSchema = Yup.object().shape({
    search: Yup.string()
        .trim()
        .max(10, 'Must be 10 characters or less')
        .required("Account # is required"),
    memo_no: Yup.string()
        .trim()
        .max(10, 'Must be 10 characters or less')
        .required("Memo # is required"),
  });

const CreateSchema = Yup.object().shape({
    fname: Yup.string()
        .trim()
        .max(30, 'Must be 30 characters or less')
        .required("Firstname is required"),
    mi: Yup.string()
        .trim()
        .max(1, 'Must be 1 character only')
        .matches(/^([a-z\s A-Z])+$/, "Valid characters from A-Z only"),
    lname: Yup.string()
        .trim()
        .max(30, 'Must be 30 characters or less')
        .required("Lastname is required."),
    phase: Yup.string()
        .trim()
        .required("Phase is required."),
    block: Yup.string()
        .trim()
        .matches(/^\d+$/, "Please input valid numbers.")
        .required("Block number is required."),
    lot: Yup.string()
        .trim()
        .matches(/^\d+$/, "Please input valid numbers.")
        .required("Lot number is required."),
    date: Yup.string()
        .required("Date is required")
        .test('isValidDate', 'Invalid date', function (value) {
            if (!value) {
              return true;
            }
            const parsedDate = new Date(value);
            return !isNaN(parsedDate.getTime());
          }),
    total_amount: Yup.string()
        .trim()
        .max(20, 'Must be 20 characters or less')
        // .matches(/^\d*\.?\d*$/, "Please input valid numbers.")
        .test('is-not-negative', 'Amount cannot be negative value or invalid.', value => {
            if (typeof value === 'string') {
              const numericValue = parseFloat(value);
              return !isNaN(numericValue) && numericValue >= 0;
            }
            return false; 
          })
        .required("Amount to apply is required."),
    memo: Yup.string()
        .trim()
        .max(100, 'Must be 100 characters or less')
        .required("Memo is required."),
    confirm: Yup
        .bool()
        .oneOf([true], "Please confirm.")
        .required("Please confirm."),
  });

  interface SystemData {
    user_acct_no: number;
    charge_amount: number;
    association_no: number;
    memo_no: number;
  }

const Debit_Memo: React.FC = () =>{
    const loading = useSelector((state: RootState) => state.Admin_AssociationReducer.loading);
    const success = useSelector((state: RootState) => state.Admin_AssociationReducer.redirectPath);
    const failed = useSelector((state: RootState) => state.Admin_AssociationReducer.error);
    const searchfailed = useSelector((state: RootState) => state.Admin_AssociationReducer.searcherror);
    const token = getLocalStorageVariable<string>('token');
    const [systemData, setSystemData] = useState<SystemData[] | null>(null);
    const [creditamount, setCreditAmount] = useState<any>(0);
    const [memo, setMemo] = useState<any>('');
    const [cDate, setCDate] = useState('');
    const user_id = getSessionVariable<any>('user_id');
    const accountSession = getSessionVariable<any>('account');
    const fname = getSessionVariable<string>('fname');
    const mi = getSessionVariable<string>('mi');
    const lname = getSessionVariable<string>('lname');
    const phase = getSessionVariable<string>('phase');
    const block = getSessionVariable<string>('block');
    const lot = getSessionVariable<string>('lot');
    const id = '1';
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const association_create = getLocalStorageVariable<string>('association_create');

    // ***** Fetch System Data *****
    const fetchSystemData = useCallback(async () => {
        const headers = {
            Authorization: `${token}`,
          };
        try {
            const res: AxiosResponse<SystemData[]> = await axios.post(`${apiUrl.url}admin_get_system_account/`, { id: id }, { headers });
            setSystemData(res.data);
        } catch (error) {
            console.error('Error: ', error);
            return false;
        }
    }, [id, token]);

    useEffect(() => {
        fetchSystemData();
    }, [ fetchSystemData ]);


    // **** Set current date ******
    useEffect(() => {
        const currentDate = new Date();
        const formattedDate = formatDate(currentDate);
        setCDate(formattedDate);
    }, []); 

    const formatDate = (date: Date): string => {
        const year = date.getFullYear();
        const month = (date.getMonth() + 1).toString().padStart(2, '0'); 
        const day = date.getDate().toString().padStart(2, '0');

        return `${year}-${month}-${day}`;
    };

    // **** Total Amount on blur ******
    const handleAmountBlur = (value: string) => {
        setCreditAmount(parseFloat(value));
      };
    
    const handleMemoBlur = (value: string) => {
        setMemo(value);
      };

    const handleDateChange = (event: any) => {
        const newDate = event.target.value;
            setCDate(newDate);
      };

    const handleSearch = (values: any) => {
        const { search } = values;
        dispatch(associationSearchRequest(search));
      };
    
    const handleItemClick = (path: string) => {
        setSessionVariable('setSelectedItem', path);
        navigate(path);
    };

    const handleSubmit = (values: any) => {
        const totalAmountWithoutCommas = values.total_amount.replace(/,/g, '');
        const payload = {
            memo_no: systemData ? Number(systemData[0]?.memo_no) : 0,
            type: 'debit',
            user_id: user_id,
            account: accountSession,
            fname: values.fname,
            mi: values.mi,
            lname: values.lname,
            phase: values.phase,
            block: values.block,
            lot: values.lot,
            date: values.date,
            total_amount: parseFloat(totalAmountWithoutCommas),
            memo: values.memo,
        };
        dispatch(debitMemoRequest(payload));
    };

    return (
        <div>
            {loading?<LoadingPage/>:""}
            {success?<CreateCreditMemoSuccess/>:""}
            {failed?<CreateCreditMemoFailed/>:""}
            {searchfailed?<SearchAccountFailed/>:""}

            <div className="container-fluid ">
                {association_create != '1'? <AuthAccessFailed />:""}

                <Row className="justify-content-md-center">
                    <Col>
                        <SubMenu/>
                    </Col>
                    <Col xs lg="10"  className="p-3 bg-white rounded shadow-sm ">
                        <h4 className="text-success" ><BsClipboard2Plus size="30" /> Debit Memo</h4>
                            <Breadcrumb>
                                <Breadcrumb.Item onClick={() => handleItemClick('/admin_association_menu')}>Association Menu</Breadcrumb.Item>
                                <Breadcrumb.Item active>Debit Memo</Breadcrumb.Item>
                            </Breadcrumb>
                            <hr/>
                        <div style = {{ width:"400px"}} />
                        Debit Memo - debit adjustment made to individual user account
                        <Alert style={{border: "1px solid rgb(128, 128, 128)", backgroundColor: '#e6ffe3'}} className='mt-2 shadow-lg'>
                            <Formik
                                enableReinitialize={true}
                                initialValues={{ 
                                    search: accountSession || '',
                                    memo_no: systemData ? Number(systemData[0]?.memo_no) : "",
                                }}
                                validationSchema={SearchSchema}
                                onSubmit={handleSearch}
                                >
                                <Form>
                                    <Row>
                                        <Col sm>   
                                            <InputGroup className="mt-1 mb-1">
                                                <InputGroup className="w-25">
                                                    <InputGroup.Text className="w-100">
                                                        Account # 
                                                    </InputGroup.Text>
                                                </InputGroup>
                                                <Field 
                                                    type="text" 
                                                    name="search" 
                                                    maxLength="10"
                                                    placeholder="Account #"
                                                    className="w-50 form-control"
                                                />
                                                <Button type="submit" variant="outline-success"><BiSearchAlt size="20" className='ms-2 me-2'/></Button>
                                                <ErrorMessage name="search">
                                                    {msg => <div style={{color:'red',padding:'5px'}}>{msg}</div>}
                                                </ErrorMessage>
                                            </InputGroup>
                                        </Col>
                                        <Col sm>   
                                            <InputGroup className="mt-1 mb-1">
                                                <InputGroup className="w-25">
                                                    <InputGroup.Text className="w-100">
                                                        Memo #
                                                    </InputGroup.Text>
                                                </InputGroup>
                                                <Field 
                                                    type="text" 
                                                    name="memo_no" 
                                                    maxLength="10"
                                                    placeholder="Credit Memo"
                                                    className="w-75 form-control"
                                                    disabled={true}
                                                />
                                                <ErrorMessage name="memo_no">
                                                    {msg => <div style={{color:'red',padding:'5px'}}>{msg}</div>}
                                                </ErrorMessage>
                                            </InputGroup>
                                        </Col>
                                        <Col sm> </Col>
                                    </Row>
                                </Form>
                            </Formik>
                            
                            <Formik
                                enableReinitialize={true}
                                initialValues={{
                                    fname: fname || '',
                                    mi: mi || '',
                                    lname: lname || '',
                                    phase: phase || '',
                                    block: block || '',
                                    lot: lot || '',
                                    date: cDate,
                                    total_amount: creditamount.toLocaleString("en-US", { maximumFractionDigits: 2, minimumFractionDigits: 2 }),
                                    memo: memo,
                                    confirm: false,
                                }}
                                validationSchema={CreateSchema}
                                onSubmit={handleSubmit}
                                >
                                <Form>
                                    <Alert style={{border: "1px solid rgb(128, 128, 128)"}} className='mt-2 bg-light'>
                                    <Row>
                                        <Col sm>   
                                            <InputGroup className="mt-1 mb-1">
                                                <InputGroup className="w-25">
                                                    <InputGroup.Text className="w-100">
                                                        Firstname 
                                                    </InputGroup.Text>
                                                </InputGroup>
                                                <Field 
                                                    type="text" 
                                                    name="fname"
                                                    maxLength="30"
                                                    className="w-75 form-control"
                                                    disabled={true}
                                                />
                                                <ErrorMessage name="fname">
                                                    {msg => <div style={{color:'red',padding:'5px'}}>{msg}</div>}
                                                </ErrorMessage>
                                            </InputGroup>
                                        </Col>
                                        <Col sm>   
                                            <InputGroup className="mt-1 mb-1">
                                                <InputGroup className="w-25">
                                                    <InputGroup.Text className="w-100">
                                                        MI
                                                    </InputGroup.Text>
                                                </InputGroup>
                                                <Field 
                                                    type="text" 
                                                    name="mi" 
                                                    maxLength="1"
                                                    className="w-75 form-control"
                                                    disabled={true}
                                                />
                                                <ErrorMessage name="mi">
                                                    {msg => <div style={{color:'red',padding:'5px'}}>{msg}</div>}
                                                </ErrorMessage>
                                            </InputGroup>
                                        </Col>
                                        <Col sm>   
                                            <InputGroup className="mt-1 mb-1">
                                                <InputGroup className="w-25">
                                                    <InputGroup.Text className="w-100">
                                                        Lastname 
                                                    </InputGroup.Text>
                                                </InputGroup>
                                                <Field 
                                                    type="text" 
                                                    name="lname" 
                                                    maxLength="30"
                                                    className="w-75 form-control"
                                                    disabled={true}
                                                />
                                                <ErrorMessage name="lname">
                                                    {msg => <div style={{color:'red',padding:'5px'}}>{msg}</div>}
                                                </ErrorMessage>
                                            </InputGroup>
                                        </Col>
                                    </Row>

                                    <Row>
                                        <Col sm>   
                                            <InputGroup className="mt-1 mb-1">
                                                <InputGroup className="w-25">
                                                    <InputGroup.Text className="w-100">
                                                        Phase
                                                    </InputGroup.Text>
                                                </InputGroup>
                                                <Field 
                                                    type="text" 
                                                    name="phase" 
                                                    maxLength="5"
                                                    className="w-75 form-control"
                                                    disabled={true}
                                                />
                                                <ErrorMessage name="phase">
                                                    {msg => <div style={{color:'red',padding:'5px'}}>{msg}</div>}
                                                </ErrorMessage>
                                            </InputGroup>
                                        </Col>
                                        <Col sm>   
                                            <InputGroup className="mt-1 mb-1">
                                                <InputGroup className="w-25">
                                                    <InputGroup.Text className="w-100">
                                                        Block 
                                                    </InputGroup.Text>
                                                </InputGroup>
                                                <Field 
                                                    type="text" 
                                                    name="block" 
                                                    maxLength="5"
                                                    className="w-75 form-control"
                                                    disabled={true}
                                                />
                                                <ErrorMessage name="block">
                                                    {msg => <div style={{color:'red',padding:'5px'}}>{msg}</div>}
                                                </ErrorMessage>
                                            </InputGroup>
                                        </Col>
                                        <Col sm>   
                                            <InputGroup className="mt-1 mb-1">
                                                <InputGroup className="w-25">
                                                    <InputGroup.Text className="w-100">
                                                        Lot 
                                                    </InputGroup.Text>
                                                </InputGroup>
                                                <Field 
                                                    type="text" 
                                                    name="lot" 
                                                    maxLength="5"
                                                    className="w-75 form-control"
                                                    disabled={true}
                                                />
                                                <ErrorMessage name="lot">
                                                    {msg => <div style={{color:'red',padding:'5px'}}>{msg}</div>}
                                                </ErrorMessage>
                                            </InputGroup>
                                        </Col>
                                    </Row>
                                </Alert>

                                    <Row>
                                        <Col sm>   
                                            <InputGroup className="mt-1 mb-1">
                                                <InputGroup className="w-25">
                                                    <InputGroup.Text className="w-100">
                                                        Date <strong className='text-danger'>&nbsp;*</strong>
                                                    </InputGroup.Text>
                                                </InputGroup>
                                                <Field 
                                                    type="date" 
                                                    name="date"
                                                    className="w-75 form-control text-center"
                                                    onChange={(event:any) => handleDateChange(event)}
                                                />
                                                <ErrorMessage name="date">
                                                    {msg => <div style={{color:'red',padding:'5px'}}>{msg}</div>}
                                                </ErrorMessage>
                                            </InputGroup>
                                        </Col>
                                        <Col sm> </Col>
                                        <Col sm> </Col>
                                    </Row>
                                    <Row>
                                        <Col sm>   
                                            <InputGroup className="mt-1 mb-1">
                                                <InputGroup className="w-25">
                                                    <InputGroup.Text className="w-100">
                                                        Amount <strong className='text-danger'>&nbsp;*</strong>
                                                    </InputGroup.Text>
                                                </InputGroup>
                                                <Field 
                                                    type="text" 
                                                    name="total_amount" 
                                                    maxLength="11"
                                                    placeholder="0.00"
                                                    className="w-75 form-control text-end"
                                                    onBlur={(e: any) => handleAmountBlur(e.target.value)}
                                                />
                                                <ErrorMessage name="total_amount">
                                                    {msg => <div style={{color:'red',padding:'5px'}}>{msg}</div>}
                                                </ErrorMessage>
                                            </InputGroup>
                                        </Col>
                                        <Col sm> </Col>
                                        <Col sm> </Col>
                                    </Row>

                                    <Row>
                                        <Col sm={10}>   
                                            <InputGroup className="mt-1 mb-1">
                                                <InputGroup className="w-25">
                                                    <InputGroup.Text className="w-100">
                                                        Remarks <strong className='text-danger'>&nbsp;*</strong>
                                                    </InputGroup.Text>
                                                </InputGroup>
                                                <Field 
                                                    type="text" 
                                                    name="memo" 
                                                    maxLength="100"
                                                    placeholder="Memo"
                                                    className="w-75 form-control"
                                                    onBlur={(e: any) => handleMemoBlur(e.target.value)}
                                                />
                                                <ErrorMessage name="memo">
                                                    {msg => <div style={{color:'red',padding:'5px'}}>{msg}</div>}
                                                </ErrorMessage>
                                            </InputGroup>
                                        </Col>
                                        <Col sm> </Col>
                                    </Row>

                                    <br/>
                                        <Col sm> 
                                            <label>
                                                <Field type="checkbox" name="confirm" />
                                                &nbsp;Please confirm to save debit memo.
                                            </label>
                                            <ErrorMessage name="confirm">
                                                {msg => <div style={{color:'red',padding:'5px'}}>{msg}</div>}
                                            </ErrorMessage>
                                        </Col>
                                    <br/>
                                    <div className="d-grid gap-2">
                                        <Button variant="primary" type="submit" className="btn btn-primary btn-block rounded-pill mb-3" >
                                            <AiOutlineSend size="20"/> Save
                                        </Button>
                                    </div>
                                </Form>
                            </Formik>
                        </Alert>
                    </Col>
                </Row>
            </div>
        </div>
    );
};

export default Debit_Memo;