import React, { useEffect, useState, useRef } from 'react';
import ReactToPrint from 'react-to-print';
import { Row, Col, Breadcrumb, Alert } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState } from '../../../redux/reducers';
import { AiOutlineUnorderedList } from "react-icons/ai";
import { FaPrint } from "react-icons/fa";
import SubMenu from '../submenu';
import { getSessionVariable } from '../../../components/sessionStorage';
import { getLocalStorageVariable } from '../../../components/localStorage';
import { LoadingPage, AuthAccessFailed } from '../../../components/loader';

const User_View: React.FC = () => {
    const failed = useSelector((state: RootState) => state.Admin_CreateUserReducer.error);
    const loading = useSelector((state: RootState) => state.Admin_CreateUserReducer.loading);
    const user_update = getLocalStorageVariable<string>('user_update');
    const navigate = useNavigate();
    const [isPrinting, setIsPrinting] = useState(false);
    const printAllRef = useRef(null);

    const account = getSessionVariable<any>('account');
    const fname = getSessionVariable<any>('fname');
    const mi = getSessionVariable<any>('mi');
    const lname = getSessionVariable<any>('lname');
    const phase = getSessionVariable<any>('phase');
    const block = getSessionVariable<any>('block');
    const lot = getSessionVariable<any>('lot');
    const lotarea = getSessionVariable<any>('lotarea');
    const floorarea = getSessionVariable<any>('floorarea');
    const dues = getSessionVariable<any>('dues');
    const status = getSessionVariable<any>('status');
    const association_total = getSessionVariable<any>('association_total');
    const association_debit = getSessionVariable<any>('association_debit');
    const association_credit = getSessionVariable<any>('association_credit');
    const association_balance = getSessionVariable<any>('association_balance');
    const water_total = getSessionVariable<any>('water_total');
    const water_debit = getSessionVariable<any>('water_debit');
    const water_credit = getSessionVariable<any>('water_credit');
    const water_balance = getSessionVariable<any>('water_balance');
    const total_payment = getSessionVariable<any>('total_payment');

    useEffect(() => {
        if (!account) {
            navigate('/admin_update_user_list'); 
        }
    }, [navigate, account]);

    const handleItemClick = (path: string) => {
        navigate(path);
    };

    // ***** Print Per User *****
    const handlePrint = () => {
        setIsPrinting(true);
        // Open a new window and write the content to be printed
        const printWindow = window.open('', '_blank');
        printWindow?.document.write('<html><head><title>Print</title></head><body>');
        printWindow?.document.write((printableContent as any).outerHTML);
        printWindow?.document.write('</body></html>');
        printWindow?.document.close();

        // Trigger the print
        printWindow?.print();
        setIsPrinting(false);     
    }
    const printableContent = (
        <div>
            <div style={{ textAlign: 'center', marginBottom: '20px' }}>
                <h3>Account Details</h3>
            </div>
            <div style={{ padding: '10px', border: '1px solid #ddd', borderRadius: '5px' }} className='mb-3'>
                <Row className="mb-2">
                    <Col><strong>Account ID:</strong></Col>
                    <Col>{account}</Col>
                </Row>
                <Row className="mb-2">
                    <Col><strong>Name:</strong></Col>
                    <Col>{fname} {mi}. {lname}</Col>
                </Row>
                
                <Row className="mb-2">
                    <Col><strong>Address:</strong></Col>
                    <Col>Phase {phase} Block {block} Lot {lot}</Col>
                </Row>
                <Row className="mb-2">
                    <Col><strong>Status:</strong></Col>
                    <Col>{status === 1 ? <strong className='text-success'>Active</strong> : <strong className='text-danger'>Inactive</strong>}
                    </Col>
                </Row>
            </div>
            <div style={{ padding: '10px', border: '1px solid #ddd', borderRadius: '5px' }}>
                <Row className="mb-2">
                    <Col><strong>Lot Area (sq.mt):</strong></Col>
                    <Col>{lotarea}</Col>
                </Row>
                <Row className="mb-2">
                    <Col><strong>Floor Area (sq.mt):</strong></Col>
                    <Col>{floorarea}</Col>
                </Row>
                <Row className="mb-2">
                    <Col><strong>Monthly Dues:</strong></Col>
                    <Col>₱ &emsp;{(dues).toLocaleString("en-US", { maximumFractionDigits: 2, minimumFractionDigits: 2})}</Col>
                </Row>
                <Row className="mb-2">
                    <Col><strong>Total Association Due:</strong></Col>
                    <Col>₱ &emsp;{(association_total).toLocaleString("en-US", { maximumFractionDigits: 2, minimumFractionDigits: 2})}</Col>
                </Row>
                <Row className="mb-2">
                    <Col><strong>Total Association Balance:</strong></Col>
                    <Col>₱ &emsp;{(association_balance).toLocaleString("en-US", { maximumFractionDigits: 2, minimumFractionDigits: 2})}</Col>
                </Row>
                <Row className="mb-2">
                    <Col><strong>Total Association Adjustment:</strong></Col>
                    <Col>₱ &emsp;{(association_debit - association_credit).toLocaleString("en-US", { maximumFractionDigits: 2, minimumFractionDigits: 2})}</Col>
                </Row>
                <Row className="mb-2">
                    <Col><strong>Total Water Bill:</strong></Col>
                    <Col>₱ &emsp;{(water_total).toLocaleString("en-US", { maximumFractionDigits: 2, minimumFractionDigits: 2})}</Col>
                </Row>
                <Row className="mb-2">
                    <Col><strong>Total Water Bill Balance:</strong></Col>
                    <Col>₱ &emsp;{(water_balance).toLocaleString("en-US", { maximumFractionDigits: 2, minimumFractionDigits: 2})}</Col>
                </Row>
                <Row className="mb-2">
                    <Col><strong>Total Water Bill Adjustment:</strong></Col>
                    <Col>₱ &emsp;{(water_debit - water_credit).toLocaleString("en-US", { maximumFractionDigits: 2, minimumFractionDigits: 2})}</Col>
                </Row>

                <Row className="mb-2">
                    <Col><strong>Total Payment:</strong></Col>
                    <Col>₱ &emsp;{(total_payment).toLocaleString("en-US", { maximumFractionDigits: 2, minimumFractionDigits: 2})}</Col>
                </Row>
                <hr/>
                <Row className="mb-2">
                    <Col><strong>Total Balance:</strong></Col>
                    <Col><strong>₱ &emsp;{(association_balance + water_balance).toLocaleString("en-US", { maximumFractionDigits: 2, minimumFractionDigits: 2})}</strong></Col>
                </Row>
                <style>
                    {`
                    @media print {
                        body {
                            margin: 0;
                        }
                        .print-content {
                        }
                        @page {
                            size: A4;
                            margin: 10mm;
                        }
                    }
                    `}
                </style>
            </div>
        </div>
    )

    return (
        <div className="container-fluid">
            {loading ? <LoadingPage /> : ""}
            {user_update != '1' ? <AuthAccessFailed /> : ""}

            <Row className="justify-content-md-center">
                <Col>
                    <SubMenu />
                </Col>
                <Col xs lg="10" className="p-3 bg-white rounded shadow-sm">
                    <h4 className="text-primary mb-3"><AiOutlineUnorderedList size="30" /> User View</h4>
                    <Breadcrumb>
                        <Breadcrumb.Item onClick={() => handleItemClick('/admin_user_menu')}>User Menu</Breadcrumb.Item>
                        <Breadcrumb.Item onClick={() => handleItemClick('/admin_user_list')}>User List</Breadcrumb.Item>
                        <Breadcrumb.Item active>User Details</Breadcrumb.Item>
                    </Breadcrumb>
                    
                    <hr />
                    <div style = {{ width:"400px"}} />
                    <Row>
                        <Col lg={6} sm>
                        </Col>
                        <Col lg="6" className=''>
                            <div className="btn-toolbar mb-2 mb-md-0 float-end">
                                <div className="btn-group mr-2">
                                    
                                    <ReactToPrint
                                        trigger={() => <button type="button" onClick={handlePrint} className="btn btn-md btn-outline-success"><FaPrint size="20"/> Print</button>}
                                        content={() => printAllRef.current}
                                    />
                                </div>
                                <div style={{ display: 'none' }}>
                                    <div ref={printAllRef} >
                                        {isPrinting ? null : printableContent}
                                    </div>
                                </div>
                            </div>
                        </Col>
                    </Row>

                    <Alert style={{ border: "1px solid #808080", backgroundColor: '#fff' }} className="mt-2 shadow">
                        <div style={{ textAlign: 'center', marginBottom: '20px' }}>
                            <h3>Account Details</h3>
                        </div>
                        <div style={{ padding: '10px', border: '1px solid #ddd', borderRadius: '5px' }} className='mb-3'>
                            <Row className="mb-2">
                                <Col><strong>Account ID:</strong></Col>
                                <Col>{account}</Col>
                            </Row>
                            <Row className="mb-2">
                                <Col><strong>Name:</strong></Col>
                                <Col>{fname} {mi}. {lname}</Col>
                            </Row>
                            
                            <Row className="mb-2">
                                <Col><strong>Address:</strong></Col>
                                <Col>Phase {phase} Block {block} Lot {lot}</Col>
                            </Row>
                            <Row className="mb-2">
                                <Col><strong>Status:</strong></Col>
                                <Col>{status === 1 ? <strong className='text-success'>Active</strong> : <strong className='text-danger'>Inactive</strong>}
                                </Col>
                            </Row>
                        </div>
                        <div style={{ padding: '10px', border: '1px solid #ddd', borderRadius: '5px' }}>
                            <Row className="mb-2">
                                <Col><strong>Lot Area (sq.mt):</strong></Col>
                                <Col>{lotarea}</Col>
                            </Row>
                            <Row className="mb-2">
                                <Col><strong>Floor Area (sq.mt):</strong></Col>
                                <Col>{floorarea}</Col>
                            </Row>
                            <Row className="mb-2">
                                <Col><strong>Monthly Dues:</strong></Col>
                                <Col>₱ &emsp;{(dues).toLocaleString("en-US", { maximumFractionDigits: 2, minimumFractionDigits: 2})}</Col>
                            </Row>
                            <Row className="mb-2">
                                <Col><strong>Total Association Due:</strong></Col>
                                <Col>₱ &emsp;{(association_total).toLocaleString("en-US", { maximumFractionDigits: 2, minimumFractionDigits: 2})}</Col>
                            </Row>
                            <Row className="mb-2">
                                <Col><strong>Total Association Balance:</strong></Col>
                                <Col>₱ &emsp;{(association_balance).toLocaleString("en-US", { maximumFractionDigits: 2, minimumFractionDigits: 2})}</Col>
                            </Row>
                            <Row className="mb-2">
                                <Col><strong>Total Association Adjustment:</strong></Col>
                                <Col>₱ &emsp;{(association_debit - association_credit).toLocaleString("en-US", { maximumFractionDigits: 2, minimumFractionDigits: 2})}</Col>
                            </Row>
                            <Row className="mb-2">
                                <Col><strong>Total Water Bill:</strong></Col>
                                <Col>₱ &emsp;{(water_total).toLocaleString("en-US", { maximumFractionDigits: 2, minimumFractionDigits: 2})}</Col>
                            </Row>
                            <Row className="mb-2">
                                <Col><strong>Total Water Bill Balance:</strong></Col>
                                <Col>₱ &emsp;{(water_balance).toLocaleString("en-US", { maximumFractionDigits: 2, minimumFractionDigits: 2})}</Col>
                            </Row>
                            <Row className="mb-2">
                                <Col><strong>Total Water Bill Adjustment:</strong></Col>
                                <Col>₱ &emsp;{(water_debit - water_credit).toLocaleString("en-US", { maximumFractionDigits: 2, minimumFractionDigits: 2})}</Col>
                            </Row>

                            <Row className="mb-2">
                                <Col><strong>Total Payment:</strong></Col>
                                <Col>₱ &emsp;{(total_payment).toLocaleString("en-US", { maximumFractionDigits: 2, minimumFractionDigits: 2})}</Col>
                            </Row>
                            <hr/>
                            <Row className="mb-2">
                                <Col><strong>Total Balance:</strong></Col>
                                <Col><strong>₱ &emsp;{(association_balance + water_balance).toLocaleString("en-US", { maximumFractionDigits: 2, minimumFractionDigits: 2})}</strong></Col>
                            </Row>
                            
                        </div>
                    </Alert>
                </Col>
            </Row>
        </div>
    );
};

export default User_View;
