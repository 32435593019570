import React from 'react';
import { 
    Row, 
    Col,
    Card,
} from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { AiOutlineFileDone, AiOutlineDollar, AiOutlineDelete, AiOutlineForm, AiOutlineUnorderedList, AiOutlineTool } from "react-icons/ai";
import SubMenu from '../submenu';


const PaymentMenu: React.FC = () =>{
    return (
        <div className="container-fluid ">
            <Row className="justify-content-md-center">
                <Col sm={2}>
                    <SubMenu/>
                </Col>
                <Col xs lg="10"  className="p-3 bg-white rounded shadow-sm">
                    <h4 className="text-danger" ><AiOutlineDollar size="30" /> Payment Menu</h4>
                    <hr/>
                    <div style = {{ width:"400px"}} ></div>
                    <Row xs={1} md={4} className="g-4">
                        <Col>
                            <Link to="/admin_payment" style={{ textDecoration: 'none'}}>
                                <Card className="mb-3 rounded shadow-lg justify-content-between align-items-center text-center bg-danger bg-opacity-75">
                                    <Card.Body >
                                        <Card.Title className='text-white'>
                                            <AiOutlineDollar size="90" /><br/><br/>Payment
                                        </Card.Title>
                                    </Card.Body>
                                </Card>
                            </Link>
                        </Col>
                        <Col>
                            <Link to="/admin_verification" style={{ textDecoration: 'none'}}>
                                <Card className="mb-3 rounded shadow-lg justify-content-between align-items-center text-center bg-danger bg-opacity-75">
                                    <Card.Body >
                                    <Card.Title className='text-white'><AiOutlineFileDone  size="90" /><br/><br/>Verification</Card.Title>
                                    </Card.Body>
                                </Card>
                            </Link>
                        </Col>
                        <Col>
                            <Link to="/bills_ad_deleteuser" style={{ textDecoration: 'none'}}>
                                <Card className="mb-3 rounded shadow-lg justify-content-between align-items-center text-center bg-danger bg-opacity-75">
                                    <Card.Body >
                                    <Card.Title className='text-white'><AiOutlineForm size="90" /><br/><br/>Update</Card.Title>
                                    </Card.Body>
                                </Card>
                            </Link>
                        </Col>
                        <Col>
                            <Link to="/bills_ad_searchactivateuser" style={{ textDecoration: 'none'}}>
                                <Card className="mb-3 rounded shadow-lg justify-content-between align-items-center text-center bg-danger bg-opacity-75">
                                    <Card.Body >
                                    <Card.Title className='text-white'><AiOutlineDelete size="90" /><br/><br/>Delete</Card.Title>
                                    </Card.Body>
                                </Card>
                            </Link>
                        </Col>
                        <Col>
                            <Link to="/bills_ad_userlist" style={{ textDecoration: 'none'}}>
                                <Card className="mb-3 rounded shadow-lg justify-content-between align-items-center text-center bg-danger bg-opacity-75">
                                    <Card.Body >
                                    <Card.Title className='text-white'><AiOutlineUnorderedList  size="90" /><br/><br/>Payment List</Card.Title>
                                    </Card.Body>
                                </Card>
                            </Link>
                        </Col>
                        <Col>
                            <Link to="/bills_ad_userlist" style={{ textDecoration: 'none'}}>
                                <Card className="mb-3 rounded shadow-lg justify-content-between align-items-center text-center bg-danger bg-opacity-75">
                                    <Card.Body >
                                    <Card.Title className='text-white'><AiOutlineTool  size="90" /><br/><br/>Adjustment</Card.Title>
                                    </Card.Body>
                                </Card>
                            </Link>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </div>
    );
};

export default PaymentMenu;