// src/store/auth/sagas.ts
import { call, put, takeLatest } from 'redux-saga/effects';
import { AxiosResponse } from 'axios';
import { 
  ActionTypes, 
  activate_searchSuccess, 
  activate_searchFailure, 
  activate_updateSuccess, 
  activate_updateFailure, 
  activate_updateRedirect 
} from './activateActions';
import apiUrl, {homeUrl} from '../../../../components/apiurl';
import axios from 'axios';
import { setSessionVariable, getSessionVariable } from '../../../../components/sessionStorage';
import CryptoJS from 'crypto-js';

// ****** decrypt Data *****
const encryptionKey = process.env.REACT_APP_DE_EN ?? ''; 
const ivHex = '00000000000000000000000000000000'; // Fixed IV as used in the backend

const decryptData = (encryptedData: string, encryptionKey: string): string => {
  const key = CryptoJS.enc.Utf8.parse(encryptionKey); // Use Utf8 parsing for consistency
  const iv = CryptoJS.enc.Hex.parse(ivHex);

  // Convert the hex string to bytes, then decrypt
  const encryptedHexStr = CryptoJS.enc.Hex.parse(encryptedData); // Interpret hex as bytes
  const encryptedBase64 = CryptoJS.enc.Base64.stringify(encryptedHexStr); // Convert bytes to Base64

  // Decrypt the Base64 string
  const bytes = CryptoJS.AES.decrypt(encryptedBase64, key, {
    iv: iv,
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.Pkcs7,
  });

  return bytes.toString(CryptoJS.enc.Utf8);
};

// search
function* activate_search(action: any): Generator<any, void, AxiosResponse> {
  try {
    const { account } = action.payload;
    const response: AxiosResponse = yield call(axios.post, `${apiUrl.url}activate_search`, { account });
    const user = response.data;

      if(user!=''){
        yield put(activate_searchSuccess(user));
        setSessionVariable('user_id', user[0].id);
        setSessionVariable('account', user[0].account);
        setSessionVariable('fname', user[0].fname);
        setSessionVariable('mi', user[0].mi);
        setSessionVariable('lname', user[0].lname);
        setSessionVariable('phase', user[0].phase);
        setSessionVariable('block', user[0].block);
        setSessionVariable('lot', user[0].lot);
        window.location.reload();
      }else{
        yield put(activate_searchFailure('failed'));
      }
  } catch (error) {
    const errorMessage = (error as any).message; 
    yield put(activate_searchFailure(errorMessage));
  }
}


function* activate(action: any): Generator<any, void, AxiosResponse> {
  try {
    // Initial activation API call
    const activationResponse: AxiosResponse = yield call(axios.post, `${apiUrl.url}activation`, action.payload);
    const user = activationResponse.data;

    if (user != '') {
      yield put(activate_updateSuccess(user));
      yield put(activate_updateRedirect('/login'));

      const accountSession = getSessionVariable<any>('account');
      const fname = getSessionVariable<string>('fname');
      const mi = getSessionVariable<string>('mi');
      const lname = getSessionVariable<string>('lname');
      const phase = getSessionVariable<string>('phase');
      const block = getSessionVariable<string>('block');
      const lot = getSessionVariable<string>('lot');

      // Define verify link with `verify_code`
      const verify_code = encodeURIComponent(action.payload.verify_code); // Ensure URL-safe
      const account = encodeURIComponent(action.payload.account);
      // const verify_link = `${apiUrl.url}activation/verify?verify_code=${verify_code}&account=${account}`;
      const verify_link = `${homeUrl.url}verify_email?verify_code=${verify_code}&account=${account}`;

      // Email sending API call
      const emailResponse: AxiosResponse = yield call(axios.post, `${apiUrl.url}send_email_no_token`, {
        to: action.payload.email,
        subject: 'Account Activation',
        html: `
          <div style="background-color: rgb(243,243,243); padding: 30px;">
            <div style="font-family:Arial, Helvetica, sans-serif; font-size: 14px; border: 1px solid #d1d1d1">
              <div style="background-color: #9ffcca; color: #383d3a; padding: 5px; border: none; text-align: center;">
              <a href="https://pearsportal.com" target="_blank"><img src="https://billing.pearsportal.com/linao.png" style="width:160px; height:100px" /></a>
              </div>
                    <div style="padding: 30px; background-color: #FFF">
                    Hi ${decryptData(fname || '', encryptionKey)},
                    <br/><br/>
                    Your account details:
                    <div style="padding-left: 10px;">
                      <table style="border: none; padding: 3px; font-weight: bold">
                        <tr><td>Account #</td><td style="padding-left:5px; padding-right:5px">:</td><td>${accountSession}</td></tr>
                        <tr><td>Name</td><td style="padding-left:5px; padding-right:5px">:</td><td>${decryptData(fname || '', encryptionKey)} ${mi} ${decryptData(lname || '', encryptionKey)}</td></tr>
                        <tr><td>Address</td><td style="padding-left:5px; padding-right:5px">:</td><td>Phase ${phase} Block ${block} Lot ${lot}</td></tr>
                        <tr><td>E-mail</td><td style="padding-left:5px; padding-right:5px">:</td><td>${action.payload.email}</td></tr>
                        <tr><td>Cellphone</td><td style="padding-left:5px; padding-right:5px">:</td><td>${action.payload.cellphone}</td></tr>
                      </table>
                      <br/>
                      Please verify your email.<br/>
                      <a href="${verify_link}" style="display:inline-block; padding:10px 20px; color:#ffffff; background-color:#00c04b; text-decoration:none; border-radius:5px;">Verify</a>
                      <br/><br/>
                    </div>
                    Please give us some feedback to improve the system.<br/>Thank you.
                    <br/><br/>
                    Best regards,<br/>
                    Casa Mira - Linao Application Team
                  </div>
                </div>
          </div>`,
      });

      if (emailResponse.status === 200) {
        console.log('Email sent successfully');
      } else {
        console.error('Failed to send email');
      }
    } else {
      yield put(activate_updateFailure('Activation failed'));
    }
  } catch (error) {
    const errorMessage = (error as any).message;
    yield put(activate_updateFailure(errorMessage));
  }
}


function* admin_watchLogin() {
  yield takeLatest(ActionTypes.ACTIVATE_SEARCH_REQUEST, activate_search);
  yield takeLatest(ActionTypes.ACTIVATE_UPDATE_REQUEST, activate);
}

export default admin_watchLogin;
