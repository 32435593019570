import React, { useState, useEffect, useCallback } from 'react';
import ReactToPrint from 'react-to-print';
import axios, { AxiosResponse } from 'axios';
import { 
    Row, 
    Col,
    Button,
    Table,
    Breadcrumb,
    Pagination,
} from 'react-bootstrap';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import { FaSort, FaSortUp, FaSortDown } from "react-icons/fa";
import * as Yup from 'yup';
import { AiOutlineEye, AiOutlineUnorderedList, AiOutlineMessage } from "react-icons/ai";
import { BiSearchAlt } from "react-icons/bi";
import { TfiPrinter } from "react-icons/tfi";
import { RiFileExcel2Fill } from "react-icons/ri";
import SubMenu from '../submenu';
import { useNavigate } from 'react-router-dom';
import { AuthAccessFailed } from '../../../components/loader';
import { getLocalStorageVariable } from '../../../components/localStorage';
import { setSessionVariable } from '../../../components/sessionStorage';
import apiUrl from '../../../components/apiurl';
import * as XLSX from 'xlsx';
import saveAs from 'file-saver';
import CryptoJS from 'crypto-js';

const SearchSchema = Yup.object().shape({
    search: Yup.string()
        .trim(),
  });

const ViewSchema = Yup.object().shape({
    id: Yup.string()
        .trim(),
  });

  interface UserData {
    id: number;
    account: string;
    fname: string;
    mi: string;
    lname: string;
    phase: string;
    block: string;
    lot: string;
    lot_area: string;
    floor_area: string;
    dues: string;
    cellphone: string;
    email: string;
    status: number;
    association_total: number;
    association_debit: number;
    association_credit: number;
    association_balance: number;
    water_total: number;
    water_debit: number;
    water_credit: number;
    water_balance: number;
    total_payment: number;
  }

const ITEMS_PER_PAGE = 20;

interface SortConfig {
    key: keyof UserData;
    direction: 'asc' | 'desc';
  }

const User_List: React.FC = () =>{
    const token = getLocalStorageVariable<string>('token');
    const [dataUser, setUser] = useState<UserData[] | null>(null);
    const [searchTerm, setSearchTerm] = useState('');
    const user_update = getLocalStorageVariable<string>('user_update');
    const navigate = useNavigate();
    const componentRef = React.useRef(null);

    // Pagination
    const [currentPage, setCurrentPage] = useState(1);

    // Pagination calculation
    const indexOfLastItem = currentPage * ITEMS_PER_PAGE;
    const indexOfFirstItem = indexOfLastItem - ITEMS_PER_PAGE;

    // ****** decrypt Data *****
    const encryptionKey = process.env.REACT_APP_DE_EN ?? ''; 
    const ivHex = '00000000000000000000000000000000'; // Fixed IV as used in the backend
 
    const decryptData = (encryptedData: string, encryptionKey: string): string => {
        try {
            const key = CryptoJS.enc.Utf8.parse(encryptionKey); // Use Utf8 parsing for consistency
            const iv = CryptoJS.enc.Hex.parse(ivHex);
    
            // Ensure the encryptedData is a valid hex string
            if (!/^[a-fA-F0-9]+$/.test(encryptedData)) {
                console.error("Invalid encrypted data format:", encryptedData);
                return ""; // Return an empty string or a placeholder on error
            }
    
            // Convert the hex string to bytes, then decrypt
            const encryptedHexStr = CryptoJS.enc.Hex.parse(encryptedData); // Interpret hex as bytes
            const encryptedBase64 = CryptoJS.enc.Base64.stringify(encryptedHexStr); // Convert bytes to Base64
    
            // Decrypt the Base64 string
            const bytes = CryptoJS.AES.decrypt(encryptedBase64, key, {
                iv: iv,
                mode: CryptoJS.mode.CBC,
                padding: CryptoJS.pad.Pkcs7,
            });
    
            const decrypted = bytes.toString(CryptoJS.enc.Utf8);
    
            if (!decrypted) {
                console.error("Decryption failed:", encryptedData);
                return ""; // Return an empty string on failure
            }
    
            return decrypted;
        } catch (error) {
            console.error("Error decrypting data:", error, encryptedData);
            return ""; // Return an empty string or placeholder if decryption fails
        }
    };
    
    // ***** Handle Search *****
    const handleSearch = (values: { search: string }) => {
        setCurrentPage(1); // Reset pagination
        applyLocalSearch(values.search); // Filter data locally
    };
    
    const applyLocalSearch = (searchTerm: string) => {
        if (!originalUserData) {
            setUser(null); // If originalUserData is null, set filtered data to null
            return;
        }
        
        // Perform a case-insensitive search in fname, lname, and other fields
        const filteredData = originalUserData.filter((user) => {
            const searchLower = searchTerm.toLowerCase();
            return (
                user.account?.toLowerCase().includes(searchLower) ||
                user.fname?.toLowerCase().includes(searchLower) ||
                user.lname?.toLowerCase().includes(searchLower) ||
                user.phase?.toLowerCase().includes(searchLower) ||
                user.block?.toLowerCase().includes(searchLower) ||
                user.lot?.toLowerCase().includes(searchLower)
            );
        });
        setUser(filteredData.length > 0 ? filteredData : null);
    };
    
    // Store the original data for local search
    const [originalUserData, setOriginalUserData] = useState<UserData[] | null>(null);
    
    // Updated fetchUser logic to store the original data
    const fetchUser = useCallback(async () => {
        const headers = {
            Authorization: `${token}`,
        };
    
        try {
            const res: AxiosResponse<UserData[]> = await axios.get(`${apiUrl.url}admin_get_user`,{ headers, params: { search: searchTerm } });
    
            if (res.data.length > 0) {
                const decryptedData = res.data.map((user) => ({
                    ...user, fname: decryptData(user.fname, encryptionKey), lname: decryptData(user.lname, encryptionKey),
                }));
                setOriginalUserData(decryptedData); // Store the original data for filtering
                setUser(decryptedData); // Set the data to be displayed
            } else {
                setOriginalUserData(null);
                setUser(null);
            }
        } catch (error) {
            console.error('Error fetching user data:', error);
        }
    }, [searchTerm, token, encryptionKey]);


    // ***** Export to Excel *****
    const formattedData = (dataUser || []).map((r, i) => ({
        Acount: r.account,
        Firstname: r.fname,
        MI: r.mi,
        Lastname: r.lname,
        Phase: r.phase,
        Block: r.block,
        Lot: r.lot,
      }));
      
    const exportToExcel = () => {
        const worksheet = XLSX.utils.json_to_sheet(formattedData);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet 1");
        const excelBuffer = XLSX.write(workbook, {
          bookType: "xlsx",
          type: "array",
        });
        const dataBlob = new Blob([excelBuffer], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        saveAs(dataBlob, "user_list.xlsx");
      };


    // Print List
    const headers = [
        { label: "Account #", key: "account" },
        { label: "First Name", key: "fname" },
        { label: "MI", key: "mi" },
        { label: "Last Name", key: "lname" },
        { label: "Phase", key: "phase" },
        { label: "Block", key: "block" },
        { label: "Lot", key: "lot" },
        { label: "Cellphone", key: "cellphone" },
        { label: "Email", key: "email" },
      ];

// ***** Print List *****
    class ComponentToPrint extends React.Component {
        render() {
          return (
            <div style={{fontSize:'10px'}} className="print-content">
                <Table responsive striped bordered hover size="sm">
                    <thead>
                        <tr className="text-center">
                            <th>#</th>
                            <th>Account #</th>
                            <th>Name</th>
                            <th>Phase</th>
                            <th>Block</th>
                            <th>Lot</th>
                            <th>Lot Area</th>
                            <th>Floor Area</th>
                            <th>Dues</th>
                        </tr>
                    </thead>
                        {dataUser?.length!=0? (
                            <tbody>
                                {dataUser?.map((r, i) => (
                                <tr key={i} className="align-middle text-secondary">
                                    <td className="text-center align-middle">{i + 1}</td>
                                    <td className="text-center align-middle">{r.account}</td>
                                    <td className="">{r.fname} {r.mi}. {r.lname}</td>
                                    <td className="text-center align-middle">{r.phase}</td>
                                    <td className="text-center align-middle">{r.block}</td>
                                    <td className="text-center align-middle">{r.lot}</td>
                                    <td className="text-center align-middle">{r.lot_area}</td>
                                    <td className="text-center">{r.floor_area}</td>
                                    <td className="text-center">{r.dues}</td>
                                </tr>
                                ))}
                            </tbody>
                        ) : (
                            <tbody>
                                <tr><td colSpan={15} className='text-danger'>No user account found...</td></tr>
                            </tbody>
                        )}
                </Table>
                <style>
                    {`
                    @media print {
                        body {
                            margin: 0;
                        }
                        .print-content {
                        }
                        @page {
                            size: A4;
                            margin: 1cm;
                        }
                    }
                    `}
                </style>
            </div>
          );
        }
      }


    useEffect(() => {
        fetchUser();
    }, [searchTerm, fetchUser]);

    // ***** Sorting Code *****
    const [sortConfig, setSortConfig] = useState<SortConfig | null>(null);

    const sortedData = React.useMemo(() => {
      if (!dataUser || !sortConfig) return dataUser;
      const sortedArray = [...dataUser];
      sortedArray.sort((a, b) => {
        const aValue = a[sortConfig.key];
        const bValue = b[sortConfig.key];
        if (aValue < bValue) return sortConfig.direction === 'asc' ? -1 : 1;
        if (aValue > bValue) return sortConfig.direction === 'asc' ? 1 : -1;
        return 0;
      });
      return sortedArray;
    }, [dataUser, sortConfig]);

    const handleSort = (key: keyof UserData) => {
        setSortConfig((prevSortConfig) => {
          if (prevSortConfig && prevSortConfig.key === key) {
            return {
              key,
              direction: prevSortConfig.direction === 'asc' ? 'desc' : 'asc',
            };
          } else {
            return { key, direction: 'asc' };
          }
        });
    };

    const handleView = async(values: any) => {
        const { id } = values;
        const headers = {
            Authorization: `${token}`,
          };
        try {
            const res: AxiosResponse<UserData[]> = await axios.post(`${apiUrl.url}admin_update_user_select/`, { id: id }, { headers });
            setSessionVariable('user_id', res.data[0].id);
            setSessionVariable('account', res.data[0].account);
            setSessionVariable('fname', decryptData(res.data[0].fname, encryptionKey));
            setSessionVariable('mi', res.data[0].mi);
            setSessionVariable('lname', decryptData(res.data[0].lname, encryptionKey));
            setSessionVariable('phase', res.data[0].phase);
            setSessionVariable('block', res.data[0].block);
            setSessionVariable('lot', res.data[0].lot);
            setSessionVariable('lotarea', res.data[0].lot_area);
            setSessionVariable('floorarea', res.data[0].floor_area);
            setSessionVariable('dues', res.data[0].dues);
            setSessionVariable('cellphone', res.data[0].cellphone);
            setSessionVariable('email', res.data[0].email);
            setSessionVariable('status', res.data[0].status);
            setSessionVariable('association_total', res.data[0].association_total);
            setSessionVariable('association_debit', res.data[0].association_debit);
            setSessionVariable('association_credit', res.data[0].association_credit);
            setSessionVariable('association_balance', res.data[0].association_balance);
            setSessionVariable('water_total', res.data[0].water_total);
            setSessionVariable('water_debit', res.data[0].water_debit);
            setSessionVariable('water_credit', res.data[0].water_credit);
            setSessionVariable('water_balance', res.data[0].water_balance);
            setSessionVariable('total_payment', res.data[0].total_payment);
            
            navigate('/admin_user_view');
        } catch (error) {
            console.error('Error: ', error);
        }
      };

      const handleChat = async(values: any) => {
        const { id } = values;
        const headers = {
            Authorization: `${token}`,
          };
        try {
            const res: AxiosResponse<UserData[]> = await axios.post(`${apiUrl.url}admin_update_user_select/`, { id: id }, { headers });
            setSessionVariable('user_id_receiver', res.data[0].id);
            // setSessionVariable('account', res.data[0].account);
            setSessionVariable('fname', decryptData(res.data[0].fname, encryptionKey));
            setSessionVariable('mi', res.data[0].mi);
            setSessionVariable('lname', decryptData(res.data[0].lname, encryptionKey));
            // setSessionVariable('phase', res.data[0].phase);
            // setSessionVariable('block', res.data[0].block);
            // setSessionVariable('lot', res.data[0].lot);
            // setSessionVariable('lotarea', res.data[0].lot_area);
            // setSessionVariable('floorarea', res.data[0].floor_area);
            // setSessionVariable('dues', res.data[0].dues);
            // setSessionVariable('cellphone', res.data[0].cellphone);
            // setSessionVariable('email', res.data[0].email);
            // setSessionVariable('status', res.data[0].status);
            
            navigate('/admin_message_user');
        } catch (error) {
            console.error('Error: ', error);
        }
        // navigate('/admin_message_user');
      };

    const handleItemClick = (path: string) => {
        setSessionVariable('setSelectedItem', path);
        navigate(path);
    };

    // Apply pagination after sorting
    const paginatedData = React.useMemo(() => {
        return sortedData?.slice(indexOfFirstItem, indexOfLastItem);
    }, [sortedData, indexOfFirstItem, indexOfLastItem]);

    // Pagination handler
    const paginate = (pageNumber: number) => setCurrentPage(pageNumber);
    const totalPages = Math.ceil((sortedData?.length || 0) / ITEMS_PER_PAGE);

    return (
            <div className="container-fluid ">
                {user_update != '1'? <AuthAccessFailed />:""}

                <Row className="justify-content-md-center">
                    <Col>
                        <SubMenu/>
                    </Col>
                    <Col xs lg="10"  className="p-3 bg-white rounded shadow-sm ">
                        <h4 className="text-primary mb-3" ><AiOutlineUnorderedList size="30" /> User Account List</h4>
                            <Breadcrumb>
                                <Breadcrumb.Item onClick={() => handleItemClick('/admin_user_menu')}>User Menu</Breadcrumb.Item>
                                <Breadcrumb.Item active>User List</Breadcrumb.Item>
                            </Breadcrumb>
                        <hr/>
                        <div style = {{ width:"400px"}} />
                         <Formik
                            enableReinitialize={true}
                            initialValues={{ 
                                search: "",
                            }}
                            validationSchema={SearchSchema}
                            onSubmit={handleSearch}
                            >
                                {({ errors, touched }) => (
                                <Form>
                                    <Row>
                                        <Col lg="6"  className="bg-white rounded">
                                            <FloatingLabel label="&#x1F50E; Search..." className="mb-2">
                                                <Field
                                                    type="search"
                                                    name="search"
                                                    maxLength="50"
                                                    placeholder="&#x1F50E; Search..."
                                                    style={{border: "1px solid rgb(128, 128, 128)"}}
                                                    className={`rounded w-100 form-control ${touched.search && errors.search ? 'is-invalid' : touched.search ? 'is-valid' : ''}`}
                                                />
                                                <ErrorMessage name="search">
                                                    {msg => <div className="invalid-feedback">{msg}</div>}
                                                </ErrorMessage>
                                            </FloatingLabel>
                                            <Button type="submit" variant="outline-success" style={{display:'none'}}><BiSearchAlt size="20" className='ms-2 me-2'/></Button>
                                        </Col>
                                        <Col lg="6" className=''>
                                            <div className="btn-toolbar mb-2 mb-md-0 float-end">
                                            <div className="btn-group mr-2">
                                                
                                                <ReactToPrint
                                                    trigger={() => <button type="button"  className="btn btn-md btn-outline-success"><TfiPrinter size="20"/> Print List</button>}
                                                    content={() => componentRef.current}
                                                />
                                                <button type="button" onClick={exportToExcel} className="btn btn-md btn-outline-success"><RiFileExcel2Fill size="20"/> Export to Excel</button>
                                               
                                            </div>
                                                <div style={{ display: 'none' }}>
                                                </div>
                                                <div style={{ display: 'none' }}>
                                                    <ComponentToPrint ref={componentRef} />
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                </Form>
                                )}
                            </Formik>

                                <Table responsive striped bordered hover size="sm">
                                    <thead>
                                        <tr className="text-center bg-secondary" style={{cursor: 'pointer'}}>
                                            <th className="bg-secondary text-white">#</th>
                                            <th onClick={() => handleSort('account')} className="sortable bg-secondary text-white">
                                                Account # &emsp;{sortConfig?.key === 'account' ? (sortConfig.direction === 'asc' ? <FaSortUp /> : <FaSortDown />) : <FaSort />}
                                            </th>
                                            <th onClick={() => handleSort('lname')} className="sortable bg-secondary text-white" >
                                                Name &emsp; {sortConfig?.key === 'lname' ? (sortConfig.direction === 'asc' ? <FaSortUp /> : <FaSortDown />) : <FaSort />}
                                            </th>
                                            <th onClick={() => handleSort('phase')} className="sortable bg-secondary text-white">
                                                Phase &emsp; {sortConfig?.key === 'phase' ? (sortConfig.direction === 'asc' ? <FaSortUp /> : <FaSortDown />) : <FaSort />}
                                            </th>
                                            <th onClick={() => handleSort('block')} className="sortable bg-secondary text-white">
                                                Block &emsp; {sortConfig?.key === 'block' ? (sortConfig.direction === 'asc' ? <FaSortUp /> : <FaSortDown />) : <FaSort />}
                                            </th>
                                            <th onClick={() => handleSort('lot')} className="sortable bg-secondary text-white">
                                                Lot &emsp; {sortConfig?.key === 'lot' ? (sortConfig.direction === 'asc' ? <FaSortUp /> : <FaSortDown />) : <FaSort />}
                                            </th>
                                            <th onClick={() => handleSort('status')} className="sortable bg-secondary text-white">
                                                Status &emsp; {sortConfig?.key === 'status' ? (sortConfig.direction === 'asc' ? <FaSortUp /> : <FaSortDown />) : <FaSort />}
                                            </th>
                                            <th colSpan={2} className="bg-secondary text-white">Actions</th>
                                        </tr>
                                    </thead>
                                            <tbody>
                                                {paginatedData?.length ? (
                                                    paginatedData.map((r, i) => (
                                                        <tr key={i} className="align-middle text-secondary">
                                                            <td className="text-center align-middle">{i + 1 + indexOfFirstItem}</td>
                                                            <td className="text-center align-middle">{r.account}</td>
                                                            <td className="">
                                                                {r.fname} {r.mi}. {r.lname}
                                                            </td>
                                                            <td className="text-center align-middle">{r.phase}</td>
                                                            <td className="text-center align-middle">{r.block}</td>
                                                            <td className="text-center align-middle">{r.lot}</td>
                                                            <td className="text-center align-middle">
                                                                {r.status === 1 ? <strong className='text-success'>Active</strong> : <strong className='text-danger'>Inactive</strong>}
                                                            </td>
                                                            <td className="text-center align-middle">
                                                                <Formik
                                                                    enableReinitialize={true}
                                                                    initialValues={{ 
                                                                        id: r.id,
                                                                    }}
                                                                    validationSchema={ViewSchema}
                                                                    onSubmit={handleView}
                                                                        >
                                                                    <Form>
                                                                        <Field type="text" name={r.id} value={r.id} hidden></Field>
                                                                        <Button type="submit" variant="outline-primary" size="sm" className='me-2 btn btn-block rounded'><AiOutlineEye /> View</Button>
                                                                    </Form>
                                                                </Formik>
                                                            </td>
                                                            <td className="text-center align-middle">
                                                                <Formik
                                                                    enableReinitialize={true}
                                                                    initialValues={{ 
                                                                        id: r.id,
                                                                    }}
                                                                    validationSchema={ViewSchema}
                                                                    onSubmit={handleChat}
                                                                        >
                                                                    <Form>
                                                                        <Field type="text" name={r.id} value={r.id} hidden></Field>
                                                                        <Button type="submit" variant="outline-primary" size="sm" className='me-2 btn btn-block rounded'><AiOutlineMessage /> Chat</Button>
                                                                    </Form>
                                                                </Formik>
                                                            </td>
                                                        </tr>
                                                    ))
                                                ) : (
                                                    <tr>
                                                        <td colSpan={9} className='text-danger text-center'>No user account found...</td>
                                                    </tr>
                                                )}
                                            </tbody>
                                    </Table>

                            {dataUser?.length && dataUser.length > ITEMS_PER_PAGE && (
                                <Pagination>
                                    <Pagination.First onClick={() => paginate(1)} />
                                    <Pagination.Prev onClick={() => paginate(currentPage - 1)} />

                                    {Array.from({ length: totalPages }).map((_, index) => {
                                        // Show the current page and two pages before and after it
                                        if (index >= currentPage - 2 && index <= currentPage + 2) {
                                        return (
                                            <Pagination.Item
                                            key={index}
                                            active={index + 1 === currentPage}
                                            onClick={() => paginate(index + 1)}
                                            >
                                            {index + 1}
                                            </Pagination.Item>
                                        );
                                        }

                                        return null;
                                    })}
                                    {currentPage + 2 < totalPages && (
                                        <Pagination.Ellipsis key={`ellipsis`} />
                                    )}

                                    <Pagination.Next onClick={() => paginate(currentPage + 1)} />
                                    <Pagination.Last onClick={() => paginate(totalPages)} />
                                </Pagination>
                            )}
                    </Col>
                </Row>
            </div>
    );
};

export default User_List;