import React, { useState, useEffect, useRef } from 'react';
import { 
  Row, 
  Col,
  Alert,
  Breadcrumb,
} from 'react-bootstrap';
import SubMenu from '../submenu';
import axios from 'axios';
import { AiOutlineComment } from "react-icons/ai";
import apiUrl from '../../../components/apiurl';
import { getLocalStorageVariable } from '../../../components/localStorage';
import CryptoJS from 'crypto-js';

interface Message {
  id: number;
  sender: string | null;
  content: string;
  timestamp: string;
  recipient?: string;
  isSeen?: boolean;
}

interface Message_List {
  id: number;
  sender: string | null;
  recipient: string;
  isseen?: boolean;
}

interface Message_List_User_Details {
  id: number;
  fname: string;
  mi: string;
  lname: string;
  auth: string;
  avatar: string;
}

const Messages: React.FC = () => {
    const [messages, setMessages] = useState<Message[]>([]);
    const [messages_list, setMessagesList] = useState<Message_List[]>([]);
    const [messages_list_user_details, setMessagesListUserDetails] = useState<Message_List_User_Details[]>([]);
    const sender = getLocalStorageVariable<string>('userId');
    const token = getLocalStorageVariable<string>('token');
    const userAvatar = getLocalStorageVariable<string>('avatar');
    const [recAvatar, setRecAvatar] = useState('');
    const [reciever, setReciever] = useState('');
    const [content, setContent] = useState('');
    const messagesContainerRef = useRef<HTMLDivElement>(null);
    const [selectedItem, setSelectedItem] = useState(null);

  // ****** decrypt Data *****
  const encryptionKey = process.env.REACT_APP_DE_EN ?? ''; 
  const ivHex = '00000000000000000000000000000000'; // Fixed IV as used in the backend

  const decryptData = (encryptedData: string, encryptionKey: string): string => {
    const key = CryptoJS.enc.Utf8.parse(encryptionKey); // Use Utf8 parsing for consistency
    const iv = CryptoJS.enc.Hex.parse(ivHex);
  
    // Convert the hex string to bytes, then decrypt
    const encryptedHexStr = CryptoJS.enc.Hex.parse(encryptedData); // Interpret hex as bytes
    const encryptedBase64 = CryptoJS.enc.Base64.stringify(encryptedHexStr); // Convert bytes to Base64
  
    // Decrypt the Base64 string
    const bytes = CryptoJS.AES.decrypt(encryptedBase64, key, {
      iv: iv,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7,
    });
  
    return bytes.toString(CryptoJS.enc.Utf8);
  };


    const headers = {
      Authorization: `${token}`,
    };

  // ***** Message List display *****
    const getMessageList = (sender:string) => {
      axios.get(`${apiUrl.url}messages/message_list/${sender}`,{headers})
        .then(response => {
          setMessagesList(response.data);
            })
        .catch(error => console.error(error));
      axios.get(`${apiUrl.url}messages/message_list_users/users`,{headers})
        .then(response => {
            setMessagesListUserDetails(response.data);
        })
        .catch(error => console.error(error));
      }

    useEffect(() => {
      if (sender) {
        getMessageList(sender);
      }
    }, [sender]);
  
  // ***** To make the message div start from bottom *****
    useEffect(() => {
      if (messagesContainerRef.current) {
        messagesContainerRef.current.scrollTop = messagesContainerRef.current.scrollHeight;
      }
    }, [messages]);

  // ***** Sending Message ******
    const sendMessage = () => {
      axios.post(`${apiUrl.url}messages`, { sender, content, recipient: reciever },{headers}) 
        .then(response => {
          setMessages([...messages, { id: response.data.id, sender, content, timestamp: new Date().toISOString() }]);
          setContent('');
        })
        .catch(error => console.error(error));
    };

  // ***** Selecting Message to Display *****  
    const selectMessage = (messageId: number, id: any, avatar: string) => {
        axios.get(`${apiUrl.url}messages/message_display/${messageId}/${sender}`,{headers})
          .then(response => {
            if (Array.isArray(response.data) && response.data.length > 0) {
              setMessages(response.data);
      
              const firstMessage = response.data[0];
              if (sender === firstMessage.sender) {
                setReciever(firstMessage.recipient);
                markAsRead(response.data[0].recipient,response.data[0].sender);
              } else {
                setReciever(firstMessage.sender);
                markAsRead(response.data[0].sender,response.data[0].recipient);
              }
              setRecAvatar(avatar);
              setSelectedItem(id);
            } else {
              setMessages([])
              setReciever(id || '');
              setSelectedItem(id);
            }
            if (sender) {
              getMessageList(sender);
            }
          })
          .catch(error => {
            console.error(error);
          });
      };

    //   useEffect(() => {
    //     const intervalId = setInterval(() => {
    //       if (selectedItem !== null) {
    //         selectMessage(selectedItem, selectedItem, recAvatar);
    //       }
    //     }, 1000);
    //     return () => clearInterval(intervalId);
    //   }, [selectedItem, recAvatar]);
      
  
    const markAsRead = (sender: number, recipient: number) => {
      axios.put(`${apiUrl.url}messages/${sender}/${recipient}`,null,{headers})
        .then(response => {
          // console.log(`Message ${messageId} marked as read`);
          })
        .catch(error => console.error(error));
    };

    // Assuming message.timestamp is a string representing a valid date
    const formattedTimestamp = (timestamp: string) => {
      const date = new Date(timestamp);

      const timeOptions: Intl.DateTimeFormatOptions = {
        hour: 'numeric',
        minute: '2-digit',
        hour12: true,
      };
      const dateOptions: Intl.DateTimeFormatOptions = {
        month: 'short',
        day: 'numeric',
      };

      const formattedTime = new Intl.DateTimeFormat('en-US', timeOptions).format(date);
      const formattedDate = new Intl.DateTimeFormat('en-US', dateOptions).format(date);
      return `${formattedTime} | ${formattedDate}`;
    };

  
return (
  <div className="container-fluid ">
    <Row className="justify-content-md-center">
      <Col sm={3}>
          <SubMenu/>
      </Col>
      <Col lg="9"  className="p-3 bg-white rounded shadow-sm">
          <h4 className="text-primary" ><AiOutlineComment size="30" /> Messages</h4>
                  <Breadcrumb>
                      <Breadcrumb.Item href="/dashboard">Dashboard</Breadcrumb.Item>
                      <Breadcrumb.Item active>Messages</Breadcrumb.Item>
                  </Breadcrumb>
              <hr/>
      
            <section >
                <div className="row">
                  <div className="col-md-12">
            
                  <Alert style={{border: "1px solid rgb(128, 128, 128)"}} className='bg-light'>
                      <div className="card-body">
            
                        <div className="row">

                        {/* <div className="me-5 d-none d-lg-block">
                        <span>Get connected with us on social networks:</span>
                    </div> */}

                          <div className="col-md-6 col-lg-5 col-xl-4 mb-4 mb-md-0 d-none d-lg-block">
                              <div className="input-group rounded mb-3">
                                <input type="search" className="form-control rounded" placeholder="Search" aria-label="Search"
                                  aria-describedby="search-addon" />
                              </div>
            
                              <div style={{ position: 'relative', height: '550px', overflowY: 'auto'}}>
                                <ul className="list-unstyled mb-0 bg-light">
                                  {messages_list_user_details.map((userDetailsArray:any, index) => (
                                    <li className="p-2 pb-0 border-bottom" style={{cursor:'pointer'}} key={index}>
                                      <div onClick={() => selectMessage(userDetailsArray.id, userDetailsArray.id, userDetailsArray.avatar)} className="d-flex justify-content-between" style={{ textDecoration: 'none' }}>
                                      {selectedItem==userDetailsArray.id?
                                        <div className="d-flex flex-row text-primary fw-bold">
                                          <div>
                                            <img
                                              src={userDetailsArray.avatar || '/static/media/default.d1c5ffe2b3cea9d80c7b.jpg'}
                                              alt="avatar"
                                              className="d-flex align-self-center me-3"
                                              width="45"
                                            />
                                          </div>
                                          <div className="pt-1">
                                            <p className="large mb-0">{decryptData(userDetailsArray.fname, encryptionKey)} {userDetailsArray.mi} {decryptData(userDetailsArray.lname, encryptionKey)} </p>
                                            <p className="large text-muted mb-2">{userDetailsArray.auth}</p>
                                          </div>
                                        </div>
                                      :
                                        <div className="d-flex flex-row">
                                          <div>
                                            <img
                                              src={userDetailsArray.avatar || '/static/media/default.d1c5ffe2b3cea9d80c7b.jpg'}
                                              alt="avatar"
                                              className="d-flex align-self-center me-3"
                                              width="45"
                                            />
                                          </div>
                                          <div className="pt-1">
                                            <p className="mb-0 text-muted">{decryptData(userDetailsArray.fname, encryptionKey)} {userDetailsArray.mi} {decryptData(userDetailsArray.lname, encryptionKey)}</p>
                                            <p className="small text-muted mb-2">{userDetailsArray.auth}</p>
                                          </div>
                                        </div>
                                      }
                                        
                                        <div className="pt-1">
                                          {messages_list.map(message => (
                                            <div key={message.id}>
                                              {message.sender == userDetailsArray.id?.toString() && (
                                                message.isseen ? '' : <span className="badge bg-danger rounded-pill float-end">&nbsp;</span>
                                              )}
                                            </div>
                                          ))}
                                        </div>
                                      </div>
                                    </li>
                                  ))}
                                </ul>
                              </div>
                          </div>
            
                          <div className="col-md-6 col-lg-7 col-xl-8">
            
                            <div className="p-3 pe-3 shadow-sm" 
                              style={{ position: 'relative', height: '550px', overflowY: 'auto', backgroundColor:'white'}} ref={messagesContainerRef}>
            
                              {messages.map((message, index) => (
                                <div key={index}>
                                  {sender==message.recipient?
                                    <div className="d-flex flex-row justify-content-start"key={index}>
                                      <img src={recAvatar || '/static/media/default.d1c5ffe2b3cea9d80c7b.jpg'}
                                        alt="avatar 1" style={{width: '45px', height: '100%'}}/>
                                      <div>
                                        <p className="medium p-2 ms-3 mb-1 rounded-3" style={{backgroundColor: '#e1e1e1'}}>
                                          {message.content} 
                                        </p>
                                        <p className="small me-3 mb-3 rounded-3 text-muted">{formattedTimestamp(message.timestamp)}</p>
                                      </div>
                                    </div>
                                  :
                                    <div className="d-flex flex-row justify-content-end" key={index}>
                                      <div>
                                        <p className="medium p-2 me-3 mb-1 text-white rounded-3 bg-primary">
                                          {message.content} 
                                        </p>
                                        <p className="small me-3 mb-3 rounded-3 text-muted">{formattedTimestamp(message.timestamp)}</p>
                                      </div>
                                      <img src={userAvatar || '/static/media/default.d1c5ffe2b3cea9d80c7b.jpg'}
                                        alt="avatar 1" style={{width: '45px', height: '100%'}}/>
                                    </div>
                                  }
                                </div>
                              ))}

                            </div>
            
                            <div className="text-muted d-flex justify-content-start align-items-center pt-3 mt-2">
                                {selectedItem?
                                  <input
                                    type="text"
                                    className="form-control form-control-md rounded-pill"
                                    id="exampleFormControlInput2"
                                    placeholder="Type message"
                                    value={content}
                                    onChange={(e) => setContent(e.target.value)}
                                    onKeyDown={(e) => {
                                      if (e.key === 'Enter') {
                                        e.preventDefault(); 
                                        sendMessage(); 
                                      }
                                    }}
                                  />
                                :<></>}
                            </div>
                          </div>
                        </div>
                      </div>
                    </Alert>
                  </div>
                </div>
            </section>
          </Col>
        </Row>
      </div>
    );
  };
  
  export default Messages;
  
