import React, { useState, useEffect, useRef }  from 'react';
import {
    Modal,
    Spinner,
    Button,
    Image
} from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { RootState } from '../../../redux/reducers';
import { PiWarningDuotone } from "react-icons/pi";
import { PiCheckCircleDuotone } from "react-icons/pi";
import loading from '../images/linao.png';


export const CreateUserSuccess = () => {
    const redirectPath = useSelector((state: RootState) => state.Admin_CreateUserReducer.redirectPath);
    const closeButtonRef = useRef<HTMLButtonElement | null>(null); 
    const navigate = useNavigate();
    const [show, setShow] = useState(true);

    const handleClose = () => {
        setShow(false);
        if (redirectPath) {
            navigate(redirectPath);
            window.location.reload();
          }
    }
    useEffect(() => {
        if (show && closeButtonRef.current) {
          closeButtonRef.current.focus();
        }
      }, [show]);
    return (
        <Modal show={show} onHide={handleClose} centered>
            <Modal.Header  className="d-flex justify-content-center">
                <Modal.Title>
                    <div className='text-success'>
                        <PiCheckCircleDuotone size={100}/>
                    </div>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className="d-flex justify-content-center">
                <div>
                    <h4>Transaction successful!</h4>
                    <p>User account recorded successfully.</p>
                </div>
            </Modal.Body>
            <Modal.Footer className="d-flex justify-content-center">
                {/* <Button variant="secondary" onClick={handleClose} >Yes</Button> */}
                <Button variant="secondary" onClick={handleClose} ref={closeButtonRef as React.RefObject<HTMLButtonElement>}>Close</Button>
            </Modal.Footer>
        </Modal>
    );
}

export const CreateAdminUserSuccess = () => {
    const redirectPath = useSelector((state: RootState) => state.Admin_SettingsReducer.redirectPath);
    const closeButtonRef = useRef<HTMLButtonElement | null>(null); 
    const navigate = useNavigate();
    const [show, setShow] = useState(true);

    const handleClose = () => {
        setShow(false);
        if (redirectPath) {
            navigate(redirectPath);
            window.location.reload();
          }
    }
    useEffect(() => {
        if (show && closeButtonRef.current) {
          closeButtonRef.current.focus();
        }
      }, [show]);
    return (
        <Modal show={show} onHide={handleClose} centered>
            <Modal.Header  className="d-flex justify-content-center">
                <Modal.Title>
                    <div className='text-success'>
                        <PiCheckCircleDuotone size={100}/>
                    </div>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className="d-flex justify-content-center">
                <div>
                    <h4>Transaction successful!</h4>
                    <p>User account recorded successfully.</p>
                </div>
            </Modal.Body>
            <Modal.Footer className="d-flex justify-content-center">
                {/* <Button variant="secondary" onClick={handleClose} >Yes</Button> */}
                <Button variant="secondary" onClick={handleClose} ref={closeButtonRef as React.RefObject<HTMLButtonElement>}>Close</Button>
            </Modal.Footer>
        </Modal>
    );
}

export const UpdateAdminUserSuccess = () => {
    const redirectPath = useSelector((state: RootState) => state.Admin_HeadersReducer.redirectPath);
    const closeButtonRef = useRef<HTMLButtonElement | null>(null); 
    const navigate = useNavigate();
    const [show, setShow] = useState(true);

    const handleClose = () => {
        setShow(false);
        if (redirectPath) {
            navigate(redirectPath);
            window.location.reload();
          }
    }
    useEffect(() => {
        if (show && closeButtonRef.current) {
          closeButtonRef.current.focus();
        }
      }, [show]);
    return (
        <Modal show={show} onHide={handleClose} centered>
            <Modal.Header  className="d-flex justify-content-center">
                <Modal.Title>
                    <div className='text-success'>
                        <PiCheckCircleDuotone size={100}/>
                    </div>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className="d-flex justify-content-center">
                <div>
                    <h4>Recorded successfully!</h4>
                    <p>User account updated successfully.</p>
                </div>
            </Modal.Body>
            <Modal.Footer className="d-flex justify-content-center">
                {/* <Button variant="secondary" onClick={handleClose} >Yes</Button> */}
                <Button variant="secondary" onClick={handleClose} ref={closeButtonRef as React.RefObject<HTMLButtonElement>}>Close</Button>
            </Modal.Footer>
        </Modal>
    );
}

export const CreateUserFailed = () => {
    const closeButtonRef = useRef<HTMLButtonElement | null>(null); 
    const [show, setShow] = useState(true);
    const handleClose = () => setShow(false);
    useEffect(() => {
        if (show && closeButtonRef.current) {
          closeButtonRef.current.focus();
        }
      }, [show]);
    return (
        <Modal show={show} onHide={handleClose} centered>
            <Modal.Header  className="d-flex justify-content-center">
                <Modal.Title>
                    <div className='text-danger'>
                        <PiWarningDuotone size={100}/>
                    </div>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className="d-flex justify-content-center">
                <div>
                    <h4>Transaction Failed</h4>
                    <p>Server might be down or unavailable, or email might be invalid or non-existent email address. Please contact system administrator and try again!</p>
                </div>
            </Modal.Body>
            <Modal.Footer className="d-flex justify-content-center">
                <Button variant="secondary" onClick={handleClose} ref={closeButtonRef as React.RefObject<HTMLButtonElement>}>Close</Button>
            </Modal.Footer>
        </Modal>
    );
}

export const SearchAccountFailed = () => {
    const [show, setShow] = useState(true);
    const closeButtonRef = useRef<HTMLButtonElement | null>(null); 
    const handleClose = () => {
        setShow(false);
        sessionStorage.clear();
        window.location.reload();
    }
    useEffect(() => {
        if (show && closeButtonRef.current) {
          closeButtonRef.current.focus();
        }
      }, [show]);

    return (
        <Modal show={show} onHide={handleClose} centered>
            <Modal.Header  className="d-flex justify-content-center">
                <Modal.Title>
                    <div className='text-danger'>
                        <PiWarningDuotone size={100}/>
                    </div>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className="d-flex justify-content-center">
                <div>
                    <h4>Search Failed!</h4>
                    <p>User account not found. Please contact systems administrator and try again!</p>
                </div>
            </Modal.Body>
            <Modal.Footer className="d-flex justify-content-center">
                <Button variant="secondary" onClick={handleClose} ref={closeButtonRef as React.RefObject<HTMLButtonElement>}>Close</Button>
            </Modal.Footer>
        </Modal>
    );
}


export const AuthAccessFailed: React.FC = () => {
    const closeButtonRef = useRef<HTMLButtonElement | null>(null); 
    const navigate = useNavigate();
    const [show, setShow] = useState(true);

    const handleClose = () => {
        setShow(false);
            navigate('/admin_dashboard');
            window.location.reload();
    }
    useEffect(() => {
        if (show && closeButtonRef.current) {
            closeButtonRef.current.focus();
        }
    }, [show]);
    return (
        <Modal show={show} onHide={handleClose} centered>
            <Modal.Header  className="d-flex justify-content-center">
                <Modal.Title>
                    <div className='text-danger'>
                        <PiWarningDuotone size={100}/>
                    </div>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className="d-flex justify-content-center">
                <div>
                    <h4>Access Failed</h4>
                    <p>You are unauthorized to access this link, Please contact system administrator and try again!</p>
                </div>
            </Modal.Body>
            <Modal.Footer className="d-flex justify-content-center">
                <Button variant="secondary" onClick={handleClose} ref={closeButtonRef as React.RefObject<HTMLButtonElement>}>Close</Button>
            </Modal.Footer>
        </Modal>
    );
}


export const UpdateUserSuccess = () => {
    const redirectPath = useSelector((state: RootState) => state.Admin_CreateUserReducer.redirectPath);
    const closeButtonRef = useRef<HTMLButtonElement | null>(null); 
    const navigate = useNavigate();
    const [show, setShow] = useState(true);

    const handleClose = () => {
        setShow(false);
        if (redirectPath) {
            navigate(redirectPath);
            window.location.reload();
          }
    }
    useEffect(() => {
        if (show && closeButtonRef.current) {
          closeButtonRef.current.focus();
        }
      }, [show]);
    return (
        <Modal show={show} onHide={handleClose} centered>
            <Modal.Header  className="d-flex justify-content-center">
                <Modal.Title>
                    <div className='text-success'>
                        <PiCheckCircleDuotone size={100}/>
                    </div>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className="d-flex justify-content-center">
                <div>
                    <h4>Transaction successful!</h4>
                    <p>User account updated successfully.</p>
                </div>
            </Modal.Body>
            <Modal.Footer className="d-flex justify-content-center">
                {/* <Button variant="secondary" onClick={handleClose} >Yes</Button> */}
                <Button variant="secondary" onClick={handleClose} ref={closeButtonRef as React.RefObject<HTMLButtonElement>}>Close</Button>
            </Modal.Footer>
        </Modal>
    );
}


export const UpdateUserFailed = () => {
    const closeButtonRef = useRef<HTMLButtonElement | null>(null); 
    const [show, setShow] = useState(true);
    const handleClose = () => setShow(false);
    useEffect(() => {
        if (show && closeButtonRef.current) {
          closeButtonRef.current.focus();
        }
      }, [show]);
    return (
        <Modal show={show} onHide={handleClose} centered>
            <Modal.Header  className="d-flex justify-content-center">
                <Modal.Title>
                    <div className='text-danger'>
                        <PiWarningDuotone size={100}/>
                    </div>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className="d-flex justify-content-center">
                <div>
                    <h4>Transaction Failed</h4>
                    <p>Server might be down or unavailable, or email might be invalid or non-existent email address. Please contact system administrator and try again!</p>
                </div>
            </Modal.Body>
            <Modal.Footer className="d-flex justify-content-center">
                <Button variant="secondary" onClick={handleClose} ref={closeButtonRef as React.RefObject<HTMLButtonElement>}>Close</Button>
            </Modal.Footer>
        </Modal>
    );
}

