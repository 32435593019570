import { InfoState } from './headersTypes';
import {  UpdateUserAction, ActionTypes } from './headersActions';

const initialState: InfoState = {
  info: null,
  loading: false,
  error: null,
  redirectPath: null,
  password_info: null,
  password_loading: false,
  password_error: null,
  password_redirectPath: null,
};

const createReducer = (state = initialState, action: UpdateUserAction): InfoState => {
  switch (action.type) {
  // ***** Create Admin User *****
    case ActionTypes.UPDATE_USER_REQUEST:
      return { ...state, loading: true, error: null };
    case ActionTypes.UPDATE_USER_SUCCESS:
      return { ...state, info: action.payload, loading: false, error: null };
    case ActionTypes.UPDATE_USER_FAILURE:
      return { ...state, loading: false, error: action.payload };
    case ActionTypes.UPDATE_USER_REDIRECT:
      return {...state, redirectPath: action.payload };
  // ***** Access Settings *****
    // case ActionTypes.ADMIN_CREATE_AUTH_REQUEST:
    //   return { ...state, loading: true, error: null };
    // case ActionTypes.ADMIN_CREATE_AUTH_SUCCESS:
    //   return { ...state, info: action.payload, loading: false, error: null };
    // case ActionTypes.ADMIN_CREATE_AUTH_FAILURE:
    //   return { ...state, loading: false, error: action.payload };
    // case ActionTypes.ADMIN_CREATE_AUTH_REDIRECT:
    //   return {...state, redirectPath: action.payload };
    // ***** Update Password User *****
    case ActionTypes.UPDATE_PASSWORD_REQUEST:
      return { ...state, password_loading: true, password_error: null };
    case ActionTypes.UPDATE_PASSWORD_SUCCESS:
      return { ...state, password_info: action.payload, password_loading: false, password_error: null };
    case ActionTypes.UPDATE_PASSWORD_FAILURE:
      return { ...state, password_loading: false, password_error: action.payload };
    case ActionTypes.UPDATE_PASSWORD_REDIRECT:
      return {...state, password_redirectPath: action.payload };
    default:
      return state;
  }
};

export default createReducer;