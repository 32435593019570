import React, { useEffect, useState } from 'react';
import {
  Button,
  Container,
  Row,
  Alert,
  Col
} from 'react-bootstrap';
import { AiOutlineSend } from "react-icons/ai";
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import { RiLockPasswordLine } from "react-icons/ri";
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { forgotRequest } from './redux/loginActions';
import logo from '../../../images/linao.png';
import { useNavigate } from 'react-router-dom';
import { RootState } from '../../../redux/reducers';
import { LoadingPage } from '../../../components/loader';
import { ForgotResetFailed, ForgotResetSuccess } from './loader';
import { getLocalStorageVariable } from '../../../components/localStorage';
import map from '../../../images/casa1.jpg';

const LoginSchema = Yup.object().shape({
    email: Yup
        .string()
        .email('Invalid email')
        .required('Email is required!'),
    confirm: Yup
        .bool()
        .oneOf([true], "Please confirm")
        .required("Please confirm"),
    pin: Yup.string()
        .trim()
        .max(6, 'Must be 6 character only')
        .matches(/^[0-9]{6}$/, "Please input 6-digit pin number code")
        .required('PIN is required'),
});

const Forgot: React.FC = () => {
  const loading = useSelector((state: RootState) => state.LoginReducer.loading);
  const success = useSelector((state: RootState) => state.LoginReducer.user);
  const failed = useSelector((state: RootState) => state.LoginReducer.error);
  
  const [randomString, setRandomString] = useState<string>('');
  
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const generateRandomString = (length: number): string => {
    const characters = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz';
    let result = '';
    for (let i = 0; i < length; i++) {
      const randomIndex = Math.floor(Math.random() * characters.length);
      result += characters.charAt(randomIndex);
    }
    return result;
  };

  useEffect(() => {
      const generatedString = generateRandomString(6);
      setRandomString(generatedString);
  }, []);

  useEffect(() => {
    const userId = getLocalStorageVariable<string>('userId');
    const userAuth = getLocalStorageVariable<string>('userAuth');
    if (userId && userAuth!='client') {
      navigate('/admin_dashboard'); 
    }
  }, [navigate, dispatch]);

  const handleSubmit = (values: any) => {
    const payload = {
        email: values.email,
        pin: values.pin,
        password: randomString,
      }
      dispatch(forgotRequest(payload));
    };

  return (
    <Formik
      initialValues={{ 
        email: '', 
        pin: "",     
        confirm: false, 
      }}
      validationSchema={LoginSchema}
      onSubmit={handleSubmit}
    >
      {({ errors, touched }) => (
      <Container>
        {loading?<LoadingPage/>:""}
        {failed?<ForgotResetFailed/>:""}
        {success?<ForgotResetSuccess/>:""}
        
        <Row className="justify-content-md-center">
          <Col sm lg="7" className=" bg-white mt-3">
            <div className='d-none d-lg-block'>
                <div style={{ width: '100%', maxWidth: '1000px', height: '85vh', margin: '0 auto' }}>
                <iframe
                  width="100%"
                  height="100%"
                  frameBorder="0"
                  style={{ border: 0 }}
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3860.5820633614415!2d123.8103591!3d10.2684819!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x33a99dd46e4cfd23%3A0x486702a3de45aa30!2sCasa+Mira+Linao!5e0!3m2!1sen!2sph!4v1699773526753"
                  allowFullScreen
                  aria-hidden="false"
                  tabIndex={0}
                ></iframe>
              </div>
            </div>
            {/* <div className='d-none d-lg-block'>
              <img
                src={map}
                alt="Subdivision Map"
                useMap="#housesMap"
                width="100%"
              />
            </div> */}
          </Col>
          <Col sm className=" bg-white">
            
          </Col>
          <Col sm lg="4" className="my-3 p-3 bg-white rounded shadow">
              <div className="text-info text-center mt-3"> <img src={`${logo}`} alt="" width="180" height="110"/></div>
              <hr/>
              
              <Alert variant="warning">
                  <Alert.Heading><RiLockPasswordLine /> Forgot Password?</Alert.Heading>
                    <hr />
                    <p className="mb-0">
                        To reset your password :<br/>&nbsp; ○ Input your email address and PIN Code. <br/>&nbsp; ○ Check your email for your temporary password.
                    </p>
              </Alert>
                <Form>
                 
                  <Row>
                      <Col sm>
                        <FloatingLabel
                            label="Email address"
                            className="mb-3"
                          >
                            <Field
                              type="email"
                              name="email"
                              placeholder="E-mail"
                              className={`w-100 form-control ${touched.email && errors.email ? 'is-invalid' : touched.email ? 'is-valid' : ''}`}
                            />
                            <ErrorMessage name="email">
                              {msg => <div className="invalid-feedback">{msg}</div>}
                            </ErrorMessage>
                          </FloatingLabel> 
                      </Col>
                    </Row>

                    <Row>
                      <Col sm>   
                        <FloatingLabel
                            label="PIN"
                            className="mb-3"
                          >
                            <Field
                              type="text"
                              name="pin"
                              maxLength="6"
                              placeholder="PIN"
                              className={`w-100 form-control ${touched.pin && errors.pin ? 'is-invalid' : touched.pin ? 'is-valid' : ''}`}
                            />
                            <ErrorMessage name="pin">
                              {msg => <div className="invalid-feedback">{msg}</div>}
                            </ErrorMessage>
                          </FloatingLabel>
                      </Col>
                    </Row>
                    <br/>
                        <Col sm> 
                            <label>
                                <Field type="checkbox" name="confirm" />
                                &nbsp;Please confirm to reset.
                            </label>
                            <ErrorMessage name="confirm">
                                {msg => <div style={{color:'red',padding:'5px'}}>{msg}</div>}
                            </ErrorMessage>
                        </Col>
                    <br/>
                  <div className="d-grid gap-2">
                      <Button variant="primary" type="submit" className="btn btn-primary btn-block rounded-pill mb-5" >
                          <AiOutlineSend size="20"/> Reset
                      </Button>
                  </div>
                </Form>
          </Col>
        </Row>
      </Container>
      )}
    </Formik>
  );
};

export default Forgot;
