import React, { useState, useEffect, useCallback } from 'react';
import axios, { AxiosResponse } from 'axios';
import { 
    Row, 
    Col,
    Button,
    Alert,
    Breadcrumb,
} from 'react-bootstrap';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { AiOutlineSend, AiOutlineForm, AiOutlineCheckCircle , AiOutlineCloseCircle } from "react-icons/ai";
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import SubMenu from '../submenu';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { RootState } from '../../../redux/reducers';
import { updateRequest } from './redux/adminUserActions';
import { LoadingPage } from '../../../components/loader';
import { UpdateUserSuccess, UpdateUserFailed, AuthAccessFailed } from './loader';
import { getLocalStorageVariable } from '../../../components/localStorage';
import { getSessionVariable } from '../../../components/sessionStorage';
import { setSessionVariable } from '../../../components/sessionStorage';
import CryptoJS from 'crypto-js';

const CreateSchema = Yup.object().shape({
    account: Yup.string()
        .max(10, 'Must be 10 characters or less')
        .required("Account # is required"),
    fname: Yup.string()
        .trim()
        .max(30, 'Must be 30 characters or less')
        .matches(/^([a-z\s A-Z])+$/, "Valid characters from A-Z only")
        .required("Firstname is required"),
    mi: Yup.string()
        .trim()
        .max(1, 'Must be 1 character only')
        .matches(/^([a-z\s A-Z])+$/, "Valid characters from A-Z only"),
    lname: Yup.string()
        .trim()
        .max(30, 'Must be 30 characters or less')
        .matches(/^([a-z\s A-Z])+$/, "Valid characters from A-Z only")
        .required("Lastname is required."),
    phase: Yup.string()
        .trim()
        .max(5, 'Must be 5 characters or less')
        .matches(/^([a-z\s A-Z\s 0-9])+$/, "Please input valid cellphone # ex: 09XXXXXXXX.")
        .required("Phase is required."),
    block: Yup.string()
        .trim()
        .max(5, 'Must be 5 characters or less')
        .matches(/^\d+$/, "Please input valid numbers.")
        .required("Block number is required."),
    lot: Yup.string()
        .trim()
        .max(5, 'Must be 5 characters or less')
        .matches(/^\d+$/, "Please input valid numbers.")
        .required("Lot number is required."),
    lotarea: Yup.string()
        .trim()
        .max(10, 'Must be 10 characters or less')
        .matches(/^\d*\.?\d*$/, "Please input valid numbers.")
        .required("Lot area is required."),
    floorarea: Yup.string()
        .trim()
        .max(10, 'Must be 10 characters or less')
        .matches(/^\d*\.?\d*$/, "Please input valid numbers.")
        .required("Floor area is required."),
    dues: Yup.string()
        .trim()
        .max(10, 'Must be 10 characters or less')
        .matches(/^\d*\.?\d*$/, "Please input valid numbers.")
        .required("Monthly Dues is required."),
    confirm: Yup
        .bool()
        .oneOf([true], "Please confirm.")
        .required("Please confirm."),
  });


const Update_User: React.FC = () =>{
    const failed = useSelector((state: RootState) => state.Admin_CreateUserReducer.error);
    const loading = useSelector((state: RootState) => state.Admin_CreateUserReducer.loading);
    const success = useSelector((state: RootState) => state.Admin_CreateUserReducer.info);
    const token = getLocalStorageVariable<string>('token');
    const user_update = getLocalStorageVariable<string>('user_update');

    const account = getSessionVariable<any>('account');
    const fname = getSessionVariable<any>('fname');
    const mi = getSessionVariable<any>('mi');
    const lname = getSessionVariable<any>('lname');
    const phase = getSessionVariable<any>('phase');
    const block = getSessionVariable<any>('block');
    const lot = getSessionVariable<any>('lot');
    const lotarea = getSessionVariable<any>('lotarea');
    const floorarea = getSessionVariable<any>('floorarea');
    const dues = getSessionVariable<any>('dues');
    const status = getSessionVariable<any>('status');

    const dispatch = useDispatch();
    const navigate = useNavigate();

    // ****** decrypt Data *****
    const encryptionKey = process.env.REACT_APP_DE_EN ?? ''; 
    const ivHex = '00000000000000000000000000000000'; // Fixed IV as used in the backend

    const decryptData = (encryptedData: string, encryptionKey: string): string => {
        const key = CryptoJS.enc.Utf8.parse(encryptionKey); // Use Utf8 parsing for consistency
        const iv = CryptoJS.enc.Hex.parse(ivHex);
    
        // Convert the hex string to bytes, then decrypt
        const encryptedHexStr = CryptoJS.enc.Hex.parse(encryptedData); // Interpret hex as bytes
        const encryptedBase64 = CryptoJS.enc.Base64.stringify(encryptedHexStr); // Convert bytes to Base64
    
        // Decrypt the Base64 string
        const bytes = CryptoJS.AES.decrypt(encryptedBase64, key, {
        iv: iv,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7,
        });
    
        return bytes.toString(CryptoJS.enc.Utf8);
    };

    
    useEffect(() => {
        if (!account || !token) {
            navigate('/admin_update_user_list'); 
        }
    }, [ navigate, account, token ]);

    const handleItemClick = (path: string) => {
        setSessionVariable('setSelectedItem', path);
        navigate(path);
    };
   
    const handleSubmit = (values: any) => {
        const payload = {
            account: values.account,
            fname: values.fname,
            mi: values.mi,
            lname: values.lname,
            lotarea: values.lotarea,
            floorarea: values.floorarea,
            dues: values.dues,
            security: values.security,
            answer: values.answer,
            cellphone: values.cellphone,
            email: values.email,
        }
        dispatch(updateRequest(payload));
      };

    return (
        <Formik
            enableReinitialize={true}
            initialValues={{ 
                account: account || '',
                fname: decryptData(fname, encryptionKey) || '',
                mi: mi || '',
                lname: decryptData(lname, encryptionKey) || '',
                phase: phase || '',
                block: block || '',
                lot: lot || '',
                lotarea: lotarea || '',
                floorarea: floorarea || '',
                dues: dues || '',
                confirm: false,
            }}
            validationSchema={CreateSchema}
            onSubmit={handleSubmit}
            >
            {({ errors, touched }) => (
            <div className="container-fluid ">
                {loading?<LoadingPage/>:""}
                {failed?<UpdateUserFailed/>:""}
                {success?<UpdateUserSuccess/>:""}
                {user_update != '1'? <AuthAccessFailed />:""}

                <Row className="justify-content-md-center">
                    <Col>
                        <SubMenu/>
                    </Col>
                    <Col xs lg="10"  className="p-3 bg-white rounded shadow-sm ">
                        <h4 className="text-primary mb-3" ><AiOutlineForm size="30" /> Update User Account</h4>
                            <Breadcrumb>
                                <Breadcrumb.Item onClick={() => handleItemClick('/admin_user_menu')}>User Menu</Breadcrumb.Item>
                                <Breadcrumb.Item href="/admin_update_user_list">Update User List</Breadcrumb.Item>
                                <Breadcrumb.Item active>Update User</Breadcrumb.Item>
                            </Breadcrumb>
                            
                        <hr/>
                        <div style = {{ width:"400px"}} />
                        <Alert style={{border: "1px solid rgb(128, 128, 128)", backgroundColor:'#c9dbff'}} className='mt-2 shadow'>
                        <Form>
                            <Row>
                                <Col sm>
                                    <FloatingLabel label="Account Number" className="mb-2">
                                        <Field
                                            type="text"
                                            name="account"
                                            maxLength="10"
                                            disabled={true}
                                            placeholder="Account Number"
                                            className={`rounded w-100 form-control ${touched.account && errors.account ? 'is-invalid' : touched.account ? 'is-valid' : ''}`}
                                        />
                                        <ErrorMessage name="account">
                                            {msg => <div className="invalid-feedback">{msg}</div>}
                                        </ErrorMessage>
                                    </FloatingLabel>
                                </Col>
                                <Col sm>
                                    {status==1?
                                        <h4 className="text-success mt-1 mb-2" ><strong><AiOutlineCheckCircle  size="25" /> Active</strong></h4>:
                                        <h4 className="text-danger mt-1 mb-2" ><strong><AiOutlineCloseCircle size="25" /> Inactive</strong></h4>}
                                </Col>
                                <Col sm>
                                </Col>
                            </Row>

                            <Row>
                                <Col sm>
                                    <FloatingLabel label="Phase" className="mb-2">
                                        <Field
                                            type="text"
                                            name="phase"
                                            maxLength="5"
                                            disabled={true}
                                            placeholder="Phase"
                                            className={`rounded w-100 form-control ${touched.phase && errors.phase ? 'is-invalid' : touched.phase ? 'is-valid' : ''}`}
                                        />
                                        <ErrorMessage name="phase">
                                            {msg => <div className="invalid-feedback">{msg}</div>}
                                        </ErrorMessage>
                                    </FloatingLabel>
                                </Col>
                                <Col sm>
                                    <FloatingLabel label="Block" className="mb-2">
                                        <Field
                                            type="text"
                                            name="block"
                                            maxLength="5"
                                            disabled={true}
                                            placeholder="Block"
                                            className={`rounded w-100 form-control ${touched.block && errors.block ? 'is-invalid' : touched.block ? 'is-valid' : ''}`}
                                        />
                                        <ErrorMessage name="block">
                                            {msg => <div className="invalid-feedback">{msg}</div>}
                                        </ErrorMessage>
                                    </FloatingLabel>
                                </Col>
                                <Col sm>
                                    <FloatingLabel label="Lot" className="mb-2">
                                        <Field
                                            type="text"
                                            name="lot"
                                            maxLength="5"
                                            disabled={true}
                                            placeholder="Lot"
                                            className={`rounded w-100 form-control ${touched.lot && errors.lot ? 'is-invalid' : touched.lot ? 'is-valid' : ''}`}
                                        />
                                        <ErrorMessage name="lot">
                                            {msg => <div className="invalid-feedback">{msg}</div>}
                                        </ErrorMessage>
                                    </FloatingLabel>
                                </Col>
                            </Row>

                            <Row>
                                <Col sm>
                                    <FloatingLabel label="Firstname" className="mb-2">
                                        <Field
                                            type="text"
                                            name="fname"
                                            maxLength="30"
                                            placeholder="Firstname"
                                            className={`rounded w-100 form-control ${touched.fname && errors.fname ? 'is-invalid' : touched.fname ? 'is-valid' : ''}`}
                                        />
                                        <ErrorMessage name="fname">
                                            {msg => <div className="invalid-feedback">{msg}</div>}
                                        </ErrorMessage>
                                    </FloatingLabel>
                                </Col>
                                <Col sm>
                                    <FloatingLabel label="MI" className="mb-2">
                                        <Field
                                            type="text"
                                            name="mi"
                                            maxLength="1"
                                            placeholder="MI"
                                            className={`rounded w-100 form-control ${touched.mi && errors.mi ? 'is-invalid' : touched.mi ? 'is-valid' : ''}`}
                                        />
                                        <ErrorMessage name="mi">
                                            {msg => <div className="invalid-feedback">{msg}</div>}
                                        </ErrorMessage>
                                    </FloatingLabel>
                                </Col>
                                <Col sm>
                                    <FloatingLabel label="Lastname" className="mb-2">
                                        <Field
                                            type="text"
                                            name="lname"
                                            maxLength="30"
                                            placeholder="Lastname"
                                            className={`rounded w-100 form-control ${touched.lname && errors.lname ? 'is-invalid' : touched.lname ? 'is-valid' : ''}`}
                                        />
                                        <ErrorMessage name="lname">
                                            {msg => <div className="invalid-feedback">{msg}</div>}
                                        </ErrorMessage>
                                    </FloatingLabel>
                                </Col>
                            </Row>

                            <Row>
                                <Col sm>
                                    <FloatingLabel label="Lot Area" className="mb-2">
                                        <Field
                                            type="text"
                                            name="lotarea"
                                            maxLength="10"
                                            placeholder="Lot Area"
                                            className={`rounded w-100 form-control ${touched.lotarea && errors.lotarea ? 'is-invalid' : touched.lotarea ? 'is-valid' : ''}`}
                                        />
                                        <ErrorMessage name="lotarea">
                                            {msg => <div className="invalid-feedback">{msg}</div>}
                                        </ErrorMessage>
                                    </FloatingLabel>
                                </Col>
                                <Col sm>
                                    <FloatingLabel label="Floor Area" className="mb-2">
                                        <Field
                                            type="text"
                                            name="floorarea"
                                            maxLength="10"
                                            placeholder="Floor Area"
                                            className={`rounded w-100 form-control ${touched.floorarea && errors.floorarea ? 'is-invalid' : touched.floorarea ? 'is-valid' : ''}`}
                                        />
                                        <ErrorMessage name="floorarea">
                                            {msg => <div className="invalid-feedback">{msg}</div>}
                                        </ErrorMessage>
                                    </FloatingLabel>
                                </Col>
                                <Col sm>
                                    <FloatingLabel label="Dues" className="mb-2">
                                        <Field
                                            type="text"
                                            name="dues"
                                            maxLength="10"
                                            placeholder="Dues"
                                            className={`rounded w-100 form-control ${touched.dues && errors.dues ? 'is-invalid' : touched.dues ? 'is-valid' : ''}`}
                                        />
                                        <ErrorMessage name="dues">
                                            {msg => <div className="invalid-feedback">{msg}</div>}
                                        </ErrorMessage>
                                    </FloatingLabel>
                                </Col>
                            </Row>

                            <br/>
                                <Col sm> 
                                    <label>
                                        <Field type="checkbox" name="confirm" />
                                        &nbsp;Please confirm for updating the account.
                                    </label>
                                    <ErrorMessage name="confirm">
                                        {msg => <div style={{color:'red',padding:'5px'}}>{msg}</div>}
                                    </ErrorMessage>
                                </Col>
                            <br/>
                            <div className="d-grid gap-2">
                                <Button variant="primary" type="submit" className="btn btn-primary btn-block rounded-pill mb-3" >
                                    <AiOutlineSend size="20"/> Submit
                                </Button>
                            </div>
                        </Form>
                        </Alert>
                    </Col>
                </Row>
            </div>
            )}
        </Formik>
    );
};

export default Update_User;