import React, {useEffect} from 'react';
import { 
    Row, 
    Col,
    Card,
} from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { AiOutlineUserAdd, AiOutlineUser, AiOutlineForm, AiOutlineUnorderedList, AiOutlineUserSwitch, AiOutlineMessage, AiOutlineUsergroupAdd } from "react-icons/ai";
import { PiUsersThree } from "react-icons/pi";
import SubMenu from '../submenu';

const UserMenu: React.FC = () =>{
    useEffect(() => {
        const keys = Object.keys(sessionStorage);
    
        keys.forEach((key) => {
          if (key !== 'setSelectedItem') {
            sessionStorage.removeItem(key);
          }
        });
      }, []);
      
    return (
        <div className="container-fluid ">
            <Row className="justify-content-md-center">
                <Col sm={2}>
                    <SubMenu/>
                </Col>
                <Col xs lg="10"  className="p-3 bg-white rounded shadow-sm ">
                    <h4 className="text-primary" ><AiOutlineUser size="30" /> Billing Account Menu</h4>
                    <hr/>
                    <div style = {{ width:"400px"}} ></div>
                    <Row xs={1} md={4} className="g-4">
                        <Col>
                            <Link to="/admin_create_user" style={{ textDecoration: 'none'}}>
                                <Card className="mb-3 rounded shadow-lg justify-content-between align-items-center bg-primary bg-opacity-75">
                                    <Card.Body >
                                    <Card.Title className='text-white text-center'><AiOutlineUserAdd size="90" /><br/><br/>Create User</Card.Title>
                                    </Card.Body>
                                </Card>
                            </Link>
                        </Col>
                        <Col>
                            <Link to="/admin_upload_users" style={{ textDecoration: 'none'}}>
                                <Card className="mb-3 rounded shadow-lg justify-content-between align-items-center bg-primary bg-opacity-75">
                                    <Card.Body >
                                    <Card.Title className='text-white text-center'><AiOutlineUsergroupAdd size="90" /><br/><br/>Multiple Users</Card.Title>
                                    </Card.Body>
                                </Card>
                            </Link>
                        </Col>
                        <Col>
                            <Link to="/admin_update_user_list" style={{ textDecoration: 'none'}}>
                                <Card className="mb-3 rounded shadow-lg justify-content-between align-items-center bg-primary bg-opacity-75">
                                    <Card.Body >
                                    <Card.Title className='text-white text-center'><AiOutlineForm  size="90" /><br/><br/>Update User</Card.Title>
                                    </Card.Body>
                                </Card>
                            </Link>
                        </Col>
                        {/* <Col>
                            <Link to="/admin_status_update" style={{ textDecoration: 'none'}}>
                                <Card className="mb-3 rounded shadow-lg justify-content-between align-items-center bg-primary bg-opacity-75">
                                    <Card.Body >
                                    <Card.Title className='text-white text-center'><AiOutlineUserSwitch size="90" /><br/><br/>Status Update</Card.Title>
                                    </Card.Body>
                                </Card>
                            </Link>
                        </Col> */}
                        {/* <Col>
                            <Link to="/admin_message_user" style={{ textDecoration: 'none'}}>
                                <Card className="mb-3 rounded shadow-lg justify-content-between align-items-center bg-primary bg-opacity-75">
                                    <Card.Body >
                                    <Card.Title className='text-white text-center'><AiOutlineMessage size="90" /><br/><br/>Message User</Card.Title>
                                    </Card.Body>
                                </Card>
                            </Link>
                        </Col> */}
                        <Col>
                            <Link to="/admin_user_list" style={{ textDecoration: 'none'}}>
                                <Card className="mb-3 rounded shadow-lg justify-content-between align-items-center bg-primary bg-opacity-75">
                                    <Card.Body >
                                    <Card.Title className='text-white text-center'><AiOutlineUnorderedList  size="90" /><br/><br/>User List</Card.Title>
                                    </Card.Body>
                                </Card>
                            </Link>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </div>
    );
};

export default UserMenu;