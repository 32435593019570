import React, { useEffect, useState, useCallback } from 'react';
// import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import axios, { AxiosResponse } from 'axios';
import { 
    Row,
    Col,
    Alert,
    Card,
    Image,
} from 'react-bootstrap';
import { Link } from 'react-router-dom';
import SubMenu from '../submenu';
import { AiOutlineDashboard, AiOutlineDollar, AiOutlineGroup } from "react-icons/ai";
import { IoWaterOutline } from "react-icons/io5";
import { MdOutlineBalance } from "react-icons/md";
import ASSOCIATION_BARCHART from './association_barchart';
import ASSOCIATION_PIECHART from './association_piechart';
import WATER_BARCHART from './water_barchart';
import WATER_PIECHART from './water_piechart';
import apiUrl from '../../../components/apiurl';
import { getLocalStorageVariable } from '../../../components/localStorage';
import CryptoJS from 'crypto-js';


interface UserData {
  id: number;
  auth: string;
  fname: string;
  mi: string;
  lname: string;
  cellphone: string;
  email: string;
  // phase: string;
  // block: string;
  // lot: string;
}

const Dashboard: React.FC = () =>{
  const userId = getLocalStorageVariable<number>('userId');
  const userAuth = getLocalStorageVariable<string>('userAuth');
  const userAvatar = getLocalStorageVariable<string>('avatar');
  const token = getLocalStorageVariable<string>('token');
  // const [avatar, setAvatar] = useState<string | null>(userAvatar);
  // const [modalShow, setModalShow] = useState(false);

  // const [dataUser, setUser] = useState<UserData[] | null>(null);
  // const [auth, setAuth] = useState('');
  const [fname, setFname] = useState('');
  const [mi, setMi] = useState('');
  const [lname, setLname] = useState('');
  const [cellphone, setCellphone] = useState('');
  const [email, setEmail] = useState('');
  // const [phase, setPhase] = useState('');
  // const [block, setBlock] = useState('');
  // const [lot, setLot] = useState('');

  // ****** decrypt Data *****
  const encryptionKey = process.env.REACT_APP_DE_EN ?? ''; 
  const ivHex = '00000000000000000000000000000000'; // Fixed IV as used in the backend

  const decryptData = (encryptedData: string, encryptionKey: string): string => {
    const key = CryptoJS.enc.Utf8.parse(encryptionKey); // Use Utf8 parsing for consistency
    const iv = CryptoJS.enc.Hex.parse(ivHex);
  
    // Convert the hex string to bytes, then decrypt
    const encryptedHexStr = CryptoJS.enc.Hex.parse(encryptedData); // Interpret hex as bytes
    const encryptedBase64 = CryptoJS.enc.Base64.stringify(encryptedHexStr); // Convert bytes to Base64
  
    // Decrypt the Base64 string
    const bytes = CryptoJS.AES.decrypt(encryptedBase64, key, {
      iv: iv,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7,
    });
  
    return bytes.toString(CryptoJS.enc.Utf8);
  };


// ****** Mask display for email *****
  const maskEmail = (email: string): string => {
    // Split the email into the part before and after the '@' symbol
    const [name, domain] = email.split('@');
    
    // Partially mask the first part of the email
    const maskedName = name.slice(0, 2) + '****';
  
    // Return the masked email
    return `${maskedName}@${domain}`;
  };  

// ****** Mask display for cellphone *****
  const maskCellphone = (cellphone: string): string => {
    // Show only the last 4 digits, replace the rest with asterisks
    return cellphone.slice(0, 2) + "******" + cellphone.slice(-3);
  };

// ***** Get User *****
  const fetchUser = useCallback(async () => {
    const headers = {
        Authorization: `${token}`,
      };
    try {
        const res: AxiosResponse<UserData[]> = await axios.get(`${apiUrl.url}admin_get_admin_user/profile`, { headers, params: { id: userId } });
        // setUser(res.data.length > 0 ? res.data : null);
        // setAuth(res.data[0].auth);
        setFname(decryptData(res.data[0].fname, encryptionKey));
        setMi(res.data[0].mi);
        setLname(decryptData(res.data[0].lname, encryptionKey));
        setCellphone(decryptData(res.data[0].cellphone, encryptionKey));
        setEmail(decryptData(res.data[0].email, encryptionKey));
        // setPhase(res.data[0].phase);
        // setBlock(res.data[0].block);
        // setLot(res.data[0].lot);
    } catch (error) {
        console.error('Error: ', error);
    }
  }, [userId, token]);

  useEffect(() => {
      fetchUser();
  }, [ fetchUser ]);

    return (
        <div className="container-fluid"> 
            <Row className="justify-content-md-center">
                <Col sm={2}>
                    <SubMenu/>
                </Col>
                <Col xs lg="10"  className="p-3 bg-white rounded shadow-sm">
                    <Row className="justify-content-md-center">
                        <Col >
                        <div style = {{ width:"400px"}} />
                        <h4 className="text-primary" ><AiOutlineDashboard size="30"/> Dashboard</h4>
                        <hr/>
                            <Alert style={{border: "1px solid rgb(128, 128, 128)"}} className='bg-white shadow-lg mb-0'>
                              <Row>
                                <Col sm={2} className='text-center'>
                                  <Image src={userAvatar || '/static/media/1.1f1d0984d6e0f46c338d.jpg'} width="100" height="100" roundedCircle /><br/>
                                </Col>
                                <Col sm={9}>
                                  <Row >
                                      <Col sm={2} className='text-muted'>
                                          User ID
                                      </Col>
                                      <Col sm>
                                          <strong>{userId}</strong>
                                      </Col>
                                  </Row>
                                  <Row >
                                      <Col sm={2} className='text-muted'>
                                          Name
                                      </Col>
                                      <Col sm>
                                          <strong>{fname} {mi} {lname}</strong>
                                      </Col>
                                  </Row>
                                  <Row >
                                      <Col sm={2} className='text-muted'>
                                          Access
                                      </Col>
                                      <Col sm>
                                          <strong>{userAuth}</strong>
                                      </Col>
                                  </Row>
                                  <Row >
                                      <Col sm={2} className='text-muted'>
                                          Email 
                                      </Col>
                                      <Col sm>
                                          <strong>{maskEmail(email)}</strong>
                                      </Col>
                                      <Col sm={2} className='text-muted'>
                                          Cellphone
                                      </Col>
                                      <Col sm>
                                          <strong>{maskCellphone(cellphone)}</strong>
                                      </Col>
                                  </Row>
                                </Col>
                              </Row>
                            </Alert>
                        </Col>
                    </Row>
                    <Row xs={1} md={4} className="g-4 mt-0">
                      <Col>
                        <Link to="/admin_association_menu" style={{ textDecoration: 'none'}}>
                          <Card className="m-1 rounded shadow-lg">
                            <Card.Body>
                              <div style={{ position: 'absolute', height: '10px', left: 30, top: 20 }}>
                                  {/* <FeatherIcon icon="credit-card" size="40" className="text-info" stroke-width="1" /> */}
                                  <AiOutlineGroup size="50" className="text-success"/>
                              </div>
                              {/* <Card.Title><h3>{totinv.toLocaleString("en-US", { maximumFractionDigits: 2, minimumFractionDigits: 2 })}</h3></Card.Title> */}
                              <Card.Title className='text-end'><h3>0.00</h3></Card.Title>
                                <Card.Text className='pt-3 text-end'>
                                  Total Amount
                                </Card.Text>
                            </Card.Body>
                            <Card.Footer className="bg-success">
                              <big className="text-light">Association</big>
                            </Card.Footer>
                          </Card>
                        </Link>
                      </Col>
                      <Col>
                        <Link to="/admin_water_menu" style={{ textDecoration: 'none'}}>
                          <Card className="m-1 rounded shadow-lg">
                            <Card.Body className='text-right'>
                              <div style={{ position: 'absolute', height: '10px', left: 30, top: 20 }}>
                                  {/* <FeatherIcon icon="droplet" size="40" className="text-success" stroke-width="1" /> */}
                                  <IoWaterOutline size="50" className="text-info"/>
                              </div>
                              <Card.Title className='text-end'><h3>0.00</h3></Card.Title>
                                <Card.Text className='pt-3 text-end'>
                                  Total Amount
                                </Card.Text>
                            </Card.Body>
                            <Card.Footer className="bg-info">
                              <big className="text-light">Water</big>
                            </Card.Footer>
                          </Card>
                        </Link>
                      </Col>
                      <Col>
                        <Link to="/admin_payment_menu" style={{ textDecoration: 'none'}}>
                          <Card className="m-1 rounded shadow-lg">
                            <Card.Body className='text-right'>
                              <div style={{ position: 'absolute', height: '10px', left: 30, top: 20 }}>
                                  {/* <FeatherIcon icon="dollar-sign" size="40" className="text-warning" stroke-width="1" /> */}
                                  <AiOutlineDollar size="50" className="text-danger"/>
                              </div>
                              {/* <Card.Title><h3>{totpay.toLocaleString("en-US", { maximumFractionDigits: 2, minimumFractionDigits: 2 })}</h3></Card.Title> */}
                              <Card.Title className='text-end'><h3>0.00</h3></Card.Title>
                                <Card.Text className='pt-3 text-end'>
                                  Total Amount
                                </Card.Text>
                            </Card.Body>
                            <Card.Footer className="bg-danger">
                              <big className="text-light">Payment</big>
                            </Card.Footer>
                          </Card>
                        </Link>
                      </Col>
                      <Col>
                        <Link to="/bills" style={{ textDecoration: 'none'}}>
                          <Card className="m-1 rounded shadow-lg">
                            <Card.Body className='text-right'>
                              <div style={{ position: 'absolute', height: '10px', left: 30, top: 20 }}>
                                  {/* <FeatherIcon icon="book" size="40" className="text-danger" stroke-width="1" /> */}
                                  <MdOutlineBalance size="50" className="text-warning"/>
                              </div>
                              {/* <Card.Title><h3>{totbal.toLocaleString("en-US", { maximumFractionDigits: 2, minimumFractionDigits: 2 })}</h3></Card.Title> */}
                              <Card.Title className='text-end'><h3>0.00</h3></Card.Title>
                                <Card.Text className='pt-3 text-end'>
                                  Total Amount
                                </Card.Text>
                            </Card.Body>
                            <Card.Footer className="bg-warning">
                              <big className="text-light">Balance</big>
                            </Card.Footer>
                          </Card>
                        </Link>
                      </Col>
                    </Row>
                    
                    <Row xs={1} md={1} className="g-4 mt-3">
                      <Col className="text-center">
                        Total association bills and total payment per month.
                      <h4 className="text-primary" ><AiOutlineGroup size="30"/> Association Bar Chart</h4>
                        <ASSOCIATION_BARCHART/>
                      </Col>
                      {/* <Col className="text-center">
                        Total water bills and total payment per month.
                      <h4 className="text-info" ><IoWaterOutline size="30"/> Water Bar Chart</h4>
                        <Water_BarChart/>
                      </Col> */}
                    </Row>

                    <Row xs={1} md={1} className="g-4 mt-3">
                      {/* <Col className="text-center">
                        Total association bills and total payment per month.
                      <h4 className="text-primary" ><AiOutlineGroup size="30"/> Association Bar Chart</h4>
                        <Association_BarChart/>
                      </Col> */}
                      <Col className="text-center">
                        Total water bills and total payment per month.
                      <h4 className="text-info" ><IoWaterOutline size="30"/> Water Bar Chart</h4>
                        <WATER_BARCHART/>
                      </Col>
                    </Row>

                    <Row xs={1} md={2} className="g-4 mt-3">
                      <Col className="text-center">
                        Total association bills, total receivables and total payment.
                        <h4 className="text-primary" ><AiOutlineGroup size="30"/> Association Pie Chart</h4>
                        <ASSOCIATION_PIECHART/>
                      </Col>
                      <Col className="text-center">
                        Total water bills, total receivables and total payment.
                        <h4 className="text-info" ><IoWaterOutline size="30"/> Water Pie Chart</h4>
                        <WATER_PIECHART/>
                      </Col>
                    </Row>
                </Col>
            </Row>
        </div>
    );
};

export default Dashboard;