import React, { useState, useEffect, useCallback } from 'react';
import axios, { AxiosResponse } from 'axios';
import { 
    Row, 
    Col,
    Button,
    Table,
    Breadcrumb,
    Pagination,
    Modal,
} from 'react-bootstrap';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import { AiOutlineForm, AiOutlineEdit, AiOutlineDelete } from "react-icons/ai";
import { FaSort, FaSortUp, FaSortDown } from "react-icons/fa";
import { BiSearchAlt } from "react-icons/bi";
import { PiWarningDuotone } from "react-icons/pi";
import SubMenu from '../submenu';
import { useNavigate } from 'react-router-dom';
import { AuthAccessFailed } from '../../../components/loader';
import { getLocalStorageVariable } from '../../../components/localStorage';
import { setSessionVariable } from '../../../components/sessionStorage';
import apiUrl from '../../../components/apiurl';
import CryptoJS from 'crypto-js';

const CreateSchema = Yup.object().shape({
    search: Yup.string()
        .trim(),
  });

const UpdateSchema = Yup.object().shape({
    id: Yup.string()
        .trim(),
  });

  interface UserData {
    id: number;
    account: string;
    fname: string;
    mi: string;
    lname: string;
    phase: string;
    block: string;
    lot: string;
    lot_area: string;
    floor_area: string;
    dues: string;
    security: string;
    answer: string;
    cellphone: string;
    email: string;
    status: number;
  }

const ITEMS_PER_PAGE = 20;

interface SortConfig {
    key: keyof UserData;
    direction: 'asc' | 'desc';
  }

const Update_User_List: React.FC = () =>{
    const token = getLocalStorageVariable<string>('token');
    const [dataUser, setUser] = useState<UserData[] | null>(null);
    const [searchTerm, setSearchTerm] = useState('');
    const user_update = getLocalStorageVariable<string>('user_update');
    const navigate = useNavigate();
    const [showModal, setShowModal] = useState(false);
    const [updateValues, setUpdateValues] = useState({
        id: 0,
        status: 0,
    });

    // Pagination
    const [currentPage, setCurrentPage] = useState(1);

    // Pagination calculation
    const indexOfLastItem = currentPage * ITEMS_PER_PAGE;
    const indexOfFirstItem = indexOfLastItem - ITEMS_PER_PAGE;

    // ****** decrypt Data *****
    const encryptionKey = process.env.REACT_APP_DE_EN ?? ''; 
    const ivHex = '00000000000000000000000000000000'; // Fixed IV as used in the backend
 
    const decryptData = (encryptedData: string, encryptionKey: string): string => {
        try {
            const key = CryptoJS.enc.Utf8.parse(encryptionKey); // Use Utf8 parsing for consistency
            const iv = CryptoJS.enc.Hex.parse(ivHex);
    
            // Ensure the encryptedData is a valid hex string
            if (!/^[a-fA-F0-9]+$/.test(encryptedData)) {
                console.error("Invalid encrypted data format:", encryptedData);
                return ""; // Return an empty string or a placeholder on error
            }
    
            // Convert the hex string to bytes, then decrypt
            const encryptedHexStr = CryptoJS.enc.Hex.parse(encryptedData); // Interpret hex as bytes
            const encryptedBase64 = CryptoJS.enc.Base64.stringify(encryptedHexStr); // Convert bytes to Base64
    
            // Decrypt the Base64 string
            const bytes = CryptoJS.AES.decrypt(encryptedBase64, key, {
                iv: iv,
                mode: CryptoJS.mode.CBC,
                padding: CryptoJS.pad.Pkcs7,
            });
    
            const decrypted = bytes.toString(CryptoJS.enc.Utf8);
    
            if (!decrypted) {
                console.error("Decryption failed:", encryptedData);
                return ""; // Return an empty string on failure
            }
    
            return decrypted;
        } catch (error) {
            console.error("Error decrypting data:", error, encryptedData);
            return ""; // Return an empty string or placeholder if decryption fails
        }
    };
    
    // ***** Handle Search *****
    const handleSearch = (values: { search: string }) => {
        setCurrentPage(1); // Reset pagination
        applyLocalSearch(values.search); // Filter data locally
    };
    
    const applyLocalSearch = (searchTerm: string) => {
        if (!originalUserData) {
            setUser(null); // If originalUserData is null, set filtered data to null
            return;
        }
        
        // Perform a case-insensitive search in fname, lname, and other fields
        const filteredData = originalUserData.filter((user) => {
            const searchLower = searchTerm.toLowerCase();
            return (
                user.account?.toLowerCase().includes(searchLower) ||
                user.fname?.toLowerCase().includes(searchLower) ||
                user.lname?.toLowerCase().includes(searchLower) ||
                user.phase?.toLowerCase().includes(searchLower) ||
                user.block?.toLowerCase().includes(searchLower) ||
                user.lot?.toLowerCase().includes(searchLower)
            );
        });
        setUser(filteredData.length > 0 ? filteredData : null);
    };
    
    // Store the original data for local search
    const [originalUserData, setOriginalUserData] = useState<UserData[] | null>(null);
    
    // Updated fetchUser logic to store the original data
    const fetchUser = useCallback(async () => {
        const headers = {
            Authorization: `${token}`,
        };
    
        try {
            const res: AxiosResponse<UserData[]> = await axios.get(`${apiUrl.url}admin_get_user`,{ headers, params: { search: searchTerm } });
    
            if (res.data.length > 0) {
                const decryptedData = res.data.map((user) => ({
                    ...user, fname: decryptData(user.fname, encryptionKey), lname: decryptData(user.lname, encryptionKey),
                }));
                setOriginalUserData(decryptedData); // Store the original data for filtering
                setUser(decryptedData); // Set the data to be displayed
            } else {
                setOriginalUserData(null);
                setUser(null);
            }
        } catch (error) {
            console.error('Error fetching user data:', error);
        }
    }, [searchTerm, token, encryptionKey]);


    useEffect(() => {
        fetchUser();
    }, [searchTerm, fetchUser]);

    const handleUpdate = async(values: any) => {
        const { id } = values;
        const headers = {
            Authorization: `${token}`,
          };
        try {
            const res: AxiosResponse<UserData[]> = await axios.post(`${apiUrl.url}admin_update_user_select/`, { id: id }, { headers });
            setSessionVariable('user_id', res.data[0].id);
            setSessionVariable('account', res.data[0].account);
            setSessionVariable('fname', res.data[0].fname);
            setSessionVariable('mi', res.data[0].mi);
            setSessionVariable('lname', res.data[0].lname);
            setSessionVariable('phase', res.data[0].phase);
            setSessionVariable('block', res.data[0].block);
            setSessionVariable('lot', res.data[0].lot);
            setSessionVariable('lotarea', res.data[0].lot_area);
            setSessionVariable('floorarea', res.data[0].floor_area);
            setSessionVariable('dues', res.data[0].dues);
            setSessionVariable('status', res.data[0].status);
            
            navigate('/admin_update_user');
        } catch (error) {
            console.error('Error: ', error);
        }
      };
    

// **** Handle Update Status Button Click ****
    const handleStatusUpdate = (values: any) => {
        setUpdateValues(values);
        setShowModal(true);
    };

    const executeUpdate = async () => {
        const { id, status } = updateValues;
        const headers = {
            Authorization: `${token}`,
          };
        try {
            const res: AxiosResponse<UserData[]> = await axios.post(`${apiUrl.url}admin_status_update/`, { id: id, status: status }, { headers });
            window.location.reload();
            setShowModal(false);
        } catch (error) {
            console.error('Error: ', error);
        }
      };

    const handleCloseModal = () => {
        setShowModal(false);
    };

    const handleItemClick = (path: string) => {
        setSessionVariable('setSelectedItem', path);
        navigate(path);
    };

    // ***** Sorting Code *****
    const [sortConfig, setSortConfig] = useState<SortConfig | null>(null);

    const sortedData = React.useMemo(() => {
      if (!dataUser || !sortConfig) return dataUser;
        const sortedArray = [...dataUser];
        sortedArray.sort((a, b) => {
            const aValue = a[sortConfig.key];
            const bValue = b[sortConfig.key];
            if (aValue < bValue) return sortConfig.direction === 'asc' ? -1 : 1;
            if (aValue > bValue) return sortConfig.direction === 'asc' ? 1 : -1;
            return 0;
        });
      return sortedArray;
    }, [dataUser, sortConfig]);

    const handleSort = (key: keyof UserData) => {
        setSortConfig((prevSortConfig) => {
          if (prevSortConfig && prevSortConfig.key === key) {
            return {
              key,
              direction: prevSortConfig.direction === 'asc' ? 'desc' : 'asc',
            };
          } else {
            return { key, direction: 'asc' };
          }
        });
    };

    // Apply pagination after sorting
    const paginatedData = React.useMemo(() => {
        return sortedData?.slice(indexOfFirstItem, indexOfLastItem);
    }, [sortedData, indexOfFirstItem, indexOfLastItem]);

    // Pagination handler
    const paginate = (pageNumber: number) => setCurrentPage(pageNumber);
    const totalPages = Math.ceil((sortedData?.length || 0) / ITEMS_PER_PAGE);

    return (
            <div className="container-fluid ">
                {user_update != '1'? <AuthAccessFailed />:""}

                <Row className="justify-content-md-center">
                    <Col>
                        <SubMenu/>
                    </Col>
                    <Col xs lg="10"  className="p-3 bg-white rounded shadow-sm ">
                        <h4 className="text-primary mb-3" ><AiOutlineForm size="30" /> Update User Account</h4>
                            <Breadcrumb>
                                <Breadcrumb.Item onClick={() => handleItemClick('/admin_user_menu')}>User Menu</Breadcrumb.Item>
                                <Breadcrumb.Item active>Update User List</Breadcrumb.Item>
                            </Breadcrumb>
                        <hr/>
                        <div style = {{ width:"400px"}} />
                         <Formik
                            enableReinitialize={true}
                            initialValues={{ 
                                search: "",
                            }}
                            validationSchema={CreateSchema}
                            onSubmit={handleSearch}
                            >
                                {({ errors, touched }) => (
                                <Form>
                                    <Row>
                                        <Col xs lg="6"  className="bg-white rounded">
                                            <FloatingLabel label="&#x1F50E; Search..." className="mb-2">
                                                <Field
                                                    type="search"
                                                    name="search"
                                                    maxLength="50"
                                                    placeholder="&#x1F50E; Search..."
                                                    style={{border: "1px solid rgb(128, 128, 128)"}}
                                                    className={`rounded w-100 form-control ${touched.search && errors.search ? 'is-invalid' : touched.search ? 'is-valid' : ''}`}
                                                />
                                                <ErrorMessage name="search">
                                                    {msg => <div className="invalid-feedback">{msg}</div>}
                                                </ErrorMessage>
                                            </FloatingLabel>
                                            <Button type="submit" variant="outline-success" style={{display:'none'}}><BiSearchAlt size="20" className='ms-2 me-2'/></Button>
                                        </Col>
                                    </Row>
                                </Form>
                                )}
                            </Formik>

                                <Table responsive striped bordered hover size="sm">
                                    <thead>
                                        <tr className="text-center bg-secondary" style={{cursor: 'pointer'}}>
                                            <th className="bg-secondary text-white">#</th>
                                            <th onClick={() => handleSort('account')} className="sortable bg-secondary text-white">
                                                Account # &emsp;{sortConfig?.key === 'account' ? (sortConfig.direction === 'asc' ? <FaSortUp /> : <FaSortDown />) : <FaSort />}
                                            </th>
                                            <th onClick={() => handleSort('lname')} className="sortable bg-secondary text-white" >
                                                Name &emsp; {sortConfig?.key === 'lname' ? (sortConfig.direction === 'asc' ? <FaSortUp /> : <FaSortDown />) : <FaSort />}
                                            </th>
                                            <th onClick={() => handleSort('phase')} className="sortable bg-secondary text-white">
                                                Phase &emsp; {sortConfig?.key === 'phase' ? (sortConfig.direction === 'asc' ? <FaSortUp /> : <FaSortDown />) : <FaSort />}
                                            </th>
                                            <th onClick={() => handleSort('block')} className="sortable bg-secondary text-white">
                                                Block &emsp; {sortConfig?.key === 'block' ? (sortConfig.direction === 'asc' ? <FaSortUp /> : <FaSortDown />) : <FaSort />}
                                            </th>
                                            <th onClick={() => handleSort('lot')} className="sortable bg-secondary text-white">
                                                Lot &emsp; {sortConfig?.key === 'lot' ? (sortConfig.direction === 'asc' ? <FaSortUp /> : <FaSortDown />) : <FaSort />}
                                            </th>
                                            <th onClick={() => handleSort('status')} className="sortable bg-secondary text-white">
                                                Status &emsp; {sortConfig?.key === 'status' ? (sortConfig.direction === 'asc' ? <FaSortUp /> : <FaSortDown />) : <FaSort />}
                                            </th>
                                            <th colSpan={2} className="bg-secondary text-white">Actions</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {paginatedData?.length ? (
                                            paginatedData.map((r, i) => (
                                                <tr key={i} className="align-middle text-secondary">
                                                    <td className="text-center align-middle">{i + 1 + indexOfFirstItem}</td>
                                                    <td className="text-center align-middle">{r.account}</td>
                                                    <td className="">
                                                        {r.fname} {r.mi}. {r.lname}
                                                    </td>
                                                    <td className="text-center align-middle">{r.phase}</td>
                                                    <td className="text-center align-middle">{r.block}</td>
                                                    <td className="text-center align-middle">{r.lot}</td>
                                                    <td className="text-center align-middle">
                                                        {r.status === 1 ? <strong className='text-success'>Active</strong> : <strong className='text-danger'>Inactive</strong>}
                                                    </td>
                                                    <td className="text-center align-middle">
                                                        <Formik
                                                            enableReinitialize={true}
                                                            initialValues={{ 
                                                                id: r.id,
                                                            }}
                                                            validationSchema={UpdateSchema}
                                                            onSubmit={handleUpdate}
                                                                >
                                                            <Form>
                                                                <Field type="text" name={r.id} value={r.id} hidden></Field>
                                                                <Button type="submit" variant="outline-success" size="sm" className='border-none me-2 btn btn-block rounded'><AiOutlineEdit /> Update</Button>
                                                            </Form>
                                                        </Formik>
                                                    </td>
                                                    <td className="text-center align-middle">
                                                        <Formik
                                                            enableReinitialize={true}
                                                            initialValues={{ 
                                                                id: r.id,
                                                                status: r.status,
                                                            }}
                                                            validationSchema={UpdateSchema}
                                                            onSubmit={handleStatusUpdate}
                                                                >
                                                            <Form>
                                                                <Field type="text" name={r.id} value={r.id} hidden disabled={true}></Field>
                                                                <Field type="text" name='status' value={r.status} hidden disabled={true}></Field>
                                                                {r.status==1?
                                                                    <Button type="submit" variant="outline-danger" size="sm" className='me-2 btn btn-block rounded'><AiOutlineDelete /> Deactivate</Button>:
                                                                    <Button type="submit" variant="outline-primary" size="sm" className='me-2 btn btn-block rounded'><AiOutlineEdit /> Activate</Button>}
                                                            </Form>
                                                        </Formik>
                                                    </td>
                                                </tr>
                                            ))
                                        ) : (
                                            <tr>
                                                <td colSpan={9} className='text-danger text-center'>No user account found...</td>
                                            </tr>
                                        )}
                                    </tbody>
                                </Table>

                                {/* Confirmation Modal */}
                                <Modal show={showModal} onHide={handleCloseModal} centered variant='light'>
                                    <Modal.Header className="d-flex justify-content-center">
                                        <Modal.Title><PiWarningDuotone size="80" /></Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body className="d-flex justify-content-center">
                                        <div>
                                            <h4>User Status Update!</h4>
                                            <p>Are you sure to change the user status?</p>
                                        </div>
                                    </Modal.Body>
                                    <Modal.Footer className="d-flex justify-content-center">
                                        <Button variant="secondary" onClick={handleCloseModal}>No</Button>
                                        <Button variant="primary" onClick={executeUpdate}>Yes</Button>
                                    </Modal.Footer>
                                </Modal>

                            {dataUser?.length && dataUser.length > ITEMS_PER_PAGE && (
                                <Pagination>
                                    <Pagination.First onClick={() => paginate(1)} />
                                    <Pagination.Prev onClick={() => paginate(currentPage - 1)} />

                                    {Array.from({ length: totalPages }).map((_, index) => {
                                        // Show the current page and two pages before and after it
                                        if (index >= currentPage - 2 && index <= currentPage + 2) {
                                        return (
                                            <Pagination.Item
                                            key={index}
                                            active={index + 1 === currentPage}
                                            onClick={() => paginate(index + 1)}
                                            >
                                            {index + 1}
                                            </Pagination.Item>
                                        );
                                        }

                                        return null;
                                    })}

                                    {/* Show ellipsis between Pagination.Prev and Pagination.Next */}
                                    {currentPage + 2 < totalPages && (
                                        <Pagination.Ellipsis key={`ellipsis`} />
                                    )}

                                    <Pagination.Next onClick={() => paginate(currentPage + 1)} />
                                    <Pagination.Last onClick={() => paginate(totalPages)} />
                                </Pagination>
                            )}
                    </Col>
                </Row>
            </div>
    );
};

export default Update_User_List;