import React from 'react';
import { 
    Row, 
    Col,
    Card,
} from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { AiOutlineSetting, AiOutlineTeam, AiOutlineControl, AiOutlineReconciliation, AiOutlineUserSwitch } from "react-icons/ai";
import { IoArchiveOutline } from "react-icons/io5";
import { LuDatabaseBackup } from "react-icons/lu";
import { MdOutlineMonitor } from "react-icons/md";
import SubMenu from '../submenu';


const SettingsMenu: React.FC = () =>{
    return (
        <div className="container-fluid">
            <Row className="justify-content-md-center">
                <Col sm={2}>
                    <SubMenu/>
                </Col>
                <Col xs lg="10"  className="p-3 bg-white rounded shadow-sm ">
                    <h4 className="text-warning" ><AiOutlineSetting size="30" /> Settings Menu</h4>
                    <hr/>
                    <div style = {{ width:"400px"}} ></div>
                    <Row xs={1} md={4} className="g-4">
                        <Col>
                            <Link to="/admin_settings_create_admin_user" style={{ textDecoration: 'none'}}>
                                <Card className="mb-3 rounded shadow-lg justify-content-between align-items-center">
                                    <Card.Body >
                                        <Card.Title className='text-warning'>
                                            <div className="text-center w-100"><AiOutlineTeam size="90" /></div><br/>Management User
                                        </Card.Title>
                                    </Card.Body>
                                </Card>
                            </Link>
                        </Col>
                        <Col>
                            <Link to="/admin_settings_create_admin_user" style={{ textDecoration: 'none'}}>
                                <Card className="mb-3 rounded shadow-lg justify-content-between align-items-center">
                                    <Card.Body >
                                        <Card.Title className='text-warning'>
                                            <div className="text-center w-100"><AiOutlineUserSwitch size="90" /></div><br/>Status Update
                                        </Card.Title>
                                    </Card.Body>
                                </Card>
                            </Link>
                        </Col>
                        <Col>
                            <Link to="/admin_settings_access_settings" style={{ textDecoration: 'none'}}>
                                <Card className="mb-3 rounded shadow-lg justify-content-between align-items-center">
                                    <Card.Body >
                                        <Card.Title className='text-warning'>
                                            <div className="text-center w-100"><AiOutlineControl  size="90" /></div><br/>Access Settings
                                        </Card.Title>
                                    </Card.Body>
                                </Card>
                            </Link>
                        </Col>
                        <Col>
                            <Link to="/bills_ad_deleteuser" style={{ textDecoration: 'none'}}>
                                <Card className="mb-3 rounded shadow-lg justify-content-between align-items-center">
                                    <Card.Body >
                                    <Card.Title className='text-warning text-center'><LuDatabaseBackup size="90" /><br/><br/>DB Backup</Card.Title>
                                    </Card.Body>
                                </Card>
                            </Link>
                        </Col>
                        <Col>
                            <Link to="/bills_ad_searchactivateuser" style={{ textDecoration: 'none'}}>
                                <Card className="mb-3 rounded shadow-lg justify-content-between align-items-center">
                                    <Card.Body >
                                    <Card.Title className='text-warning text-center'><IoArchiveOutline size="90" /><br/><br/>Archive</Card.Title>
                                    </Card.Body>
                                </Card>
                            </Link>
                        </Col>
                        <Col>
                            <Link to="/admin_settings_monitoring" style={{ textDecoration: 'none'}}>
                                <Card className="mb-3 rounded shadow-lg justify-content-between align-items-center">
                                    <Card.Body >
                                    <Card.Title className='text-warning text-center'><MdOutlineMonitor  size="90" /><br/><br/>Monitoring</Card.Title>
                                    </Card.Body>
                                </Card>
                            </Link>
                        </Col>
                        <Col>
                            <Link to="/bills_ad_userlist" style={{ textDecoration: 'none'}}>
                                <Card className="mb-3 rounded shadow-lg justify-content-between align-items-center">
                                    <Card.Body >
                                    <Card.Title className='text-warning text-center'><AiOutlineReconciliation  size="90" /><br/><br/>Reconcile</Card.Title>
                                    </Card.Body>
                                </Card>
                            </Link>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </div>
    );
};

export default SettingsMenu;