import React, { useEffect, useState } from 'react';
import {
  Button,
  Container,
  Row,
  Alert,
  Col
} from 'react-bootstrap';
import { PiEyeLight } from "react-icons/pi";
import { PiEyeSlash } from "react-icons/pi";
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import { AiOutlineLogin } from "react-icons/ai";
import { AiOutlineSend } from "react-icons/ai";
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { admin_loginRequest } from './redux/loginActions';
import logo from '../../../images/linao.png';
import { useNavigate } from 'react-router-dom';
import { RootState } from '../../../redux/reducers';
import { LoadingPage } from '../../../components/loader';
import { LoginFailed } from './loader';
import { getLocalStorageVariable } from '../../../components/localStorage';
import me from '../../../images/casa1.jpg';

const LoginSchema = Yup.object().shape({
  email: Yup
    .string()
    .email('Invalid email')
    .required('Email is required!'),
  password: Yup
    .string()
    .required('Password is required!'),
  authCode: Yup
    .string()
    .max(6, 'Must be 6 character only')
    .matches(/^[0-9]{6}$/, "Please input 6-digit code from Authenticator.")
    .required('Authentication code is required!'),
});

const Admin_Login: React.FC = () => {
  const redirectPath = useSelector((state: RootState) => state.Admin_LoginReducer.redirectPath);
  const failed = useSelector((state: RootState) => state.Admin_LoginReducer.error);
  const loading = useSelector((state: RootState) => state.Admin_LoginReducer.loading);
  
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    const userId = getLocalStorageVariable<string>('userId');
    const userAuth = getLocalStorageVariable<string>('userAuth');
    if (userId && userAuth!='client') {
      navigate('/admin_dashboard'); 
    }

    if (redirectPath) {
      navigate(redirectPath);
      window.location.reload();
    }
  }, [redirectPath, navigate, dispatch]);

  const handleSubmit = (values: any) => {
    const { email, password, authCode } = values;
    dispatch(admin_loginRequest(email, password, authCode));
  };

  const [showPassword, setShowPassword] = useState(false); // State for password visibility
  const togglePasswordVisibility = () => {
    setShowPassword(prevState => !prevState); // Toggle password visibility
  };


  return (
    <Formik
      initialValues={{ email: '', password: '', authCode: '' }}
      validationSchema={LoginSchema}
      onSubmit={handleSubmit}
    >
      {({ errors, touched }) => (
      <Container>
        {loading?<LoadingPage/>:""}
        {failed?<LoginFailed/>:""}
        <Row className="justify-content-md-center">
          <Col sm lg="5" className=" bg-white">
            <div className='d-none d-lg-block'>
              <img
                src={me}
                alt="Subdivision Map"
                useMap="#housesMap"
                width="100%"
              />
            </div>
          </Col>
          <Col sm lg="2" className=" bg-white">
            
          </Col>
          <Col sm lg="4" className="my-3 p-3 bg-white rounded shadow">
              <div className="text-info text-center mt-3"> <img src={`${logo}`} alt="" width="180" height="110"/></div>
              <hr/>
              
              <Alert variant="danger">
                  <Alert.Heading><AiOutlineLogin size="25" /> Management Login</Alert.Heading>
              </Alert>
                <Form>
                 
                  <Row>
                      <Col sm>
                        <FloatingLabel label="E-mail address" className="mb-2">
                          <Field
                            type="email"
                            name="email"
                            placeholder="E-mail"
                            className={`w-100 form-control ${touched.email && errors.email ? 'is-invalid' : touched.email ? 'is-valid' : ''}`}
                          />
                          <ErrorMessage name="email">
                            {msg => <div className="invalid-feedback">{msg}</div>}
                          </ErrorMessage>
                        </FloatingLabel>
                      </Col>
                    </Row>

                    <Row>
                        <Col sm>   
                          <FloatingLabel label="Password" className="mb-2">
                            <Field
                              type={showPassword ? 'text' : 'password'} // Toggle between text and password
                              name="password"
                              placeholder="Password"
                              className={`w-100 form-control ${touched.password && errors.password ? 'is-invalid' : touched.password ? 'is-valid' : ''}`}
                            />
                              <span 
                                onClick={togglePasswordVisibility}
                                style={{
                                  position: 'absolute',
                                  right: '30px',
                                  top: '50%',
                                  transform: 'translateY(-50%)',
                                  cursor: 'pointer',
                                  color: '#6c757d' // Optional: adjust color as needed
                                }}
                              >
                                {showPassword ? <PiEyeSlash size='30' /> : <PiEyeLight size='30' />}
                              </span>
                            <ErrorMessage name="password">
                              {msg => <div className="invalid-feedback">{msg}</div>}
                            </ErrorMessage>
                          </FloatingLabel>
                        </Col>
                    </Row>

                    <Row>
                        <Col sm>
                          <FloatingLabel label="Authentication Code" className="mb-2">
                            <Field
                              type="text" 
                              name="authCode"
                              maxLength="6"
                              placeholder="Authentication Code"
                              className={`w-100 form-control ${touched.authCode && errors.authCode ? 'is-invalid' : touched.authCode ? 'is-valid' : ''}`}
                            />
                            <ErrorMessage name="authCode">
                              {msg => <div className="invalid-feedback">{msg}</div>}
                            </ErrorMessage>
                          </FloatingLabel>
                        </Col>
                    </Row>

                    <br/>
                    <div className="d-flex justify-content-between align-items-center w-100">
                        <a href="/admin_forgot" style={{ textDecoration: 'none' }}>Forgot password?</a>
                    </div>
                    {/* <ReCAPTCHA sitekey="6Le73ZQaAAAAAMiABk5UPq2s-nPc1RCVS0opsrp8" onChange={onChange} /> */}
                    <br/>
                  <div className="d-grid gap-2">
                      <Button variant="primary" type="submit" className="btn btn-primary btn-block rounded-pill mb-5" >
                          <AiOutlineSend size="20"/> Login
                      </Button>
                  </div>
                </Form>
          </Col>
        </Row>
      </Container>
      )}
    </Formik>
  );
};

export default Admin_Login;
