import React, { useState, useEffect, useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import axios, { AxiosResponse } from 'axios';
import apiUrl from '../../../components/apiurl';
import { Alert, Image } from 'react-bootstrap';
import { PiWarningDuotone } from "react-icons/pi";
import { PiCheckCircleDuotone } from "react-icons/pi";

const Verify_Email: React.FC = () => {
    const [code, setCode] = useState('');
    const [account, setAccount] = useState('');
    const [success, setSuccess] = useState<boolean | null>(null); // Track success status
    const location = useLocation(); // Access the current location

    // Extract query parameters from the URL
    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        setCode(queryParams.get('verify_code') || ''); // Default to '' if not present
        setAccount(queryParams.get('account') || '');
    }, [location]);

    const verifyUser = useCallback(async () => {
        try {
            const res: AxiosResponse = await axios.get(`${apiUrl.url}activation/verify/`, {
                params: { verify_code: code, account: account },
            });
            console.log('User verified:', res.data);
            // Update success state based on response
            setSuccess(res.data.success);
        } catch (error) {
            console.error('Error: ', error);
            setSuccess(false); // Handle error as failure
        }
    }, [code, account]);

    useEffect(() => {
        if (code && account) {
            verifyUser();
        }
    }, [code, account, verifyUser]);

    return (
        <div className="container-fluid" style={{height:'300px'}}>
            {success === null ? (
                <p>Verifying...</p>
            ) : success ? (
                <div>
                    <div className='text-success w-100 text-center'>
                        <PiCheckCircleDuotone size={100}/>
                    </div>
                    <Alert key='success' variant='success'>
                        Your email has been successfully verified.
                    </Alert>
                </div>
            ) : (
                <div>
                    <div className='text-danger w-100 text-center'>
                        <PiWarningDuotone size={100}/>
                    </div>
                    <Alert key='danger' variant='danger'>
                        Verification failed or your email is already verified. <br/>Please try again.
                    </Alert>
                </div>
            )}
        </div>
    );
};

export default Verify_Email;
