import { call, put, takeLatest } from 'redux-saga/effects';
import { AxiosResponse } from 'axios';
import { 
  PaymentActionTypes, 
  paymentSuccess, 
  paymentFailure, 
  paymentRedirect, 
  paymentSearchSuccess,
  paymentSearchFailure,
} from './adminPaymentActions';
import apiUrl from '../../../../components/apiurl';
import axios from 'axios';
import { getLocalStorageVariable } from '../../../../components/localStorage';
import { setSessionVariable } from '../../../../components/sessionStorage';

// ***** Create Association Bill ****
function* payment_create(action: any): Generator<any, void, AxiosResponse> {
  try {
    const token = getLocalStorageVariable<string>('token');
    const headers = {
      Authorization: `${token}`,
    };
    
    const response: AxiosResponse = yield call(axios.post, `${apiUrl.url}admin_create_association_bill`, action.payload, { headers });
    const user = response.data;

      if(user!=''){
        yield put(paymentSuccess(user));
        yield put(paymentRedirect('/admin_association_menu'));
        // window.location.reload();
        // console.log(user)
      }else{
        yield put(paymentFailure('failed'));
      }
  } catch (error) {
    const errorMessage = (error as any).message; 
    yield put(paymentFailure(errorMessage));
  }
}


// ***** Search User Account *****
function* payment_search(action: any): Generator<any, void, AxiosResponse> {
  try {
    const token = getLocalStorageVariable<string>('token');
    const headers = {
      Authorization: `${token}`,
    };
    
    const response: AxiosResponse = yield call(axios.post, `${apiUrl.url}admin_payment/search_user`, action.payload, { headers });
    const user = response.data;
    
      if(user!=''){
        yield put(paymentSearchSuccess(user));
        // yield put(associationSearchRedirect('/admin_get_association/'));
        setSessionVariable('user_id', user[0].id);
        setSessionVariable('account', user[0].account);
        setSessionVariable('fname', user[0].fname);
        setSessionVariable('mi', user[0].mi);
        setSessionVariable('lname', user[0].lname);
        setSessionVariable('phase', user[0].phase);
        setSessionVariable('block', user[0].block);
        setSessionVariable('lot', user[0].lot);
        setSessionVariable('lot_area', user[0].lot_area);
        setSessionVariable('dues', user[0].dues);
        setSessionVariable('email', user[0].email);
        setSessionVariable('cellphone', user[0].cellphone);
        window.location.reload();
      }else{
        yield put(paymentSearchFailure('failed'));
      }
  } catch (error) {
    const errorMessage = (error as any).message; 
    yield put(paymentSearchFailure(errorMessage));
  }
}


// // ***** Credit Memo *****
// function* credit_memo(action: any): Generator<any, void, AxiosResponse> {
//   try {
//     const token = getLocalStorageVariable<string>('token');
//     const headers = {
//       Authorization: `${token}`,
//     };
    
//     const response: AxiosResponse = yield call(axios.post, `${apiUrl.url}admin_credit_memo`, action.payload, { headers });
//     const user = response.data;

//       if(user!=''){
//         yield put(creditMemoSuccess(user));
//         yield put(creditMemoRedirect('/admin_association_credit_memo'));
//         sessionStorage.clear();
//         // console.log(user)
//       }else{
//         yield put(creditMemoFailure('failed'));
//       }
//   } catch (error) {
//     const errorMessage = (error as any).message; 
//     yield put(creditMemoFailure(errorMessage));
//   }
// }

// // ***** Debit Memo *****
// function* debit_memo(action: any): Generator<any, void, AxiosResponse> {
//   try {
//     const token = getLocalStorageVariable<string>('token');
//     const headers = {
//       Authorization: `${token}`,
//     };
    
//     const response: AxiosResponse = yield call(axios.post, `${apiUrl.url}admin_debit_memo`, action.payload, { headers });
//     const user = response.data;

//       if(user!=''){
//         yield put(debitMemoSuccess(user));
//         yield put(debitMemoRedirect('/admin_association_debit_memo'));
//         sessionStorage.clear();
//         // console.log(user)
//       }else{
//         yield put(debitMemoFailure('failed'));
//       }
//   } catch (error) {
//     const errorMessage = (error as any).message; 
//     yield put(debitMemoFailure(errorMessage));
//   }
// }

// ***** watchers *****
function* watchAssociation() {
  yield takeLatest(PaymentActionTypes.ADMIN_PAYMENT_CREATE_REQUEST, payment_create);
  yield takeLatest(PaymentActionTypes.ADMIN_PAYMENT_SEARCH_REQUEST, payment_search);
//   yield takeLatest(AssociationActionTypes.CREDIT_MEMO_REQUEST, credit_memo);
//   yield takeLatest(AssociationActionTypes.DEBIT_MEMO_REQUEST, debit_memo);
}

export default watchAssociation;
