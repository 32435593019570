import { call, put, takeLatest } from 'redux-saga/effects';
import { AxiosResponse } from 'axios';
import { 
  ActionTypes, 
  createSuccess, 
  createFailure, 
  redirect,
  updateSuccess,
  updateFailure,
  updateRedirect,
} from './adminUserActions';
import apiUrl from '../../../../components/apiurl';
import axios from 'axios';
import { getLocalStorageVariable } from '../../../../components/localStorage';

// ***** Create User *****
function* adminCreateUser(action: any): Generator<any, void, AxiosResponse> {
  try {
    const token = getLocalStorageVariable<string>('token');
    const headers = {
      Authorization: `${token}`,
    };

    const response: AxiosResponse = yield call(axios.post, `${apiUrl.url}admin_create_user`, action.payload, { headers });
    const user = response.data;

      if(user!=''){
        yield put(createSuccess(user));
        yield put(redirect('/admin_create_user'));
        // console.log(user)
      }else{
        yield put(createFailure('failed'));
      }
  } catch (error) {
    const errorMessage = (error as any).message; 
    yield put(createFailure(errorMessage));
  }
}

// ***** Update User *****
function* adminUpdateUser(action: any): Generator<any, void, AxiosResponse> {
  try {
    const token = getLocalStorageVariable<string>('token');
    const headers = {
      Authorization: `${token}`,
    };

    const response: AxiosResponse = yield call(axios.post, `${apiUrl.url}admin_update_user`, action.payload, { headers });
    const user = response.data;

      if(user!=''){
        yield put(updateSuccess(user));
        yield put(updateRedirect('/admin_update_user_list'));
      }else{
        yield put(updateFailure('failed'));
      }
  } catch (error) {
    const errorMessage = (error as any).message; 
    yield put(updateFailure(errorMessage));
  }
}

function* watchAdminCreateUser() {
  yield takeLatest(ActionTypes.ADMIN_CREATE_USER_REQUEST, adminCreateUser);
  yield takeLatest(ActionTypes.ADMIN_UPDATE_USER_REQUEST, adminUpdateUser);
}

export default watchAdminCreateUser;
