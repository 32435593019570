import React, { useState, useEffect, useCallback } from "react";
import axios, { AxiosResponse } from "axios";
import { Table, Row, Col, Breadcrumb } from "react-bootstrap";
import { Formik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { RootState } from "../../../redux/reducers";
import { getLocalStorageVariable } from "../../../components/localStorage";
import { AiOutlineControl } from "react-icons/ai";
import apiUrl from "../../../components/apiurl";
import SubMenu from '../submenu';
import { setSessionVariable } from '../../../components/sessionStorage';

interface UserLogData {
  log_id: number;
  action_time: string;
  action_type: string;
  user_id: string;
  old_data: string;
  new_data: string;
}

function getDifference(oldStr: string | null | undefined, newStr: string | null | undefined): string {
    if (!oldStr) oldStr = ""; // Set to empty string if null or undefined
    if (!newStr) newStr = ""; // Set to empty string if null or undefined
  
    let result = "";
    const oldWords = oldStr.split(" ");
    const newWords = newStr.split(" ");
  
    // Find differences and highlight them
    oldWords.forEach((word, index) => {
      if (newWords[index] !== word) {
        result += `<span style="color:red; text-decoration:line-through;">${word}</span> `;
      } else {
        result += `${word} `;
      }
    });
  
    newWords.slice(oldWords.length).forEach((word) => {
      result += `<span style="color:green;">${word}</span> `;
    });
  
    return result.trim();
  }
  

const Monitoring: React.FC = () => {
  const token = getLocalStorageVariable<string>("token");
  const [data, setData] = useState<UserLogData[] | null>(null);
  const navigate = useNavigate();

  const fetchData = useCallback(async () => {
    const headers = { Authorization: `${token}` };
    try {
      const res: AxiosResponse<UserLogData[]> = await axios.get(`${apiUrl.url}logs/user`, { headers });
      setData(res.data.length > 0 ? res.data : null);
    } catch (error) {
      console.error("Error: ", error);
    }
  }, [token]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const handleItemClick = (path: string) => {
    setSessionVariable('setSelectedItem', path);
    navigate(path);
};
  return (
    <Formik initialValues={{}} onSubmit={() => {}}>
      <div className="container-fluid ">
        <Row className="justify-content-md-center">
          <Col>
            <SubMenu/>
          </Col>
          <Col xs lg="10"  className="p-3 bg-white rounded shadow-sm ">
                        <h4 className="text-warning" ><AiOutlineControl size="30" /> Access Settings</h4>
                            <Breadcrumb>
                                <Breadcrumb.Item onClick={() => handleItemClick('/admin_settings_menu')}>Settings Menu</Breadcrumb.Item>
                                <Breadcrumb.Item active>Management User</Breadcrumb.Item>
                            </Breadcrumb>
                        <hr/>
            <Table responsive striped bordered hover size="sm">
              <thead>
                <tr className="text-center">
                  {/* <th>#</th> */}
                  <th>Log ID</th>
                  <th>Action Time</th>
                  <th>Action Type</th>
                  <th>User ID</th>
                  <th>Difference</th>
                  <th>Old Data</th>
                  <th>New Data</th>
                </tr>
              </thead>
              {data ? (
                <tbody>
                  {data.map((r, i) => (
                    <tr key={i} className="align-middle text-secondary">
                      {/* <td className="text-center align-middle">{i + 1}</td> */}
                      <td className="text-center align-middle">{r.log_id}</td>
                      <td>{r.action_time}</td>
                      <td>{r.action_type}</td>
                      <td>{r.user_id}</td>
                      <td dangerouslySetInnerHTML={{ __html: getDifference(r.old_data, r.new_data) }} />
                      <td>{r.old_data}</td>
                      <td>{r.new_data}</td>
                    </tr>
                  ))}
                </tbody>
              ) : (
                <tbody>
                  <tr>
                    <td colSpan={8}>Loading...</td>
                  </tr>
                </tbody>
              )}
            </Table>
          </Col>
        </Row>
      </div>
    </Formik>
  );
};

export default Monitoring;
