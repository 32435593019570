import React from 'react';
// import logo from '../../images/logo.png';
import logo_head from '../../images/linao.png';
import { FaFacebookF, FaTwitter, FaGoogle, FaInstagram, FaLinkedin, FaGithub, FaHome, FaEnvelope, FaPhoneAlt, FaMobileAlt, FaMapMarkedAlt } from "react-icons/fa";

const Footer = () =>{
    return (
        <div>
            <footer className="text-center text-lg-start bg-body-tertiary text-muted mt-5">
                <section className="d-flex justify-content-center justify-content-lg-between p-4 border-bottom">
                    <div className="me-5 d-none d-lg-block">
                        <span>Get connected with us on social networks:</span>
                    </div>
                    <div>
                        <a href="#" className="me-4 text-reset">
                            <FaFacebookF  size="20"/>
                        </a>
                        <a href="#" className="me-4 text-reset">
                            <FaGoogle  size="20"/>
                        </a>
                        <a href="#" className="me-4 text-reset">
                            <FaInstagram  size="20"/>
                        </a>
                        <a href="#" className="me-4 text-reset">
                            <FaLinkedin  size="20"/>
                        </a>
                        <a href="#" className="me-4 text-reset">
                            <FaGithub  size="20"/>
                        </a>
                    </div>
                </section>

                <section className="">
                    <div className="container text-center text-md-start mt-5">
                    <div className="row mt-3">
                        <div className="col-md-3 col-lg-4 col-xl-3 mx-auto mb-4">
                        <h6 className="text-uppercase fw-bold mb-4">
                        <img src={`${logo_head}`} alt="" width="60" height="40"/> Casa Mira - Linao
                        </h6>
                        <p>
                        CASA MIRA - LINAO is 1.9 km from the Cebu South National Hiway and 5 minutes away from SRP. 
                        Acessible to Star Mall at Lawaan, Jolibee, Robinsons Supermarket, both Public and Private Schools.
                        </p>
                        </div>
                        <div className="col-md-2 col-lg-2 col-xl-2 mx-auto mb-4">
                            <h6 className="text-uppercase fw-bold mb-4">
                                Services
                            </h6>
                            <p>
                                <a href="/booking" className="text-reset" style={{ textDecoration: 'none'}}>Reservation</a>
                            </p>
                            <p>
                                <a href="/billing" className="text-reset" style={{ textDecoration: 'none'}}>Gate Pass</a>
                            </p>
                            <p>
                                <a href="/shopping" className="text-reset" style={{ textDecoration: 'none'}}>Request Approval</a>
                            </p>
                            {/* <p>
                                <a href="/enrollment" className="text-reset" style={{ textDecoration: 'none'}}>Enrollment</a>
                            </p> */}
                        </div>
                        <div className="col-md-3 col-lg-2 col-xl-2 mx-auto mb-4">
                            <h6 className="text-uppercase fw-bold mb-4">
                                Useful links
                            </h6>
                            <p>
                                <a href="/pricing" className="text-reset" style={{ textDecoration: 'none'}}>FAQ</a>
                            </p>
                            <p>
                                <a href="/faq" className="text-reset" style={{ textDecoration: 'none'}}>News</a>
                            </p>
                            <p>
                                <a href="/schedule" className="text-reset" style={{ textDecoration: 'none'}}>Schedule</a>
                            </p>
                            <p>
                                <a href="/help" className="text-reset" style={{ textDecoration: 'none'}}>Help</a>
                            </p>
                        </div>
                        <div className="col-md-4 col-lg-3 col-xl-3 mx-auto mb-md-0 mb-4">
                        <h6 className="text-uppercase fw-bold mb-4">Contact</h6>
                        <p><FaMapMarkedAlt size="20"/> Maghaway Rd, Sandayong, Minglanilla, Cebu, Philippines</p>
                        <p><FaEnvelope size="20"/> admin@pearsportal.com</p>
                        <p><FaMobileAlt size="20"/>  +63 936 146 2455</p>
                        <p><FaPhoneAlt size="20"/> + 032 </p>
                        </div>
                    </div>
                    </div>
                </section>

                <div className="text-center p-4" style={{backgroundColor: 'rgba(0, 0, 0, 0.05)'}}>
                    &copy; Copyright. All Rights Reserved<br/>
                    Developed and Maintained by : &nbsp;
                    <a href='https://pearsportal.com/' style={{ textDecoration: 'none', color:'blue'}}>Pears Portal</a>
                </div>
            </footer>
        </div>
    )
}

export default Footer;