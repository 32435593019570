import React, { useState, useEffect, useCallback } from 'react';
import axios, { AxiosResponse } from 'axios';
import {
    Navbar,
    Dropdown,
    DropdownButton,
    Button,
    Nav,
    Image,
    Badge,
    Container,
    Offcanvas,
    NavDropdown
  } from 'react-bootstrap';
import logo_head from '../../images/linao.png';
import { useNavigate } from 'react-router-dom';
import { FaHome } from "react-icons/fa";
import { MdOutlineInfo } from "react-icons/md";
import { GrServices } from "react-icons/gr";
import { IoMdPricetags } from "react-icons/io";
import { BiSolidPhoneCall } from "react-icons/bi";
import { RiFeedbackLine } from "react-icons/ri";
import { AiOutlineBell, AiOutlineComment, AiOutlineLogout, AiOutlineIdcard, AiOutlineSetting, AiOutlineDashboard } from "react-icons/ai";
import { getLocalStorageVariable } from '../../components/localStorage';
import { setSessionVariable } from '../../components/sessionStorage';
import apiUrl from '../../components/apiurl';

interface Notification {
  id: number;
  message: string;
  timestamp: string;
  user_id: number;
  status: boolean;
}

const Header: React.FC = () => {
  const userId = getLocalStorageVariable<string>('userId');
  const userAuth = getLocalStorageVariable<string>('userAuth');
  const userAvatar = getLocalStorageVariable<string>('avatar');
  const token = getLocalStorageVariable<string>('token');
  const loginDate = getLocalStorageVariable<string>('loginDate');
  const [notifications, setNotifications] = useState<Notification[]>([]);
  const [messages, setMessages] = useState(0);
  const [ctr, setCtr] = useState<number>(0);
  const navigate = useNavigate();

// ****** Automatic logout if no activity **********
const handleLogout = () => {
  navigate('/logout');
};

useEffect(() => {
  let logoutTimer: NodeJS.Timeout;

  const resetTimer = () => {
    if (logoutTimer) {
      clearTimeout(logoutTimer);
    }
    // Set a new timeout for 1 hour
    logoutTimer = setTimeout(handleLogout, 3600000);
  };

  const handleActivity = () => {
    resetTimer();
  };
  window.addEventListener('mousemove', handleActivity);
  window.addEventListener('keydown', handleActivity);
  resetTimer();
  return () => {
    if (logoutTimer) {
      clearTimeout(logoutTimer);
    }
    window.removeEventListener('mousemove', handleActivity);
    window.removeEventListener('keydown', handleActivity);
  };
}, [handleLogout]);
  

// ****** Automatic logout if you did not logout yesterday **********
  useEffect(() => {
    const currentDate = new Date();
    const formattedDate = formatDate(currentDate);
    if((formattedDate != loginDate)&&(loginDate != null)){
      handleLogout()
    }
  }, [handleLogout]); 

  const formatDate = (date: Date): string => {
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, '0'); 
    const day = date.getDate().toString().padStart(2, '0');

    return `${year}-${month}-${day}`;
  };

// ********** Get the notifications ************
  const fetchNotificationhData = useCallback(async () => {
    const headers = {
        Authorization: `${token}`,
      };
    try {
        const res: AxiosResponse<Notification[]> = await axios.get(`${apiUrl.url}notifications/${userId}/${userAuth}`, { headers });
        setNotifications(res.data);
    } catch (error) {
        console.error('Error: ', error);
        return false;
    }
  }, [userId, userAuth, token]);

  useEffect(() => {
    if (userId!='' && userId!=null) {
      fetchNotificationhData();
    }
  }, [userId, fetchNotificationhData]);

// ********** Count the notifications ************
  useEffect(() => {
    const newCtr = notifications.reduce((count, notification) => {
      return !notification.status ? count + 1 : count;
    }, 0);
    setCtr(newCtr);
  }, [notifications]);


// ********** Get the messages ************
const fetchMessagesData = useCallback(async () => {
  const headers = {
      Authorization: `${token}`,
    };
  try {
      const res: AxiosResponse = await axios.get(`${apiUrl.url}messages/message_list/${userId}`, { headers });
        for(let i = 0; i < res.data.length; i++ ){
          if(res.data[i].isseen === 0){
            setMessages(prevMessages => prevMessages + 1);
          }
        }
  } catch (error) {
      console.error('Error: ', error);
      return false;
  }
}, [userId, token]);

useEffect(() => {
  if (userId!='' && userId!=null) {
    fetchMessagesData();
  }
}, [userId, fetchMessagesData]);


// ****** Navigate to path when click *****
const handleItemClick = (path: string) => {
  setSessionVariable('setSelectedItem', path);
  navigate(path);
};


// ***** Messages offcanvas *******

const [show1, setShow1] = useState(false);

  const handleClose1 = () => setShow1(false);
  const handleShow1 = () => setShow1(true);
  
  return(
    <>
    {/* <Container fluid className='bg-dark' data-bs-theme="dark" style={{position:'fixed'}}> */}
        {['sm'].map((expand, index) => (
            <Navbar  key={index} expand={expand} sticky="top"  bg="dark" data-bs-theme="dark" className="shadow-lg mb-2">
              {/* <Navbar bg="dark" data-bs-theme="dark" expand="sm" sticky="top" className="p-2 mb-2 shadow"></Navbar> */}
                <Container fluid>
                    {/* <Navbar.Brand href="/">
                        <img src={logo_head} alt="" width="50" height="40"/>
                        &nbsp;Pears Portal
                    </Navbar.Brand> */}
                    <Navbar.Brand href="/" ><img src={logo_head} alt="" width="70" height="40"/> Casa Mira - Linao</Navbar.Brand>
                    <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-${expand}`} />
                    <Navbar.Offcanvas
                        id={`offcanvasNavbar-expand-${expand}`}
                        aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`}
                        placement="end"
                        data-bs-theme="dark"
                    >
                        <Offcanvas.Header closeButton>
                            <Offcanvas.Title id={`offcanvasNavbarLabel-expand-${expand}`}>
                              Casa Mira - Linao
                            </Offcanvas.Title>
                        </Offcanvas.Header>
                        <Offcanvas.Body>
                            <Nav className="justify-content-end flex-grow-1 pe-0 pt-1">
                                {/* <Nav.Link href="/"><FaHome size={15}/> Home</Nav.Link> */}
                                {userAuth=='client' || userAuth==''?<Nav.Link onClick={() => handleItemClick('/dashboard')}><FaHome size={20}/> Home</Nav.Link>:
                                  <Nav.Link onClick={() => handleItemClick('/admin_dashboard')}><FaHome size={20}/> Home</Nav.Link>}
                                <Nav.Link href="/dashboard"><MdOutlineInfo size={20}/> About Us</Nav.Link>
                                {/* <Nav.Link href="/services"><GrServices size={20}/> Services</Nav.Link> */}
                                {/* <Nav.Link href="#action2"><IoMdPricetags size={15}/> Pricing</Nav.Link> */}
                                <Nav.Link href="#action2"><BiSolidPhoneCall size={20}/> Contact Us</Nav.Link>
                            </Nav>



                            {userId!='' && userId!=null?
                              <Nav className="pt-1">
                                
                                  {userAuth!='client'?
                                    <>
                                      <Nav.Link onClick={() => handleItemClick('/admin_notifications')}>
                                        <AiOutlineBell size="20" /> Notifications &nbsp;
                                        {ctr==0?'':<Badge pill bg="danger">{ctr}</Badge>}
                                      </Nav.Link>
                                    </>
                                    :
                                    <>
                                      <Nav.Link onClick={() => handleItemClick('/notifications')}>
                                        <AiOutlineBell size="20" /> Notifications &nbsp;
                                        {ctr==0?'':<Badge pill bg="danger">{ctr}</Badge>}
                                      </Nav.Link>
                                    </>
                                  }


                                  {userAuth!='client'?
                                    <>
                                      <Nav.Link onClick={() => handleItemClick('/admin_messages')}>
                                        <AiOutlineComment size="20" /> Messages &nbsp;
                                        {messages>0?<Badge pill bg="danger">{messages}</Badge>:''}
                                      </Nav.Link>
                                    </>
                                    :
                                    <>
                                      <Nav.Link onClick={() => handleItemClick('/messages')}>
                                      {/* <Nav.Link onClick={handleShow1}> */}
                                        <AiOutlineComment size="20" /> Messages &nbsp;
                                        {messages>0?<Badge pill bg="danger">{messages}</Badge>:''}
                                      </Nav.Link>
                                    </>
                                  }
                              
                              
                                <NavDropdown
                                    // variant="outline-dark"
                                    title={<Image src={userAvatar || '/static/media/default.d1c5ffe2b3cea9d80c7b.jpg'} width="35" height="35" roundedCircle />}
                                    id="dropdown-button-drop-start"
                                    drop='start'
                                    // className='border-dark'
                                  >
                                      {userAuth!='client'?
                                      <>
                                        <NavDropdown.Item onClick={() => handleItemClick('/admin_dashboard')}><AiOutlineDashboard size="20" /> &nbsp;Dashboard</NavDropdown.Item>
                                        <NavDropdown.Divider />
                                        <NavDropdown.Item onClick={() => handleItemClick('/admin_profile')}><AiOutlineIdcard size="20" /> &nbsp;Profile</NavDropdown.Item>
                                        {/* <NavDropdown.Item onClick={() => handleItemClick('/admin_notifications')}><AiOutlineBell size="20" /> &nbsp;Notifications</NavDropdown.Item>
                                        <NavDropdown.Item onClick={() => handleItemClick('/admin_messages')}><AiOutlineComment size="20" /> &nbsp;Messages</NavDropdown.Item> */}
                                        <Dropdown.Item onClick={() => handleItemClick('/admin_dashboard')}><RiFeedbackLine size="20" /> &nbsp;Feedback</Dropdown.Item>
                                      </>:<>
                                        <NavDropdown.Item onClick={() => handleItemClick('/dashboard')}><AiOutlineDashboard size="20" /> &nbsp;Dashboard</NavDropdown.Item>
                                        <NavDropdown.Divider />
                                        <NavDropdown.Item onClick={() => handleItemClick('/profile')}><AiOutlineIdcard size="20" /> &nbsp;Profile</NavDropdown.Item>
                                        {/* <NavDropdown.Item onClick={() => handleItemClick('/notifications')}><AiOutlineBell size="20" /> &nbsp;Notifications</NavDropdown.Item>
                                        <NavDropdown.Item onClick={() => handleItemClick('/messages')}><AiOutlineComment size="20" /> &nbsp;Messages</NavDropdown.Item> */}
                                        <Dropdown.Item onClick={() => handleItemClick('/dashboard')}><RiFeedbackLine size="20" /> &nbsp;Feedback</Dropdown.Item>
                                      </>
                                      }
                                      <NavDropdown.Divider />
                                      <NavDropdown.Item onClick={() => handleItemClick('/logout')}><AiOutlineLogout size="20" /> &nbsp;Logout</NavDropdown.Item>
                                  </NavDropdown>
                              </Nav>
                            :
                              <Nav className="ms-auto">
                                <Button onClick={() => handleItemClick('/login')} variant="light" type="submit" className="btn btn-block rounded-pill m-1" >
                                    Log in
                                </Button>
                                <Button onClick={() => handleItemClick('/activation')} variant="primary" type="submit" className="btn btn-block rounded-pill m-1" >
                                    Activate
                                </Button>
                              </Nav>
                            }
                        </Offcanvas.Body>
                    </Navbar.Offcanvas>

                    {/* Messages offcanvas */}
                    <Offcanvas show={show1} onHide={handleClose1} placement="end">
                      <Offcanvas.Header closeButton>
                        <Offcanvas.Title>Offcanvas</Offcanvas.Title>
                      </Offcanvas.Header>
                      <Offcanvas.Body>
                        Some text as placeholder. In real life you can have the elements you
                        have chosen. Like, text, images, lists, etc.
                      </Offcanvas.Body>
                    </Offcanvas>

                </Container>
            </Navbar>
        ))}
    {/* </Container> */}
      {/* <Navbar bg="dark" data-bs-theme="dark" expand="sm" sticky="top" className="p-2 mb-2 shadow">
        <Navbar.Brand href="/" ><img src={logo_head} alt="" width="70" height="40"/> Casa Mira - Linao</Navbar.Brand>
        
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="mr-auto text-light">
              {userAuth=='client' || userAuth==''?<Nav.Link onClick={() => handleItemClick('/dashboard')}>Home</Nav.Link>:<Nav.Link onClick={() => handleItemClick('/admin_dashboard')}>Home</Nav.Link>}
              
              <Nav.Link href="/login">About</Nav.Link>
            </Nav> */}

            {/* {userId!='' && userId!=null?
              <Nav className="ms-auto">
                
                  {userAuth!='client'?
                    <>
                      <Nav.Link onClick={() => handleItemClick('/admin_notifications')}>
                        <AiOutlineBell size="25" />
                        {ctr==0?'':<Badge pill bg="danger">{ctr}</Badge>}
                      </Nav.Link>
                    </>
                    :
                    <>
                      <Nav.Link onClick={() => handleItemClick('/notifications')}>
                        <AiOutlineBell size="25" />
                        {ctr==0?'':<Badge pill bg="danger">{ctr}</Badge>}
                      </Nav.Link>
                    </>
                  }


                  {userAuth!='client'?
                    <>
                      <Nav.Link onClick={() => handleItemClick('/admin_messages')}>
                        <AiOutlineComment size="25" />
                        {messages>0?<Badge pill bg="danger">{messages}</Badge>:''}
                      </Nav.Link>
                    </>
                    :
                    <>
                      <Nav.Link onClick={() => handleItemClick('/messages')}>
                        <AiOutlineComment size="25" />
                        {messages>0?<Badge pill bg="danger">{messages}</Badge>:''}
                      </Nav.Link>
                    </>
                  }
              
              
                <DropdownButton
                    variant="outline-dark"
                    title={<Image src={userAvatar || '/static/media/default.d1c5ffe2b3cea9d80c7b.jpg'} width="40" height="40" roundedCircle />}
                    id="dropdown-button-drop-start"
                    drop='start'
                  >
                      {userAuth!='client'?
                      <>
                        <Dropdown.Item onClick={() => handleItemClick('/admin_dashboard')}><AiOutlineDashboard size="20" /> &nbsp;Dashboard</Dropdown.Item>
                        <Dropdown.Divider />
                        <Dropdown.Item onClick={() => handleItemClick('/admin_profile')}><AiOutlineIdcard size="20" /> &nbsp;Profile</Dropdown.Item>
                        <Dropdown.Item onClick={() => handleItemClick('/admin_notifications')}><AiOutlineBell size="20" /> &nbsp;Notifications</Dropdown.Item>
                        <Dropdown.Item onClick={() => handleItemClick('/admin_messages')}><AiOutlineComment size="20" /> &nbsp;Messages</Dropdown.Item>
                      </>:<>
                        <Dropdown.Item onClick={() => handleItemClick('/dashboard')}><AiOutlineDashboard size="20" /> &nbsp;Dashboard</Dropdown.Item>
                        <Dropdown.Divider />
                        <Dropdown.Item onClick={() => handleItemClick('/profile')}><AiOutlineIdcard size="20" /> &nbsp;Profile</Dropdown.Item>
                        <Dropdown.Item onClick={() => handleItemClick('/notifications')}><AiOutlineBell size="20" /> &nbsp;Notifications</Dropdown.Item>
                        <Dropdown.Item onClick={() => handleItemClick('/messages')}><AiOutlineComment size="20" /> &nbsp;Messages</Dropdown.Item>
                      </>
                      }
                      <Dropdown.Divider />
                      <Dropdown.Item onClick={() => handleItemClick('/logout')}><AiOutlineLogout size="20" /> &nbsp;Logout</Dropdown.Item>
                  </DropdownButton>
              </Nav>
            :
              <Nav className="ms-auto">
                <Button onClick={() => handleItemClick('/login')} variant="light" type="submit" className="btn btn-block rounded-pill m-1" >
                    Log in
                </Button>
                <Button onClick={() => handleItemClick('/activation')} variant="primary" type="submit" className="btn btn-block rounded-pill m-1" >
                    Activate
                </Button>
              </Nav>
            } */}
          {/* </Navbar.Collapse>
      </Navbar> */}
    </>
  );
}

export default Header;